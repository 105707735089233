import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {}
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      console.log({ error: this.state.hasError });
      // You can render any custom fallback UI
      return (
        <div className="flex flex-col items-center justify-center">
          <div>{this.props.errorMessage || "Oops, there is an error!"}</div>
          <button
            onClick={() => {
              this.setState({ hasError: false });
              this.props.onClick && this.props.onClick();
            }}
          >
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
