import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/gbflogo.png";
import { CiUser } from "react-icons/ci";
import { FaKey } from "react-icons/fa";
import { BsBoxArrowRight } from "react-icons/bs";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { signInSchema } from "../../utils/validationSchema/authSchema";
import useAuth from "../../hooks/useAuth";
import api from "../../api/axios";
import fetcher from "../../api/fetacher";
import { useDispatch, useSelector } from "react-redux";
import { setProgram, setUser } from "../../feature/auth";
import InputText from "../../components/input/InputText";
import { IoMdLock } from "react-icons/io";
import { changePasswordSchema } from "../../utils/validationSchema/changePasswordSchema";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState("");
  const { program, user } = useSelector((state) => state.auth);
  const { changeNewUserPassword } = useAuth();
  const dispatch = useDispatch();

  const initialValues = {
    password: "",
    password2: "",
  };

  const changePassword = async (data) => {
    try {
      setLoading(true);
      const res = await changeNewUserPassword({ newPassword: data.password });
      switch (user?.userType) {
        case "SUPER_ADMIN":
          navigate("/admin/dashboard");
          break;
        case "ADMIN":
          navigate("/admin/dashboard");
          break;
        case "PARTNER":
          navigate("/admin/dashboard");
          break;
        default:
          navigate("/dashboard");
      }
      setError(res.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen bg-new-background bg-cover bg-no-repeat">
      <div className="flex items-center justify-center w-[500px] bg-white h-max p-4 rounded-md">
        <div className="flex flex-col items-center w-[400px]">
          <h3 className="text-center m-0 w-[100px]">
            <a href="https://waste4reward.org/#" className="logo logo-admin">
              <img
                src={Logo}
                height="100"
                alt="logo"
                className="my-3"
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            </a>
          </h3>
          <div className="px-2 mt-2 w-full">
            <h4 className="text-xl font-bold mb-2 text-center">
              Welcome Back !
            </h4>
            <p className="text-sm text-center mb-3 text-gray-400">
              Change Password to continue...
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={changePasswordSchema}
              onSubmit={changePassword}
            >
              {({ handleChange, errors, values, handleSubmit }) => {
                return (
                  <Form>
                    <div className="mb-3 w-full">
                      <label htmlFor="password">Password</label>
                      <div className="flex items-center border border-gray-300 h-30 bg-gray-50">
                        <input
                          type={`${viewPassword ? "text" : "password"}`}
                          className="p-2 w-full"
                          onChange={handleChange}
                          value={values.password}
                          id="password"
                          name="password"
                          placeholder="Enter password"
                        />
                        <span className="inline-block w-[20px]">
                          {viewPassword ? (
                            <IoEyeOutline
                              onClick={() => setViewPassword(false)}
                            />
                          ) : (
                            <IoEyeOffOutline
                              onClick={() => setViewPassword(true)}
                            />
                          )}
                        </span>
                      </div>
                      {errors && errors.password && (
                        <p className="text-red-500">{errors.password}</p>
                      )}
                    </div>
                    <div className="mb-3 w-full">
                      <label htmlFor="password2">Re-Type Password</label>
                      <div className="flex items-center border border-gray-300 h-30 bg-gray-50">
                        <input
                          type={`${viewPassword ? "text" : "password"}`}
                          className="p-2 w-full"
                          onChange={handleChange}
                          value={values.password2}
                          id="password"
                          name="password2"
                          placeholder="Enter password"
                        />
                        <span className="inline-block w-[20px]">
                          {viewPassword ? (
                            <IoEyeOutline
                              onClick={() => setViewPassword(false)}
                            />
                          ) : (
                            <IoEyeOffOutline
                              onClick={() => setViewPassword(true)}
                            />
                          )}
                        </span>
                      </div>
                      {errors && errors.password2 && (
                        <p className="text-red-500">{errors.password2}</p>
                      )}
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <div className="mb-2 flex justify-center">
                      <div className="col-12 mt-2">
                        <button
                          className="bg-sky-500 flex justify-center items-center p-4 text-white bg-yellow-600 h-8"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          {loading ? "loading..." : "Submit"}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
