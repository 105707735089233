import React, { useCallback, useEffect, useState } from "react";
import { login } from "../ds/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../feature/auth";
import { gatAllAggregator } from "../ds/aggregators";
import toast from "react-hot-toast";

import {
  createCollector,
  downloadCollector,
  gatAllCollector,
  getAllCollectorList,
  getCollectorDetail,
  updateCollector,
} from "../ds/collectors";
import { downloadCsvFile } from "../utils/utils";
const useCollector = () => {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const [collectorCount, setCollectorCount] = useState();
  const [downloadData, setDownloadData] = useState("");

  const gatAllCollectors = async ({
    page = 1,
    size = 10,
    query,
    selectedState,
    selectedLga,
    aggId,
    program,
    startDate,
    endDate,
  }) => {
    setLoading(true);
    const res = await gatAllCollector({
      page,
      size,
      nameOrEmailOrPhoneNumber: query || "",
      state: selectedState || "",
      location: selectedLga || "",
      aggregatorId: aggId || "",
      program,
      startDate: startDate || "",
      endDate: endDate || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };

  const getSingleCollector = async (collectorId) => {
    setLoading(true);
    const res = await getCollectorDetail({ id: collectorId });
    setLoading(false);
    return JSON.parse(res);
  };

  const gatAllCollectorList = useCallback(async (program) => {
    setLoading(true);
    const res = await getAllCollectorList(program);
    // console.log({ res }, "hook");
    setCollectorCount(JSON.parse(res)?.data?.length);
    return res;
  }, []);

  useEffect(() => {
    gatAllCollectorList();
  }, []);

  const createNewCollector = async (data) => {
    const res = await createCollector(data);
    return res;
  };
  const updateExistingCollector = async (data) => {
    const res = await updateCollector(data);
    return res;
  };
  const collectorDownload = async ({
    program,
    query,
    state,
    location,
    aggregatorId,
    startDate,
    endDate,
  }) => {
    toast.success("download...");
    const res = await downloadCollector({
      program: program,
      nameOrEmailOrPhoneNumber: query || "",
      state: state || "",
      aggregatorId: aggregatorId || "",
      location: location || "",
      startDate: startDate || "",
      endDate: endDate || "",
    });
    return downloadCsvFile(res, "collector.csv", setDownloadData, toast);
  };
  return {
    loading,
    gatAllCollectors,
    gatAllCollectorList,
    getSingleCollector,
    createNewCollector,
    updateExistingCollector,
    collectorDownload,
  };
};

export default useCollector;
