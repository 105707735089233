import React, { useState } from "react";
import Logo from "../../assets/images/gbflogo.png";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { signInSchema } from "../../utils/validationSchema/authSchema";
import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { setProgram, setUser } from "../../feature/auth";
import { IoMdLock } from "react-icons/io";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [error, setError] = useState("");
  const { loginUser } = useAuth();
  const dispatch = useDispatch();

  const initialValues = {
    email: "",
    password: "",
  };

  const signIn = async (data) => {
    try {
      setLoading(true);
      const res = await loginUser(data);
      if (res?.data) {
        localStorage.setItem("accessToken", res?.data?.access_token);
        const { user } = res?.data;
        dispatch(setUser(user));
        switch (user?.userType) {
          case "SUPER_ADMIN":
            user.hasChangePassword
              ? navigate("/admin/dashboard")
              : navigate("/change_password");

            break;
          case "ADMIN":
            dispatch(setProgram(user?.programId));
            user.hasChangePassword
              ? navigate("/admin/dashboard")
              : navigate("/change_password");

            break;
          case "PARTNER":
            dispatch(setProgram(user?.programId));
            user.hasChangePassword
              ? navigate("/admin/dashboard")
              : navigate("/change_password");
            break;
          case "BA_FO":
            dispatch(setProgram(user?.programId));
            user.hasChangePassword
              ? navigate("/admin/dashboard")
              : navigate("/change_password");
            break;
          case "FIELD_OFFICER":
            dispatch(setProgram(user?.programId));
            user.hasChangePassword
              ? navigate("/admin/dashboard")
              : navigate("/change_password");
            break;
          default:
            dispatch(setProgram(user?.programId));
            user.hasChangePassword
              ? navigate("/dashboard")
              : navigate("/change_password");
        }
      }
      setError(res.message);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-center items-center h-screen bg-new-background bg-cover bg-no-repeat">
      <div className="flex items-center justify-center w-[500px] bg-white h-max p-4 rounded-md">
        <div className="flex flex-col items-center w-[400px]">
          <h3 className="text-center m-0 w-[100px]">
            <a href="https://waste4reward.org/#" className="logo logo-admin">
              <img
                src={Logo}
                height="100"
                alt="logo"
                className="my-3"
                style={{ width: "100%", height: "auto", objectFit: "contain" }}
              />
            </a>
          </h3>
          <div className="px-2 mt-2 w-full">
            <h4 className="text-xl font-bold mb-2 text-center">
              Welcome Back !
            </h4>
            <p className="text-sm text-center mb-3 text-gray-400">
              Sign in to continue...
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={signInSchema}
              onSubmit={signIn}
            >
              {({ handleChange, errors, values }) => {
                return (
                  <Form>
                    <div className="mb-3 w-full">
                      <label className="form-label" htmlFor="username">
                        Email
                      </label>
                      <div className="flex flex-col items-center border border-gray-300 h-30 bg-gray-50">
                        <input
                          type="text"
                          className="p-2 w-full"
                          onChange={handleChange}
                          value={values.email}
                          id="email"
                          name="email"
                          placeholder="Enter email address"
                        />
                      </div>
                      {errors && errors.email && (
                        <p className="text-red-500">{errors.email}</p>
                      )}
                    </div>

                    <div className="mb-3 w-full">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <div className="flex items-center border border-gray-300 h-30 bg-gray-50">
                        <input
                          type={`${viewPassword ? "text" : "password"}`}
                          className="p-2 w-full"
                          onChange={handleChange}
                          value={values.password}
                          id="password"
                          name="password"
                          placeholder="Enter password"
                        />
                        <span className="inline-block w-[20px]">
                          {viewPassword ? (
                            <IoEyeOutline
                              onClick={() => setViewPassword(false)}
                            />
                          ) : (
                            <IoEyeOffOutline
                              onClick={() => setViewPassword(true)}
                            />
                          )}
                        </span>
                      </div>
                      {errors && errors.password && (
                        <p className="text-red-500">{errors.password}</p>
                      )}
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <div className="mb-2 flex justify-between">
                      <div className="flex items-center gap-2">
                        <input type="checkbox" />
                        <label className="font-bold">Remember me</label>
                      </div>
                      <div className="col-12 mt-2">
                        <button
                          className="bg-sky-500 flex justify-center items-center p-4 text-white bg-yellow-600 h-8"
                          type="submit"
                        >
                          {loading ? "loading..." : "Log In"}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div
            className="mt-4 mb-4 flex gap-1 w-full"
            onClick={() => navigate("/resetpassword")}
            style={{ cursor: "pointer" }}
          >
            <IoMdLock />
            <p className="mb-0 text-sm">Forgot your password?</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
