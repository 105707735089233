import React from "react";

const Imo = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M216.232 417.365L219.771 414.678L223.867 413.603L226.138 414.52L228.23 413.956L230.305 409.927L231.016 407.794L232.998 406.645L235.384 406.092L237.814 405.827L242.587 406.805L244.755 406.116L246.95 405.69L248.075 406.611L250.729 407.893L252.198 408.209L254.229 409.927L254.912 415.565L254.387 417.868L253.918 418.581L254.518 419.532L254.889 420.836V422.216L255.097 423.584L255.13 424.967L254.731 426.155L254.005 427.19L253.508 428.518L252.874 429.765L250.875 431.894L247.02 442.492L245.452 442.913L243.765 443.106L240.974 442.342L234.322 442.971L226.873 442.48L221.303 439.041L220.932 438.372L219.665 437.488L219.167 436.943L219.102 435.202L219.872 433.578L220.304 429.998L218.125 427.829L216.214 428.277L214.324 428.15L213.98 427.035L215.613 419.109L216.232 417.365Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Imo;
