import React, { useEffect, useRef, useState } from "react";
import BottleWater from "../../../assets/images/bottle-of-water.png";
import DashCard from "../../../components/card/DashCard";
import InputSelect from "../../../components/input/InputSelect";
import DataCard from "../../../components/card/DataCard";
import rafiki from "../../../assets/images/rafiki.png";
import Character2 from "../../../assets/images/Character.png";
import LineCharts from "../../../components/charts/LineChart";
import Granular from "../../../assets/images/granular.png";
import Pana from "../../../assets/images/pana.png";
import Group from "../../../assets/images/group.png";
import Character from "../../../assets/images/Characters.png";
import { AiOutlineRight } from "react-icons/ai";
import useResource from "../../../hooks/useResource";
import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector } from "react-redux";
import useNav from "../../../hooks/useNav";
import Nigeria from "../../../components/map/Nigeria";
import { MapData } from "../../../components/map/StatesData";
import SearchableDropdown from "../../../components/input/SearchableDropdown";
import useAggregator from "../../../hooks/useAggregator";

const colors = [
  "green",
  "orange",
  "purple",
  "blue",
  "red",
  "black",
  "#FF00FF",
  "#ADFF2F",
  "#FFA07A",
  "#FF00FF",
  "#0000CD",
  "#00FA9A",
  "#FF4500",
  "#FA8072",
  "#6A5ACD",
  "#EE82EE",
  "#9ACD32",
  "#40E0D0",
  "#F5DEB3",
  "#FF6347",
  "#D8BFD8",
  "#008080",
  "#BC8F8F",
  "#4169E1",
  "#DDA0DD",
  "#800000",
  "#F08080",
  "#4B0082",
  "#9400D3",
  "#00BFFF",
  "#D2691E",
  "#8B008B",
  "#E9967A",
  "#F0FFF0",
  "#F0E68C",
  "#E6E6FA",
  "#87CEFA",
];
const AdminDashboard = () => {
  const { program } = useSelector((state) => state.auth);
  const { closeNav, setCloseNav } = useNav();
  const [currentHover, setCurrentHover] = useState(false);
  const [activeMapIndex, setActiveMapIndex] = useState(0);
  const [activeMapCoord, setActiveMapCoord] = useState({});
  const [stateDetail, setStateDetail] = useState({});
  const [showStateDetails, setshowStateDetails] = useState(0);
  const [aggregatorList, setAggregatorList] = useState([]);

  const stateRef = useRef(null);
  const toolTipRef = useRef(null);
  const {
    typeOfPlastic,
    sourceOfPlastic,
    paReport,
    peReport,
    topFiveAggregator,
    processedReport,
    collectionReport,
    setCollectionYear,
    setProcessedYear,
    topFiveCollector,
    setTopFiveColYear,
    setTopFiveAggYear,
    stateCollectionReport,
    setStateYear,
    topFiveLocation,
    topFiveStates,
    setTopStateYear,
    setTopLocationYear,
    setCollectionReportTimeline,
    setProcessedReportTimeline,
    collectionReportTimeline,
    processedReportTimeline,
    setSourceOfPlasticId,
    setTypeOfPlasticId,
    setAggregatorId,
  } = useResource(program);
  const { gatAllAggregatorLists } = useAggregator();

  useEffect(() => {
    const getAggregatorsList = async () => {
      const res = await gatAllAggregatorLists(program);
      const list = JSON.parse(res)?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setAggregatorList([...list]);
    };
    getAggregatorsList();
  }, [program]);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const peMetrics = [
    {
      title: "Total Collections",
      subtitle: numberWithCommas(parseInt(peReport?.noOfPlasticCollected) || 0),
      figure: `${numberWithCommas(
        parseInt(peReport?.noOfPlasticCollected * 40) || 0
      )} Bottles`,
      image: BottleWater,
      unit: "Kg",
      css: "bg-[#DCFAE6]",
    },
    {
      title: "Total Offtake",
      subtitle: numberWithCommas(parseInt(peReport?.noOfPlasticProcessed) || 0),
      figure: `${numberWithCommas(
        parseInt(peReport?.noOfPlasticProcessed * 40) || 0
      )} Bottles`,
      image: BottleWater,
      unit: "Kg",
      css: "bg-[#F4EBFF]",
    },
    {
      title: "Transaction value",
      subtitle: `NGN ${numberWithCommas(
        parseInt(peReport?.transactionValue) || 0
      )}`,
      figure: `${numberWithCommas(
        parseInt(peReport?.noOfPlasticCollected) || 0
      )} kg`,
      image: Granular,
      css: "bg-[#FFE6D5]",
    },
    {
      title: "Average Income per collector",
      caption: "Across program duration",
      subtitle: `NGN ${numberWithCommas(
        parseInt(peReport?.averageCollector) || 0
      )}`,
      image: Pana,
      css: "bg-[#E0F2FE]",
    },
  ];

  const paMetrics = [
    {
      title: "Total Aggregators",
      subtitle: numberWithCommas(parseInt(paReport?.noOfAggregator) || 0),
      figure: `${numberWithCommas(parseInt(paReport?.aggregatorState) || 0)} ${
        paReport?.aggregatorState === 1 ? "state" : "states"
      }`,
      image: Character,
      css: "bg-[#EAECF5]",
    },
    {
      title: "Total Users",
      subtitle: numberWithCommas(parseInt(paReport?.noOfUser) || 0),
      image: Group,
      css: "bg-white border border-gray-300",
    },
    {
      title: "Total Recyclers",
      subtitle: numberWithCommas(parseInt(paReport?.noOfRecycler) || 0),
      image: rafiki,
      css: "bg-white border border-gray-300",
    },
    {
      title: "Total Collectors",
      subtitle: numberWithCommas(parseInt(paReport?.noOfCollector) || 0),
      image: Character2,
      css: "bg-[#FFFAEB]",
    },
  ];
  const stateCollectionReport2 = stateCollectionReport?.map((data, index) => {
    return {
      ...data,
      color: colors[index],
    };
  });
  const newMapData = MapData?.map((data, index) => {
    const data1 = stateCollectionReport2?.find((data1, index) => {
      if (data.name === data1.state) {
        return {
          ...data1,
        };
      }
    });
    return MapData[index]?.name !== data1?.state ? data : { ...data1, ...data };
  });

  return (
    <>
      <div className="p-4 h-max">
        <div className="flex flex-col gap-2 mb-2">
          <h1 className="font-bold text-xl">Dashboard</h1>
          <p className="pr-2">Gain insights from the metrics below.</p>
        </div>
        <div className="flex flex-col items-center border border-gray-200 p-4 mb-10 rounded-md">
          <div className="flex flex-col md:w-full xl:flex-row mb-3">
            <p className="font-bold xl:w-[200px]">Performance Metrics</p>
            <div className="flex md:w-full xl:justify-end gap-2">
              <SearchableDropdown
                options={
                  [{ label: "All aggregators", value: 0 }, ...aggregatorList] ||
                  []
                }
                placeholder={"Filter by aggregator"}
                // selectedOption={selectedState}
                defaultValue={null}
                handleChange={(e) => {
                  setAggregatorId(e?.value);
                }}
                containerClass={"md:w-full xl:w-[185px]"}
                css={"w-[176px] md:w-full xl:w-[185px]"}
              />
              <SearchableDropdown
                options={
                  [
                    { label: "All plastics", value: 0 },
                    ...typeOfPlastic?.map((data) => {
                      return {
                        label: data.name,
                        value: data.id,
                      };
                    }),
                  ] || []
                }
                placeholder={"Type Of Plastic"}
                // selectedOption={selectedState}
                defaultValue={null}
                handleChange={(e) => {
                  setTypeOfPlasticId(e);
                }}
                containerClass={"md:w-full xl:w-[185px]"}
                css={"w-[176px] md:w-full xl:w-[185px]"}
              />
              <SearchableDropdown
                containerClass={"md:w-full xl:w-[185px]"}
                css={"w-[175px] md:w-full xl:w-[185px]"}
                placeholder={"Source Of Plastic"}
                options={[
                  { label: "All sources", value: 0 },
                  ...sourceOfPlastic?.map((data) => {
                    return {
                      label: data.name,
                      value: data.id,
                    };
                  }),
                ]}
                handleChange={(e) => {
                  setSourceOfPlasticId(e);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col xl:flex-row gap-2 w-full">
            <div className="flex flex-wrap gap-4 basis-[80%]">
              {peMetrics?.map(
                (
                  { title, subtitle, figure, image, css, unit, caption },
                  index
                ) => {
                  return (
                    <>
                      <DashCard
                        key={index}
                        title={title}
                        subtitle={subtitle}
                        figure={figure}
                        caption={caption}
                        image={image}
                        unit={unit}
                        css={`
                          ${css} w-full lg:basis-[48%] xl:basis-[48.5%]
                        `}
                      />
                    </>
                  );
                }
              )}
            </div>
            <div className="flex justify-center gap-4 lg:gap-8 bg-black rounded-md h-max mt-6 p-4 xl:w-[278px] xl:flex-col xl:mt-0 basis-[20%]">
              <div className="">
                <p className="text-white text-[12px] mb-2">Carbon Credits</p>
                <div className="flex flex-col items-center justify-center bg-slate-100 rounded-md w-[158px] h-[120px] md:w-[300px] lg:basis-[370px] xl:w-[248px] lg:h-[150px]">
                  <p className="text-sm">Total Credit</p>
                  <div className="">
                    <div className="flex items-center gap-2 text-xl">
                      <p className="font-bold text-[18px]">
                        {numberWithCommas(
                          parseInt(peReport?.carbonCredit) || 0
                        )}
                      </p>
                      <p className="text-green-400 text-sm">Kg C02e</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <p className="text-white text-[12px] mb-2">Plastokens</p>
                <div className="flex flex-col items-center justify-center text-white rounded-md bg-new-plas bg-cover bg-center bg-no-repeat w-[158px] h-[120px] md:w-[300px] lg:w-[370px] xl:w-[248px] lg:h-[150px]">
                  <p className="text-sm">Total Tokens</p>
                  <div className="">
                    <div className="flex items-center gap-2 text-xl">
                      <p className="font-bold text-[18px]">
                        {numberWithCommas(
                          parseInt(peReport?.noOfPlasticCollected) || 0
                        )}
                      </p>
                      <p className="text-white text-sm">tokens</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col border border-gray-200 gap-4 p-4 mb-10 rounded-md">
          <div className="flex justify-between mb-3">
            <p className="font-bold">Participants Metrics</p>
          </div>
          <div className="flex flex-wrap w-full gap-4">
            {paMetrics?.map(
              ({ title, subtitle, figure, image, css }, index) => {
                return (
                  <>
                    <DataCard
                      key={index}
                      title={title}
                      subtitle={subtitle}
                      figure={figure}
                      image={image}
                      css={`
                        ${css} h-max w-full lg:w-[48%] xl:basis-[49%]
                      `}
                      icon={true}
                      imageHeight={"h-[96px] w-[68px]"}
                    />
                  </>
                );
              }
            )}
          </div>
        </div>
        <div className="h-max border border-gray-300 rounded-md mb-10 p-4 relative">
          <div className="flex flex-col lg:flex-row lg:justify-between border-b border-gray-300 mb-14 h-max p-4">
            <div className="mb-2">
              <p className="font-bold">Collector Performance metric</p>
              <p className="text-sm">Track the rate of collection over time.</p>
            </div>
            <div className="flex rounded-md w-max">
              {[
                { name: "Project Overview", year: 1 },
                { name: "2022", year: 2022 },
                { name: "2023", year: 2023 },
                { name: "2024", year: 2024 },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 p-2 text-sm ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${
                      index === 0
                        ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                        : ""
                    }`}
                    onClick={() => setCollectionYear(data.year)}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div>
            <div className="flex self-end absolute top-36 xl:top-28 right-5">
              <InputSelect
                css={"w-[185px]"}
                placeholder={"Calendar Type"}
                value={collectionReportTimeline}
                options={["Monthly", "Quarterly"]}
                handleChange={(e) =>
                  setCollectionReportTimeline(e.target.value)
                }
              />
            </div>
          </div>
          <div className="h-[500px]">
            <LineCharts
              fill="#8884d8"
              datakeyX={"date"}
              dataKeyB={"plastics"}
              lineData={collectionReport?.map((data) => {
                return {
                  ...data,
                  "Plastics(kg)": numberWithCommas(parseInt(data.plastics)),
                };
              })}
            />
          </div>
        </div>
        <div className="h-max border border-gray-300 rounded-md mb-10 p-4">
          <div className="flex rounded-md w-max mb-4">
            {[
              { name: "Project Overview", year: 1 },
              { name: "2022", year: 2022 },
              { name: "2023", year: 2023 },
              { name: "2024", year: 2024 },
            ]?.map((data, index) => {
              return (
                <button
                  key={index}
                  className={`w-max border border-gray-300 p-2 text-sm ${
                    index === 2 ? "rounded-br rounded-tr" : ""
                  } ${
                    index === 0
                      ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                      : ""
                  }`}
                  onClick={() => {
                    setTopFiveAggYear(data.year);
                    setTopFiveColYear(data.year);
                  }}
                >
                  {data.name}
                </button>
              );
            })}
          </div>
          <div className="flex flex-col lg:flex-row gap-4 rounded-md">
            <div className="basis-[50%] bg-[#ECFDF3] p-4 rounded-md">
              <h1 className="h-[49px] border-b border-[#EAECF0] mb-5 font-bold">
                Top 5 Aggregators
              </h1>
              {topFiveAggregator?.length > 0 ? (
                topFiveAggregator
                  ?.map((data) => {
                    return {
                      ...data,
                      quantity: numberWithCommas(parseInt(data.quantity)),
                    };
                  })
                  ?.map((data, index) => {
                    return (
                      <div
                        className="border-b border-[#EAECF0] h-[60px]"
                        key={index}
                      >
                        <div className="flex justify-between items-center mb-3 h-full">
                          <div className="basis-[55%]">
                            <p className="font-bold text-sm">
                              {data.aggregator}
                            </p>
                            <p className="text-sm">{data.address}</p>
                          </div>
                          <p className="text-sm basis-[35%]">
                            {data.quantity}kg Collected
                          </p>
                          <p className="basis-[10%]">
                            <AiOutlineRight />
                          </p>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="text-center">loading...</div>
              )}
            </div>
            <div className="basis-[50%] bg-[#F9F5FF] p-4 rounded-md">
              <h1 className="h-[49px] border-b border-[#EAECF0] mb-5 font-bold">
                Top 5 Collectors
              </h1>
              {topFiveCollector?.length > 0 ? (
                topFiveCollector
                  ?.map((data) => {
                    return {
                      ...data,
                      quantity: numberWithCommas(parseInt(data.quantity)),
                    };
                  })
                  ?.map((data, index) => {
                    return (
                      <div
                        className="border-b border-[#EAECF0] h-[60px]"
                        key={index}
                      >
                        <div className="flex justify-between items-center mb-3 h-full">
                          <div className="basis-[55%]">
                            <p className="font-bold text-sm">
                              {data.collector}
                            </p>
                            <p className="text-sm">{data.address}</p>
                          </div>
                          <p className="text-sm basis-[35%]">
                            {data.quantity}kg Collected
                          </p>
                          <p className="basis-[10%]">
                            <AiOutlineRight />
                          </p>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <div className="text-center">loading...</div>
              )}
            </div>
          </div>
        </div>
        <div className="h-max border border-gray-300 rounded-md mb-10 p-2">
          <div className="flex flex-col lg:flex-row lg:justify-between justify-between border-b border-gray-300 mb-10 h-max p-4">
            <div className="mb-3">
              <p className="font-bold">Collection Location</p>
            </div>
            <div className="flex rounded-md w-max">
              {[
                { name: "Project Overview", year: 1 },
                { name: "2022", year: 2022 },
                { name: "2023", year: 2023 },
                { name: "2024", year: 2024 },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 p-2 text-sm ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${
                      index === 0
                        ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                        : ""
                    }`}
                    onClick={() => {
                      if (data?.year) {
                        setStateYear(data.year);
                        setTopStateYear(data.year);
                        setTopLocationYear(data.year);
                      }
                    }}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col xl:flex-row mb-5 ">
            <div className="basis-[70%] h-[500px] relative">
              <Nigeria>
                {newMapData?.map(({ id, Component, name, color }, index) => {
                  return (
                    <g
                      ref={stateRef}
                      onMouseEnter={() => {
                        setshowStateDetails(true);
                        setActiveMapIndex(index);
                      }}
                      onMouseLeave={(e) => {
                        setshowStateDetails(false);
                        setActiveMapIndex(index);
                      }}
                      onMouseMove={(e) => {
                        setActiveMapCoord({
                          left: e.nativeEvent.offsetX,
                          top: e.nativeEvent.offsetY,
                        });
                        setStateDetail(
                          stateCollectionReport?.filter(
                            (data) => data.state === name
                          )
                        );
                      }}
                      key={index}
                    >
                      <Component
                        fill={color ? `${color}` : "#ECFDF3"}
                        showStateDetails={showStateDetails}
                      />
                    </g>
                  );
                })}
              </Nigeria>
              <div
                id="tooltip"
                ref={toolTipRef}
                className={`bg-white border-b-[10px] border-grey-300 h-[160px] w-[200px] flex flex-col justify-center items-center`}
                style={{
                  position: "absolute",
                  top: activeMapCoord?.top,
                  left: activeMapCoord?.left,
                }}
              >
                {stateDetail?.length > 0 ? (
                  stateDetail?.map(
                    ({ state, aggregators, collectors, plastic }) => {
                      return (
                        <>
                          <p className="font-bold">{state}</p>
                          <p>
                            {`${
                              parseInt(plastic) > 1
                                ? "Collections(Kg):"
                                : "Collection(kg):"
                            }`}{" "}
                            {plastic}
                          </p>
                          <p>
                            {`${
                              parseInt(collectors) > 1
                                ? "Collectors:"
                                : "Collector:"
                            }`}{" "}
                            {collectors}{" "}
                          </p>
                          <p>
                            {`${
                              parseInt(aggregators) > 1
                                ? "Aggregators:"
                                : "Aggregator:"
                            }`}{" "}
                            {aggregators}{" "}
                          </p>
                        </>
                      );
                    }
                  )
                ) : (
                  <p>No state data</p>
                )}
              </div>
            </div>
            <div className="basis-[35%] bg-[#F9FAFB] p-4 rounded-md mr-3">
              <h1 className="mb-3 text-xl font-bold">States</h1>
              <div className="flex flex-col gap-4">
                {stateCollectionReport?.length > 0 ? (
                  stateCollectionReport
                    ?.map((data, index) => {
                      return {
                        ...data,
                        color: colors[index],
                      };
                    })
                    ?.map((data, index) => {
                      return (
                        <div
                          className={`flex flex-col gap-2 p-2 ${
                            index === 0 ? "bg-[#ECFDF3]" : ""
                          }`}
                        >
                          <p className="text-sm">{data.state}</p>
                          <div className="flex justify-between items-center">
                            <ProgressBar
                              completed={data.plastic}
                              bgColor={data.color}
                              height="10px"
                              isLabelVisible={false}
                              className="w-[150px]"
                            />
                            <p>{data.plastic}</p>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <p className="text-center">loading...</p>
                )}
              </div>
            </div>
          </div>
          <div className="border-b border-gray-300 "></div>
          <div className="flex flex-col lg:flex-row p-4 rounded-md">
            <div className="basis-[50%] p-4 rounded-md">
              <h1 className="h-[49px] border-b border-[#EAECF0] mb-5 font-bold">
                Top 5 states
              </h1>
              {topFiveStates?.length > 0 ? (
                topFiveStates
                  ?.map((data) => {
                    return {
                      ...data,
                      quantity: numberWithCommas(parseInt(data.quantity)),
                    };
                  })
                  ?.map((data, index) => {
                    return (
                      <div
                        className="border-b border-[#EAECF0] h-[60px]"
                        key={index}
                      >
                        <div className="flex justify-between items-center mb-3 h-full">
                          <div className="basis-[55%]">
                            <p className="font-bold text-sm">{data.state}</p>
                            {/* <p className="text-sm">{data.address}</p> */}
                          </div>
                          <p className="text-sm basis-[35%]">
                            {data.quantity}kg Collected
                          </p>{" "}
                          <p className="basis-[10%]">
                            <AiOutlineRight />
                          </p>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <p className="text-center">loading...</p>
              )}
            </div>
            <div className="basis-[50%] p-4 rounded-md">
              <h1 className="h-[49px] border-b border-[#EAECF0] mb-5 font-bold">
                Top 5 Location
              </h1>
              {topFiveLocation?.length > 0 ? (
                topFiveLocation
                  ?.map((data) => {
                    return {
                      ...data,
                      quantity: numberWithCommas(parseInt(data.quantity)),
                    };
                  })
                  .map((data, index) => {
                    return (
                      <div
                        className="border-b border-[#EAECF0] h-[60px]"
                        key={index}
                      >
                        <div className="flex justify-between items-center mb-3 h-full">
                          <div className="basis-[55%]">
                            <p className="font-bold text-sm">{data.location}</p>
                            {/* <p className="text-sm">{data.address}</p> */}
                          </div>
                          <p className="text-sm basis-[35%]">
                            {data.quantity}kg Collected
                          </p>
                          <p className="basis-[10%]">
                            <AiOutlineRight />
                          </p>
                        </div>
                      </div>
                    );
                  })
              ) : (
                <p className="text-center">loading...</p>
              )}
            </div>
          </div>
        </div>
        <div className="h-max border border-gray-300 rounded-md p-4 relative">
          <div className="flex flex-col lg:flex-row lg:justify-between border-b border-gray-300 mb-16 h-max p-4">
            <div>
              <p className="font-bold">Plastic processed</p>
              <p className="text-sm">Track the rate of collection over time.</p>
            </div>
            <div className="flex rounded-md w-max">
              {[
                { name: "Project Overview", year: 1 },
                { name: "2022", year: 2022 },
                { name: "2023", year: 2023 },
                { name: "2024", year: 2024 },
              ]?.map((data, index) => {
                return (
                  <button
                    key={index}
                    className={`w-max border border-gray-300 p-2 text-sm ${
                      index === 2 ? "rounded-br rounded-tr" : ""
                    } ${
                      index === 0
                        ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                        : ""
                    }`}
                    onClick={() => setProcessedYear(data.year)}
                  >
                    {data.name}
                  </button>
                );
              })}
            </div>
            <div className="flex self-end absolute top-36 xl:top-28 right-5">
              <InputSelect
                css={"w-[185px]"}
                placeholder={"Calendar Type"}
                value={processedReportTimeline}
                options={["Monthly", "Quarterly"]}
                handleChange={(e) => setProcessedReportTimeline(e.target.value)}
              />
            </div>
          </div>
          <div className="h-[500px] flex justify-center">
            <LineCharts
              fill="#F79009"
              datakeyX={"date"}
              dataKeyB={"plastics"}
              lineData={processedReport}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
