import React from "react";

const Gombe = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M448.712 220.69L449.05 219.47L447.881 218.057L446.369 215.145L445.512 211.993L445.157 208.718L443.573 205.374L432.673 197.67L430.483 194.732L430.783 193.047L436.802 190.127L436.944 188.654L436.737 187.127L436.845 180.052L435.754 176.999L432.352 174.98L428.584 173.277L428.021 169.273L426.367 165.752L420.709 163.445L420.861 162.029L423.625 158.336L424.428 156.436L426.23 150.418L427.852 146.629L429.408 145.342L433.422 144.174L435.153 142.918L437.687 139.623L442.646 136.891L443.902 135.593L444.77 134.437L445.84 133.698L453.164 131.853L460.422 135.225L463.791 137.722L466.707 140.678L469.285 143.989L474.812 149.308L475.931 150.662L476.281 152.403L475.598 164.103L475.959 167.765L476.423 169.274L480.142 169.629L482.13 169.427L482.785 173.866L478.88 179.886L478.754 182.129L479.858 184.359L481.502 186.28L483.511 187.615L485.794 188.444L487.732 189.685L491.287 195.145L495.05 197.397L494.499 201.824L494.777 208.529L493.969 210.64L491.998 212.184L482.217 217.765L477.733 219.66L472.879 220.405L454.328 221.133L448.712 220.69Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Gombe;
