import React from "react";

const Plateau = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M421.858 225.247L423 227.699L423.36 230.361L422.951 233.623L423.541 237.408L423.984 238.914L424.846 240.298L426.381 245.116L426.375 246.594L426.009 248.163L426.686 251.295L426.752 253.251L424.928 256.52L416.567 260.441L404.34 263.28L401.774 266.167L400.578 267.986L397.814 271.145L396.334 272.406L392.566 274.36L389.092 276.745L386.018 279.709L382.605 282.198L378.777 283.225L374.801 283.493L371.929 283.31L369.991 280.656L367.146 278.957L360.953 276.465L357.66 276.358L354.296 277.324L349.452 278.119L344.472 278.079L341.25 277.25L336.854 275.045L335.701 272.957L335.996 270.516L335.63 268.415L334.347 266.697L333.315 264.257L334.227 262.131L335.559 260.602L336.809 258.8L338.764 258.198L339.992 257.047L342.221 254.184L343.007 252.416L343.269 250.963L342.324 249.828L340.866 248.914L339.2 249.328L338.392 249.697L337.502 249.824L333.897 249.878L330.429 249.232L327.901 246.773L327.076 243.309L324.498 241.503L321.44 240.06L322.532 237.16L322.761 234.071L320.768 231.355L318.261 228.875L316.121 225.862L316.104 218.637L317.611 217.596L317.764 216.666L317.742 215.817L319.937 212.954L319.489 211.242L319.784 209.27L319.768 205.382L321.854 198.698L320.484 194.851L321.445 191.066L324.088 188.225L326.005 187.367L327.315 185.776L327.545 184.065L328.047 182.594L331.084 182.098L334.18 182.874L336.07 185.081L337.118 187.881L337.386 190.379L337.321 198.496L339.265 199.742L345.392 198.716L348.772 199.877L349.913 203.356L350.132 207.516L349.723 209.048L348.805 210.407L348.107 211.875L347.844 213.451L349.111 214.663L351.034 215.23L353.399 217.859L355.211 220.904L358.504 221.911L365.952 223.219L374.324 222.81L376.367 222.402L378.262 221.66L379.84 218.541L381.183 217.422L384.64 216.167L385.137 214.505L384.427 212.708L382.384 209.95L383.073 208.777L383.832 208.305L385.498 207.951L411.395 219.395L420.214 224.613L421.858 225.247Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Plateau;
