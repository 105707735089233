import React from "react";

const Ekiti = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M156.378 297.557L155.324 298.968L155.406 301.103L156.411 301.46L157.842 300.654L159.748 300.229L161.702 300.046L162.685 300.175L163.467 300.716L163.385 301.84L162.211 302.499L160.687 304.317L160.288 306.468L161.07 308.642L162.811 310.122L163.532 310.077L164.389 310.79L168.54 312.728L170.926 313.406L169.993 314.847L168.682 315.326L167.229 314.714L165.859 315.432L165.345 316.343L164.696 317.196L163.304 317.951L161.01 320.295L160.311 321.762L159.367 326.561L156.473 332.984L150.908 337.448L147.931 336.345L146.818 332.845L145.523 330.391L142.847 329.614L139.084 329.82L133.301 329.427L131.307 329.498L127.604 330.643L123.427 319.917L123.154 317.3L123.723 315.075L124.154 309.01L126.596 305.942L128.617 305.028L130.244 303.64L131.446 299.529L132.052 299.883L132.32 300.75L133.423 301.175L136.721 300.245L140.222 302.309L142.314 302.473L143.613 302.73L145.836 302.381L146.272 302.511L146.748 302.376L147.403 301.196L147.709 299.577L148.828 298.28L150.718 297.736L156.378 297.557Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Ekiti;
