import React from "react";
import { PiUsers } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import moment from "moment/moment";

const ViewDetail = ({
  detail,
  closeModal,
  loading,
  title,
  subtitle,
  createdAt,
  id,
}) => {
  return (
    <div className="h-[524px]">
      <div className="flex flex-col gap-y-2 w-[400px] md:w-[640px] lg:w-[640px]  bg-white p-4">
        <div className="flex justify-between">
          <PiUsers style={{ width: 26, height: 26 }} />
          <IoCloseOutline
            style={{ width: 26, height: 26, cursor: "pointer" }}
            onClick={() => closeModal()}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <div>
            <h1 className="capitalize font-bold">{title}</h1>
            {subtitle ? <p className="text-sm">{subtitle}</p> : ""}
          </div>
          {/* {editbutton ? (
          <button className="w-max p-1 border border-gray-300">Edit</button>
        ) : (
          ""
        )} */}
        </div>
        {/* {loading ? (
          <div className="flex justify-center">
            <h3>Loading details</h3>
          </div>
        ) : ( */}
        <div className="flex gap-10 w-full">
          <div className="flex flex-col gap-2 mb-3 w-full">
            {Object?.entries(detail)?.map(([key, value]) => {
              if (!value) {
                return null;
              }
              if (key === "yearOfIncorporation") {
                return (
                  <div className="flex">
                    <p>{key}:</p>
                    <p className="capitalize">
                      {moment(value).format("YYYY-MM-DD")}
                    </p>
                  </div>
                );
              }
              return (
                <div className="flex mb-3 gap-4 w-full items-center">
                  <p className="capitalize w-full flex">
                    <span className="inline-block">{key}:</span>
                    <span className="capitalize ml-2 inline-block flex gap-2">
                      {value}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        {/* )} */}

        <div className="bg-[#EDFCF2] flex flex-col justify-center rounded-md h-[100px] w-[98%] pl-4">
          <p>Date Created</p>
          <p>
            {createdAt
              ? moment(createdAt).format("YYYY-MM-DD")
              : "Not available"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewDetail;
