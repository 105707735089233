import React, { useEffect, useState } from "react";
import { login } from "../ds/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../feature/auth";
import { gatAllAggregator } from "../ds/aggregators";
import { gatAllCollector } from "../ds/collectors";
import {
  getLga,
  getLocationCollectionReport,
  getParticipantReport,
  getPerformanceReport,
  getPlasticCollectionReport,
  getPlasticProcessedReport,
  getProgram,
  getSourceOfPlastic,
  getState,
  getLgaByState,
  getStateCollectionReport,
  getTopAggregators,
  getTopCollectors,
  getTopLocation,
  getTopState,
  getTypeOfPlastic,
} from "../ds/resource";
const useResource = (program) => {
  const [loading, setLoading] = useState();
  const [paReport, setPaReport] = useState({});
  const [peReport, setPeReport] = useState({});
  const [topFiveCollector, setTopFiveCollectors] = useState([]);
  const [topFiveAggregator, setTopFiveAggregators] = useState([]);
  const [topFiveAggYear, setTopFiveAggYear] = useState(1);
  const [topFiveColYear, setTopFiveColYear] = useState(1);
  const [collectionReport, setCollectionReport] = useState([]);
  const [processedReport, setProcessedReport] = useState([]);
  const [procssedYear, setProcessedYear] = useState(1);
  const [collectionYear, setCollectionYear] = useState(1);
  const [stateYear, setStateYear] = useState(1);
  const [locColYear, setLocColYear] = useState(1);
  const [stateCollectionReport, setStateCollectionReport] = useState();
  const [locationCollectionReport, setLocationCollectionReport] = useState();
  const [topFiveStates, setTopFiveStates] = useState([]);
  const [topFiveLocation, setTopFiveLocation] = useState([]);
  const [topStateYear, setTopStateYear] = useState(1);
  const [topLocationYear, setTopLocationYear] = useState(1);
  const [typeOfPlastic, setTypeOfPlastic] = useState([]);
  const [typeOfPlasticId, setTypeOfPlasticId] = useState([]);
  const [sourceOfPlastic, setSourceOfPlastic] = useState([]);
  const [sourceOfPlasticId, setSourceOfPlasticId] = useState([]);
  const [collectionReportTimeline, setCollectionReportTimeline] =
    useState("Monthly");
  const [processedReportTimeline, setProcessedReportTimeline] =
    useState("Monthly");
  const [aggregatorId, setAggregatorId] = useState();

  useEffect(() => {
    if (program) {
      const getAllParticipantReports = async () => {
        try {
          const res = await getAllParticipantReport(program);
          setPaReport(JSON.parse(res).data);
        } catch (error) {}
      };
      getAllParticipantReports();
    }
  }, [program]);
  useEffect(() => {
    if (program) {
      const getAllPerformanceReports = async () => {
        try {
          const res = await getAllPerformanceReport({
            program,
            sourceOfPlasticId: sourceOfPlasticId?.value
              ? sourceOfPlasticId?.value
              : "" || "",
            typeOfPlasticId:
              typeOfPlasticId?.value !== 0 ? typeOfPlasticId?.value : "" || "",
            aggregatorId: aggregatorId !== 0 ? aggregatorId : "" || "",
          });
          setPeReport(JSON.parse(res).data);
        } catch (error) {}
      };
      getAllPerformanceReports();
    }
  }, [program, typeOfPlasticId, sourceOfPlasticId, aggregatorId]);

  useEffect(() => {
    if (topFiveColYear || program) {
      const getTopCollector = async () => {
        try {
          const res = await getTopFiveCollectors(topFiveColYear, program);
          setTopFiveCollectors(JSON.parse(res).data);
        } catch (error) {}
      };
      getTopCollector();
    }
  }, [topFiveColYear, program]);
  useEffect(() => {
    if (topFiveAggYear || program) {
      const getTopAggregator = async () => {
        try {
          const res = await getTopFiveAggregators(topFiveAggYear, program);
          setTopFiveAggregators(JSON.parse(res).data);
        } catch (error) {}
      };
      getTopAggregator();
    }
  }, [topFiveAggYear, program]);
  useEffect(() => {
    if (collectionYear || program) {
      const getReport = async () => {
        try {
          const res = await getPlasticCollectionReport(
            collectionYear,
            collectionReportTimeline,
            program
          );
          setCollectionReport(JSON.parse(res).data);
        } catch (error) {}
      };
      getReport();
    }
  }, [collectionYear, collectionReportTimeline, program]);
  useEffect(() => {
    if (procssedYear || program) {
      const getReport = async () => {
        try {
          const resPlastic = await getPlasticProcessedReport(
            procssedYear,
            processedReportTimeline,
            program
          );
          setProcessedReport(JSON.parse(resPlastic).data);
        } catch (error) {}
      };
      getReport();
    }
  }, [procssedYear, processedReportTimeline, program]);
  useEffect(() => {
    if (stateYear || program) {
      const getReport = async () => {
        try {
          const resPlastic = await getStateCollectionReport(stateYear, program);
          setStateCollectionReport(JSON.parse(resPlastic).data);
        } catch (error) {}
      };
      getReport();
    }
  }, [stateYear, program]);
  useEffect(() => {
    if (locColYear || program) {
      const getReport = async () => {
        try {
          const resPlastic = await getLocationCollectionReport(
            locColYear,
            program
          );
          setLocationCollectionReport(JSON.parse(resPlastic).data);
        } catch (error) {}
      };
      getReport();
    }
  }, [locColYear, program]);
  useEffect(() => {
    if (topLocationYear || program) {
      const getTopLocations = async () => {
        const res = await getTopFiveLocations(topLocationYear, program);
        setTopFiveLocation(JSON.parse(res).data);
      };
      getTopLocations();
    }
  }, [topLocationYear, program]);
  useEffect(() => {
    if (topStateYear || program) {
      const getTopStates = async () => {
        const res = await getTopFiveStates(topStateYear, program);
        setTopFiveStates(JSON.parse(res).data);
      };
      getTopStates();
    }
  }, [topStateYear, program]);

  useEffect(() => {
    if (program) {
      const getSourceOfPlastic = async () => {
        try {
          const res = await getAllSourceOfPlastics(program);
          setSourceOfPlastic(JSON.parse(res).data);
        } catch (error) {}
      };
      getSourceOfPlastic();
    }
  }, [program]);
  useEffect(() => {
    if (program) {
      const getTypeOfPlastic = async () => {
        try {
          const res = await getAllTypeOfPlastics(program);
          setTypeOfPlastic(JSON.parse(res).data);
        } catch (error) {}
      };
      getTypeOfPlastic();
    }
  }, [program]);

  const getAllStates = async (program) => {
    setLoading(true);
    const res = await getState(program);
    return res;
  };
  const getAllLgas = async (program) => {
    setLoading(true);
    const res = await getLga(program);
    return res;
  };
  const getAllTypeOfPlastics = async (program) => {
    try {
      setLoading(true);
      const res = await getTypeOfPlastic(program);
      return res;
    } catch (error) {}
  };
  const getAllSourceOfPlastics = async (program) => {
    try {
      setLoading(true);
      const res = await getSourceOfPlastic(program);
      return res;
    } catch (error) {}
  };
  const getTopFiveAggregators = async (year, program) => {
    setLoading(true);
    const res = await getTopAggregators(year, program);
    return res;
  };
  const getTopFiveCollectors = async (year, program) => {
    setLoading(true);
    const res = await getTopCollectors(year, program);
    return res;
  };
  const getTopFiveStates = async (year, program) => {
    setLoading(true);
    const res = await getTopState(year, program);
    return res;
  };
  const getTopFiveLocations = async (year, program) => {
    setLoading(true);
    const res = await getTopLocation(year, program);
    return res;
  };
  const getAllProgram = async () => {
    setLoading(true);
    const res = await getProgram();
    return res;
  };
  const getAllPerformanceReport = async ({
    program,
    sourceOfPlasticId,
    typeOfPlasticId,
    aggregatorId,
  }) => {
    setLoading(true);
    const res = await getPerformanceReport(
      program,
      sourceOfPlasticId,
      typeOfPlasticId,
      aggregatorId
    );
    return res;
  };
  const getAllParticipantReport = async (program) => {
    setLoading(true);
    const res = await getParticipantReport(program);
    return res;
  };
  const getLgaFromState = async (id) => {
    setLoading(true);
    const res = await getLgaByState(id);
    return res;
  };

  return {
    loading,
    getAllStates,
    getLgaFromState,
    getAllLgas,
    getAllTypeOfPlastics,
    getAllSourceOfPlastics,
    getTopAggregators,
    getTopCollectors,
    getTopFiveStates,
    getTopFiveLocations,
    getAllProgram,
    getAllPerformanceReport,
    getAllParticipantReport,
    paReport,
    peReport,
    topFiveCollector,
    setTopFiveColYear,
    setTopFiveAggYear,
    topFiveAggregator,
    collectionReport,
    processedReport,
    setProcessedYear,
    setCollectionYear,
    setStateYear,
    stateCollectionReport,
    locationCollectionReport,
    setLocColYear,
    topFiveStates,
    topFiveLocation,
    setTopStateYear,
    setTopLocationYear,
    typeOfPlastic,
    sourceOfPlastic,
    setCollectionReportTimeline,
    setProcessedReportTimeline,
    collectionReportTimeline,
    processedReportTimeline,
    setTypeOfPlasticId,
    setSourceOfPlasticId,
    setAggregatorId,
  };
};

export default useResource;
