/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect, forwardRef } from "react";
import { PiUsers } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import InputText from "../input/InputText";
import InputSelect from "../input/InputSelect";
import InputSelect2 from "../input/InputSelect2";
import useCollector from "../../hooks/useCollector";
import SearchableDropdown2 from "../input/SearchableDropdown2";
import { getState, getLgaByState } from "../../ds/resource";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { Form } from "react-router-dom";
import { Formik } from "formik";
import {
  createCollectorSchema,
  createCollectorSchemaTwo,
  createCollectorTwoSchema,
} from "../../utils/validationSchema/collectorSchema";
import { useSelector } from "react-redux";

export const CollectorModal = ({
  closeModal,
  requestType,
  aggregatorList,
  getCollectors,
  userData,
  states,
  lgas,
  setSelectedStateId,
  isAggregator,
  selectOptDefault,
}) => {
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { createNewCollector, updateExistingCollector } = useCollector();
  const [aggregatorid, setAggregatorId] = useState(selectOptDefault?.value);

  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);

  const { program } = useSelector((state) => state.auth);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col">
      <label className={`after:content-['*'] after:text-red-500`}>
        Date of Birth
      </label>
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-[288px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));
  const ExampleCustomInputTwo = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col">
      <label className={`after:content-['*'] after:text-red-500`}>
        Enrollment Date
      </label>
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-[288px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));

  const initialValues = {
    address: requestType === "edit" ? userData.address : "",
    firstName: requestType === "edit" ? userData.firstName : "",
    lastName: requestType === "edit" ? userData.lastName : "",
    phoneNumber: requestType === "edit" ? userData.phoneNumber : "",
    email: requestType === "edit" ? userData.email : "",
    location: requestType === "edit" ? userData.location : "",
    state: requestType === "edit" ? userData.state : "",
    gender: requestType === "edit" ? userData.gender : "",
    dateOfBirth: requestType === "edit" ? userData.dateOfBirth : "",
    aggregatorId: requestType === "edit" ? selectOptDefault.value : "",
    disabilityStatus: requestType === "edit" ? userData.disabilityStatus : "",
    baselineIncomeMonthly:
      requestType === "edit" ? userData.baselineIncomeMonthly : 0,
    isCurrentIncomeHigher:
      requestType === "edit" ? userData.isCurrentIncomeHigher : "",
    isCollectorJobMoreStable:
      requestType === "edit" ? userData.isCollectorJobMoreStable : "",
    ageRange: requestType === "edit" ? userData.ageRange : "",
    existingCollector: requestType === "edit" ? userData.existingCollector : "",
    institution: requestType === "edit" ? userData.institution : "",
  };

  const [isLoading, setisLoading] = useState(false);
  const onSubmit = async (data) => {
    console.log({ data });
    setLoading(true);
    try {
      if (requestType === "edit") {
        setLoading(true);
        const res = await updateExistingCollector({
          ...data,
          id: userData.id,
          programId: program,
          aggregatorId: isAggregator
            ? selectOptDefault?.value
            : data.aggregatorId,
        });
        if (res.code === "00") {
          toast.success("Collector updated");
          closeModal();
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error updating collector");
        }
      }
      if (requestType !== "edit") {
        setLoading(true);
        const res = await createNewCollector({
          ...data,
          programId: program,
          aggregatorId: isAggregator
            ? selectOptDefault?.value
            : data.aggregatorId,
        });
        if (res.code === "00") {
          toast.success("Collector created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating Collector");
        }
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      getCollectors();
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    {
      start
        ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
        : setFormatStartDate(null);
    }

    {
      end
        ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
        : setFormatEndDate(null);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        isAggregator && program !== 2
          ? createCollectorSchemaTwo
          : program === 2
          ? createCollectorTwoSchema
          : !isAggregator && program !== 2
          ? createCollectorSchema
          : createCollectorSchema
      }
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit, setFieldValue }) => {
        return (
          <Form>
            <div style={{ height: "500px" }}>
              <div className="flex flex-col gap-y-2 min-w-[300px] md:min-w-[450px] lg:w-[640px] bg-white p-4 h-max">
                <div className="flex justify-between">
                  <PiUsers style={{ width: 26, height: 26 }} />
                  <IoCloseOutline
                    style={{ width: 26, height: 26, cursor: "pointer" }}
                    onClick={() => closeModal()}
                  />
                </div>
                <div className="">
                  <h1 className="capitalize font-bold">
                    {requestType === "edit"
                      ? "Edit Collector"
                      : "Create new Collector"}
                  </h1>
                  <p className="text-sm">Enter the details below</p>
                </div>
                <div className="flex justify-between gap-2">
                  <InputText
                    label={"FirstName"}
                    placeholder={"Enter first name"}
                    name={"firstName"}
                    fieldRequired={true}
                    error={errors.firstName}
                    value={values.firstName}
                    handleChange={handleChange}
                  />
                  <InputText
                    label={"LastName"}
                    error={errors.lastName}
                    name={"lastName"}
                    fieldRequired={true}
                    placeholder={"Enter last name"}
                    value={values.lastName}
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex justify-between w-full gap-2">
                  <InputText
                    label={"Phone Number"}
                    placeholder={"Enter phone number"}
                    fieldRequired={true}
                    className={`${isAggregator ? "w-full" : ""}`}
                    textStyle={`${isAggregator ? "w-full" : ""}`}
                    name={"phoneNumber"}
                    value={values.phoneNumber}
                    error={errors.phoneNumber}
                    handleChange={handleChange}
                  />
                  {!isAggregator ? (
                    <SearchableDropdown2
                      label={"Aggregator"}
                      options={aggregatorList}
                      name="aggregatorId"
                      error={errors.aggregatorId}
                      dropDownStyle={{
                        display: isAggregator ? "none" : "block",
                      }}
                      // selectedOption={isAggregator ? selectOptDefault?.value : ""}
                      // selectedOption={selectOptDefault?.value}
                      // className={"hidden"}
                      // defaultInputValue={values.aggregatorId}
                      fieldRequired={true}
                      defaultValue={
                        requestType === "edit" ? [selectOptDefault] : []
                      }
                      placeholder="Select aggregator"
                      handleChange={(selectionOption) => {
                        setFieldValue("aggregatorId", selectionOption.value);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex justify-between w-full gap-2">
                  <InputText
                    label={"Email Address"}
                    placeholder={"Enter email address"}
                    name={"email"}
                    // fieldRequired={true}
                    value={values.email}
                    error={errors.email}
                    handleChange={handleChange}
                  />
                  <InputSelect
                    label={"Disability Status"}
                    placeholder="Select disability status"
                    options={["yes", "no"]}
                    fieldRequired={true}
                    name="disabilityStatus"
                    error={errors.disabilityStatus}
                    handleChange={handleChange}
                  />
                </div>
                <div className="w-full flex justify-between gap-2">
                  <InputText
                    label={"Address"}
                    placeholder={"Enter address"}
                    name={"address"}
                    textStyle={"sm:mt-6"}
                    value={values.address}
                    fieldRequired={true}
                    error={errors.address}
                    handleChange={handleChange}
                  />
                  <InputText
                    label={"Baseline Income Monthly(NGN)"}
                    placeholder={"Enter address"}
                    name={"baselineIncomeMonthly"}
                    value={values.baselineIncomeMonthly}
                    // fieldRequired={true}
                    error={errors.baselineIncomeMonthly}
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex justify-between w-full">
                  <SearchableDropdown2
                    label={"State"}
                    options={states}
                    name="state"
                    error={errors.state}
                    fieldRequired={true}
                    placeholder="Select state"
                    defaultInputValue={values.state}
                    handleChange={(selectionOption) => {
                      setFieldValue("state", selectionOption?.label);
                      setSelectedStateId(selectionOption?.value);
                    }}
                  />

                  <SearchableDropdown2
                    label={"Lga"}
                    options={lgas}
                    name="location"
                    error={errors.location}
                    fieldRequired={true}
                    defaultInputValue={values.location}
                    placeholder="Select lga"
                    handleChange={(selectionOption) =>
                      setFieldValue("location", selectionOption?.label)
                    }
                  />
                </div>
                <div className="flex justify-between gap-2">
                  <InputSelect
                    label={"Gender"}
                    placeholder="Select gender"
                    name="gender"
                    fieldRequired={true}
                    value={values.gender}
                    error={errors.gender}
                    options={["Male", "Female", "Mixed"]}
                    handleChange={handleChange}
                  />
                  {program === 1 ? (
                    <div className="flex flex-col">
                      <DatePicker
                        selected={
                          values.dateOfBirth ? values.dateOfBirth : startDate
                        }
                        onChange={(date) => {
                          setStartDate(date);
                          setFieldValue(
                            "dateOfBirth",
                            moment(date).format("YYYY-MM-DD")
                          );
                        }}
                        customInput={<ExampleCustomInput />}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                      />
                      {/* {errors.dateOfBirth ? (
                      <p className="text-red-500">{errors.dateOfBirth}</p>
                    ) : (
                      ""
                    )} */}
                    </div>
                  ) : (
                    " "
                  )}
                  {program === 2 ? (
                    <SearchableDropdown2
                      label={"Age Range"}
                      options={[
                        {
                          label: "15-29",
                          value: "15-29",
                        },
                        {
                          label: "30+",
                          value: "30+",
                        },
                        {
                          label: "Mixed",
                          value: "Mixed",
                        },
                      ]}
                      name="state"
                      error={errors.ageRange}
                      fieldRequired={true}
                      placeholder="Select age range"
                      defaultInputValue={values.ageRange}
                      handleChange={(selectionOption) => {
                        setFieldValue("ageRange", selectionOption?.value);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex justify-between w-full">
                  <SearchableDropdown2
                    label={"Is this an Existing/New Collector?"}
                    options={[
                      {
                        label: "New",
                        value: "New",
                      },
                      {
                        label: "Existing",
                        value: "Existing",
                      },
                    ]}
                    name="state"
                    error={errors.existingCollector}
                    fieldRequired={true}
                    placeholder="Select existing"
                    defaultInputValue={values.existingCollector}
                    handleChange={(selectionOption) => {
                      setFieldValue(
                        "existingCollector",
                        selectionOption?.label
                      );
                    }}
                  />

                  <InputSelect
                    label={"Is this an institution?"}
                    placeholder="Select option"
                    name="gender"
                    fieldRequired={true}
                    value={values.institution}
                    error={errors.institution}
                    options={["Yes", "No"]}
                    handleChange={(e) => {
                      setFieldValue("institution", e.target.value);
                    }}
                  />
                </div>
                <div className="flex justify-between gap-2">
                  <div className="flex flex-col">
                    <DatePicker
                      selected={
                        values.enrollmentDate
                          ? values.enrollmentDate
                          : startDate
                      }
                      onChange={(date) => {
                        setStartDate(date);
                        setFieldValue(
                          "enrollmentDate",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      customInput={<ExampleCustomInputTwo />}
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                    />
                    {/* {errors.dateOfBirth ? (
                      <p className="text-red-500">{errors.dateOfBirth}</p>
                    ) : (
                      ""
                    )} */}
                  </div>
                </div>
                {/* <div className="flex justify-between w-full">
                  <SearchableDropdown2
                    label={"Is Current Income Higher"}
                    options={[
                      { label: "yes", value: 1 },
                      { label: "no", value: 2 },
                    ]}
                    name="isCurrentIncomeHigher"
                    error={errors.isCurrentIncomeHigher}
                    placeholder="Select answer"
                    defaultInputValue={values.isCurrentIncomeHigher}
                    handleChange={(selectionOption) =>
                      setFieldValue(
                        "isCurrentIncomeHigher",
                        selectionOption.label
                      )
                    }
                  />

                  <SearchableDropdown2
                    label={"Is Collector Job More Stable"}
                    options={[
                      { label: "yes", value: 1 },
                      { label: "no", value: 2 },
                    ]}
                    name="isCollectorJobMoreStable"
                    error={errors.isCollectorJobMoreStable}
                    defaultInputValue={values.isCollectorJobMoreStable}
                    placeholder="Select answer"
                    handleChange={(selectionOption) =>
                      setFieldValue(
                        "isCollectorJobMoreStable",
                        selectionOption.label
                      )
                    }
                  />
                </div> */}

                <br />
                <div className="flex gap-2 justify-center">
                  <button
                    className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>

                  {requestType === "edit" ? (
                    <button
                      type="submit"
                      disabled={loading ? true : false}
                      onClick={handleSubmit}
                      className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                    >
                      {loading ? "Updating..." : "Save changes"}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={loading ? true : false}
                      onClick={handleSubmit}
                      className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                    >
                      {loading ? "Creating..." : "Create Collector"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
