import React from "react";

const Enugu = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M259.821 347.825L260.346 349.859L262.394 353.87L263.841 355.59L265.605 357.172L267.571 358.536L269.581 359.46L271.721 359.219L273.283 358.105L275.194 357.634L277.383 358.646L278.704 360.647L279.289 363.071L279.273 365.519L277.465 370.23L273.947 370.575L272.173 370.429L270.491 371.425L272.571 377.864L272.522 381.451L270.217 388.133L271.167 389.476L271.244 390.159L271.861 392.276L271.954 393.796L272.337 395.38L272.435 396.895L271.397 398.402L270.108 399.739L269.764 402.27L270.36 404.904L268.94 405.599L267.051 403.94L265.926 403.199L264.56 402.548L261.923 403.34L258.477 400.862L255.926 400.953L253.414 401.349L253.272 401.176L252.95 400.94L252.42 400.832L252.153 400.299L251.208 399.786L250.635 399.71L249.237 400.104L247.741 399.879L246.589 398.618L246.02 397.058L245.163 395.69L244.535 394.217L244.83 392.467L243.563 391.586L243.339 390.368L242.465 389.855L241.723 390.415L240.888 390.574L240.451 389.988L240.653 389.309L240.779 388.434L240.757 387.487L241.101 387.307L241.45 386.917L241.324 386.403L241.024 385.945L239.326 382.288L238.244 380.889L237.441 380.471L235.677 380.152L234.896 379.792L234.432 378.553L235.857 374.768L238.8 371.726L239.029 368.337L237.413 367.676L235.725 367.337L232.678 366.106L229.859 366.211L229.171 365.904L229.22 364.286L228.762 362.874L230.176 360.206L231.929 359.133L232.065 359.543L232.071 360.001L232.316 360.716L232.671 361.016L233.512 362.197L234.839 362.763L237.176 361.048L239.164 358.738L243.582 355.817L246.209 353.499L249.235 349.757L250.206 348.91L251.856 348.362L253.554 347.1L255.482 346.143L258.066 346.291L259.821 347.825Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Enugu;
