import * as yup from "yup";
export const createTransactionSchema = yup.object().shape({
  plastics: yup.array().of(
    yup.object().shape({
      amount: yup.number("Enter a valid number").required("Amount is required"),
      quantity: yup
        .number("Enter a valid number")
        .required("Quantity is required"),
      price: yup.number("Enter a valid number").required("Price is required"),
      collectorId: yup.string().required("Collector is required"),
      collectorName: yup.string(),
      aggregatorId: yup.string().required("Aggregator is required"),
      aggregatorName: yup.string(),
      typeOfPlasticId: yup.string().required("Type of plastic is required"),
      sourceOfPlasticId: yup.string().required("Source of plastic is required"),
      transactionDate: yup.string().required("Recycler is required"),
    })
  ),
});
export const createTransactionSchemaTwo = yup.object().shape({
  plastics: yup.array().of(
    yup.object().shape({
      amount: yup.number("Enter a valid number").required("Amount is required"),
      quantity: yup
        .number("Enter a valid number")
        .required("Quantity is required"),
      price: yup.number("Enter a valid number").required("Price is required"),
      collectorId: yup.string().required("Collector is required"),
      collecorName: yup.string(),
      aggregatorId: yup.string(),
      aggregatorName: yup.string(),
      typeOfPlasticId: yup.string().required("Type of plastic is required"),
      sourceOfPlasticId: yup.string().required("Source of plastic is required"),
      transactionDate: yup.string().required("Recycler is required"),
    })
  ),
});
