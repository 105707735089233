import React from "react";

const Abuja = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M232.575 280.244L232.105 280.293L230.216 280.602L228.25 280.398L225.012 280.497L222.603 280.228L221.32 278.088L220.998 277.183L220.501 241.031L220.736 239.597L222.019 239.026L231.783 238.661L234.967 239.605L239.904 244.174L242.393 245.497L242.727 245.416L250.153 235.359L255.172 234.842L256.652 234.888L258.11 235.107L259.437 235.636L260.584 234.82L262.266 232.62L264.155 233.298L265.127 235.017L266.798 234.686L266.924 235.696L266.744 236.669L266.111 237.058L265.499 237.232L264.532 238.897L263.314 244.331L263.184 247.485L263.381 252.437L260.907 262.666L258.625 268.731L256.763 271.566L254.453 274.018L247.654 277.224L240.194 279.131L232.575 280.244Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Abuja;
