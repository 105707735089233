import * as yup from "yup";
export const createWaybillSchema = yup.object({
  amount: yup.number("Enter a valid number").required("Amount is required"),
  quantity: yup.number("Enter a valid number").required("Quantity is required"),
  aggregatorId: yup.string().required("Aggregator is required"),
  recyclerId: yup.string().required("Recycler is required"),
  transactionDate: yup.string().required("Recycler is required"),
  file: yup.string().nullable(true),
});
export const createWaybillSchemaTwo = yup.object({
  amount: yup.number("Enter a valid number").required("Amount is required"),
  quantity: yup.number("Enter a valid number").required("Quantity is required"),
  aggregatorId: yup.string(),
  recyclerId: yup.string().required("Recycler is required"),
  transactionDate: yup.string().required("Recycler is required"),
  file: yup.string().nullable(true),
});
