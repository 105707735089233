import React, { Children, useRef, useState } from "react";
import { BsBoxArrowRight } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PiArrowFatLinesRight, PiArrowFatLinesLeft } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../feature/auth";
import useOutsideClick from "../../hooks/useOutsideClick";
import SettingsModal from "../modal/SettingsModal";
import Modal from "../Modal";
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import EciLogo from "../../assets/images/gbf_logo.svg";
import NpsapLogo from "../../assets/images/npsap_logo.jpeg";
import { FaTimes } from "react-icons/fa";

const DashboardSidebar = ({
  setCloseNav,
  closeNav,
  navs = [],
  setShowmobileNav,
}) => {
  let location = useLocation();
  const wrapperRef = useRef(null);
  const [showSettings, setShowSettings] = useOutsideClick(wrapperRef);
  const [isSubMenuShow, setIsSubMenuShow] = useState(false);
  const { program } = useSelector((state) => state.auth);

  // const { logout } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.clear();
    localStorage.removeItem("persist:root");
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className={` h-screen`}>
      <div className="flex justify-between items-center mb-10 px-2">
        <FaTimes
          onClick={() => setShowmobileNav(false)}
          className="w-[30px] h-30 h-max lg:hidden mt-3"
        />
        <div className="w-[100px] h-30 h-max sm:hidden md:hidden lg:block">
          <img
            src={program === 1 ? EciLogo : NpsapLogo}
            alt={"logo"}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        {closeNav ? (
          <PiArrowFatLinesRight
            style={{
              width: 24,
              height: 24,
              cursor: "pointer",
            }}
            className="sm:hidden md:hidden lg:block"
            onClick={() => setCloseNav((prev) => !prev)}
          />
        ) : (
          <PiArrowFatLinesLeft
            style={{
              width: 24,
              height: 24,
              cursor: "pointer",
            }}
            className="sm:hidden md:hidden lg:block"
            onClick={() => setCloseNav((prev) => !prev)}
          />
        )}
      </div>
      <div className={`flex flex-col ${closeNav ? "items-center" : ""} h-full`}>
        <div className="basis-[75%]">
          <ul>
            {navs.map(({ id, Icon, name, route, children }, index) => {
              return (
                <Link to={`${route}`} key={`${index}`}>
                  <li className={`flex flex-col`}>
                    <div
                      className={`flex items-center justify-between gap-4 h-10 rounded-md ${
                        location.pathname === route
                          ? "bg-green-700 text-white"
                          : ""
                      } ${
                        children && isSubMenuShow && location.pathname !== route
                          ? "bg-[#F0F1F1]"
                          : ""
                      }  p-2`}
                      onClick={() =>
                        setIsSubMenuShow(children ? !isSubMenuShow : "")
                      }
                    >
                      <div className="flex items-center gap-4">
                        <Icon
                          style={{
                            width: 24,
                            height: 24,
                            color: `${
                              location.pathname === route ? "white" : ""
                            }`,
                          }}
                        />
                        {closeNav ? "" : name}
                      </div>
                      {children ? (
                        <div>
                          {isSubMenuShow ? (
                            <IoIosArrowDown className="self-end" />
                          ) : (
                            <IoIosArrowUp className="self-end" />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {children && isSubMenuShow && (
                      <div
                        className={`h-max w-full p-2 bg-[#F0F1F1] ${
                          closeNav ? "items-center" : ""
                        }`}
                      >
                        <ul className="h-max w-full flex flex-col boder border-gray-300">
                          {children?.map(
                            ({ id, route: routeValue, Icon, name }, index) => {
                              return (
                                <li
                                  key={`${index}-${id}`}
                                  className={`flex items-center  gap-4 h-10 rounded-md mb-1 ${
                                    location.pathname === routeValue
                                      ? "bg-green-700 text-white"
                                      : "bg-white"
                                  }   p-2`}
                                  onClick={() => navigate(routeValue)}
                                >
                                  <Icon
                                    style={{
                                      width: 24,
                                      height: 24,
                                      color: `${
                                        location.pathname === routeValue
                                          ? "white"
                                          : "black"
                                      }`,
                                    }}
                                  />
                                  {closeNav ? "" : name}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
        <div className="basis-[25%] items-end">
          {/* <button
              className="flex items-center gap-4 h-10 p-2"
              onClick={() => setShowSettings(true)}
            >
              <GoGear style={{ width: 24, height: 24 }} />
              {closeNav ? "" : "Settings"}
            </button> */}
          <div className="">
            <button
              className="flex items-center gap-4 h-10 p-2 "
              onClick={() => logoutUser()}
            >
              <BsBoxArrowRight style={{ width: 24, height: 24 }} />
              {closeNav ? "" : "Logout"}
            </button>
          </div>
          <p className={` ${closeNav ? "w-[50px] text-xs" : "text-sm"} mb-4`}>
            2024 © Waste4Reward.
          </p>
        </div>
      </div>
      {showSettings && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowSettings(false)}
        >
          <SettingsModal closeModal={() => setShowSettings(false)} />
        </Modal>
      )}
    </div>
  );
};

export default DashboardSidebar;
