import React, { useState, useEffect, forwardRef } from "react";
import { PiUsers } from "react-icons/pi";
import { IoCloseOutline } from "react-icons/io5";
import InputText from "../input/InputText";
import toast from "react-hot-toast";
import useTransaction from "../../hooks/useTransaction";
import useAggregator from "../../hooks/useAggregator";
import useResource from "../../hooks/useResource";
import useCollector from "../../hooks/useCollector";
import SearchableDropdown2 from "../input/SearchableDropdown2";
import { toMoney } from "../../utils/utils";
import {
  FieldArray,
  Form,
  Formik,
  useFormik,
  FormikProviders,
  FormikProvider,
} from "formik";
import { useSelector } from "react-redux";
import {
  createTransactionSchema,
  createTransactionSchemaTwo,
} from "../../utils/validationSchema/transactionSchema";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const TransactionModal = ({
  closeModal,
  aggregatorList,
  collectorList,
  requestType,
  fetchTransactions,
  userData,
  isAggregator,
  selectOptDefault,
}) => {
  const [loading, setLoading] = useState(false);
  const { createNewAggregator } = useAggregator();
  const { getAllSourceOfPlastics, getAllTypeOfPlastics } = useResource();
  const { createNewTransaction } = useTransaction();
  const [plasticTypeList, setPlasticTypeList] = useState([]);
  const [plasticSourceList, setPlasticSourceList] = useState([]);
  const [typeOfPlastics, setTypeOfPlastics] = useState([]);
  const [lga, setLga] = useState([]);
  const [selectedTypeOfPlastic, setSelectedTypeOfPlastic] = useState("");
  const [selectedSourceOfPlastic, setSelectedSourceOfPlastic] = useState("");
  const { gatAllAggregatorLists } = useAggregator();
  const { gatAllCollectorList } = useCollector();
  const [startDate, setStartDate] = useState("");
  const [newCollection, setNewCollection] = useState({
    amount: 0,
    quantity: 0,
    price: 0,
    collectorId: null,
    collectorName: "",
    aggregatorId: null,
    aggregatorName: "",
    typeOfPlasticId: null,
    typeOfPlasticName: "",
    sourceOfPlasticId: null,
    sourceOfPlasticName: "",
    transactionDate: null,
  });
  console.log({ newCollection });
  const { program, user } = useSelector((state) => state.auth);

  useEffect(() => {
    const getPlasticTypes = async () => {
      const res = JSON.parse(await getAllTypeOfPlastics());
      const list = res?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setPlasticTypeList([...list]);
      // console.log(res.data);
    };

    const getPlasticSources = async () => {
      const res = JSON.parse(await getAllSourceOfPlastics());
      const list = res?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setPlasticSourceList([...list]);
      // console.log(res.data);
    };
    getPlasticTypes();
    getPlasticSources();
  }, []);

  const initialValues = {
    plastics: [],
  };

  const onSubmit = async (data) => {
    const newData = data.plastics.map((obj, index) => {
      return {
        amount: obj.amount,
        quantity: obj.quantity,
        price: obj.price,
        programId: program,
        collectorId: obj.collectorId,
        aggregatorId: isAggregator ? selectOptDefault?.value : obj.aggregatorId,
        typeOfPlasticId: obj.typeOfPlasticId,
        sourceOfPlasticId: obj.sourceOfPlasticId,
        transactionDate: obj.transactionDate,
      };
    });
    try {
      setLoading(true);
      const res = await createNewTransaction(newData);
      if (res.code === "00") {
        toast.success("Trasaction created");
        closeModal();
      }
      if (res.code !== "00") {
        setLoading(false);
        toast.error(res.message || "Error creating Transaction");
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      fetchTransactions();
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col">
      <label className={`after:content-['*'] after:text-red-500`}>
        Transaction Date
      </label>
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[140px] md:w-[200px] lg:w-full p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validateOnChange: isAggregator
      ? createTransactionSchemaTwo
      : createTransactionSchema,
  });

  return (
    <FormikProvider value={formik}>
      {/* <Formik
        initialValues={initialValues}
        validationSchema={
          isAggregator ? createTransactionSchemaTwo : createTransactionSchema
        }
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          errors,
          values,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => {
          return (
            <Form> */}
      <div className="flex flex-col md:flex-row lg:flex-row h-[570px] overflow-auto">
        <div className="flex flex-col w-[300px] md:w-[450px] lg:w-[571px] bg-white h-max">
          <div className="flex justify-between">
            <div className="h-[120px] p-[24px] flex flex-col gap-[16px]">
              <div className="flex justify-between ">
                <PiUsers style={{ width: 26, height: 26 }} />
              </div>
              <div className="flex flex-col gap-[4px]">
                <h1 className="capitalize font-bold">
                  {requestType === "edit"
                    ? "Edit Transaction"
                    : "create new Collections"}
                </h1>
                <p className="text-sm">Enter the details below</p>
              </div>
            </div>
            <div className="flex justify-end p-[8px] sm:block hidden pt-[24px]">
              <IoCloseOutline
                style={{
                  width: 26,
                  height: 26,
                  cursor: "pointer",
                }}
                onClick={() => closeModal()}
              />
            </div>
          </div>
          <div className="p-[24px] flex justify-center">
            <div className="flex flex-col items-center gap-[4.2px]">
              <div className="flex justify-between w-full">
                {!isAggregator ? (
                  <SearchableDropdown2
                    label={"Aggregator Name"}
                    options={aggregatorList}
                    className={"w-full"}
                    defaultValue={
                      newCollection.aggregatorId === null
                        ? ""
                        : newCollection.aggregatorId
                    }
                    dropDownStyle={{
                      display: isAggregator ? "none" : "block",
                    }}
                    // error={errors.aggregatorId}
                    placeholder="Select  aggregator"
                    handleChange={(e) => {
                      setNewCollection({
                        ...newCollection,
                        aggregatorName: e.label,
                        aggregatorId: e.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="flex justify-between w-full">
                <SearchableDropdown2
                  label={"Collector Name"}
                  options={collectorList}
                  defaultValue={newCollection.aggregatorName}
                  // defaultInputValue={values.collectorId}
                  className={`${isAggregator ? "w-full" : "w-full"}`}
                  error={formik.errors.collectorId}
                  placeholder="Select collector"
                  handleChange={(e) => {
                    setNewCollection({
                      ...newCollection,
                      collectorName: e.label,
                      collectorId: e.value,
                    });
                  }}
                />
              </div>
              <div className="flex justify-between w-full">
                <InputText
                  label={"Quantity(KG)"}
                  placeholder={"Enter quantity"}
                  name="quantity"
                  className={"w-full"}
                  textStyle={"w-full"}
                  value={newCollection.quantity}
                  // error={errors.quantity}
                  handleChange={(e) => {
                    setNewCollection({
                      ...newCollection,
                      quantity: e.target.value,
                    });
                  }}
                />
              </div>
              {program === 1 ? (
                <div className="flex justify-between w-full">
                  <InputText
                    label={"Unit Price(NGN)"}
                    placeholder={"Enter unit price"}
                    name="price"
                    className={"w-full"}
                    textStyle={"w-full"}
                    value={newCollection.price}
                    // error={formik.errors.price}
                    handleChange={(e) => {
                      setNewCollection({
                        ...newCollection,
                        price: e.target.value,
                      });
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {program === 1 ? (
                <div className="flex justify-between w-full">
                  <InputText
                    label={"Amount(NGN)"}
                    value={newCollection.quantity * newCollection.price}
                    // defaultValue={newCollection.quantity * newCollection.price}
                    name={"amount"}
                    className={"w-full"}
                    textStyle={"w-full"}
                    disabled={true}
                    // error={formik.errors.amount}
                    handleChange={(e) => {
                      setNewCollection({
                        ...newCollection,
                        amount: newCollection.quantity * newCollection.price,
                      });
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              {program === 2 ? (
                <div className="flex justify-between w-full">
                  <InputText
                    label={"Amount(NGN)"}
                    value={newCollection.amount}
                    // defaultValue={newCollection.quantity * newCollection.price}
                    name={"amount"}
                    className={"w-full"}
                    textStyle={"w-full"}
                    // error={formik.errors.amount}
                    handleChange={(e) => {
                      setNewCollection({
                        ...newCollection,
                        amount: e.target.value,
                      });
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="flex justify-between w-full">
                <SearchableDropdown2
                  label={"Source of plastic"}
                  options={plasticSourceList}
                  // defaultInputValue={newCollection.sourceOfPlasticId || null}
                  // error={errors.sourceOfPlasticId}
                  className={"w-full"}
                  placeholder="Select source of plastic"
                  handleChange={(e) => {
                    setNewCollection({
                      ...newCollection,
                      sourceOfPlasticId: e.value,
                      sourceOfPlasticName: e.label,
                    });
                  }}
                />
              </div>
              <div className="flex justify-between w-full">
                <SearchableDropdown2
                  label={"Type of plastic"}
                  options={plasticTypeList}
                  // error={errors.typeOfPlasticId}
                  placeholder="Select type of plastics"
                  className={"w-full"}
                  // defaultInputValue={values.typeOfPlasticId}
                  handleChange={(e) => {
                    setNewCollection({
                      ...newCollection,
                      typeOfPlasticId: e.value,
                      typeOfPlasticName: e.label,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col w-full">
                <DatePicker
                  selected={
                    newCollection.transactionDate
                      ? newCollection.transactionDate
                      : startDate
                  }
                  onChange={(date) => {
                    setStartDate(date);
                    setNewCollection({
                      ...newCollection,
                      transactionDate: moment(date).format("YYYY-MM-DD"),
                    });
                  }}
                  customInput={<ExampleCustomInput />}
                  showYearDropdown
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown
                />
                {/* {errors.dateOfBirth ? (
                      <p className="text-red-500">{errors.dateOfBirth}</p>
                    ) : (
                      ""
                    )} */}
              </div>
              <br />
              <div className={`flex justify-center items-center`}>
                <button
                  type="submit"
                  onClick={() => {
                    const newPlastics = [
                      ...formik.values.plastics,
                      {
                        amount:
                          program === 1
                            ? Number(
                                newCollection.quantity * newCollection.price
                              )
                            : Number(newCollection.amount),
                        quantity: Number(newCollection.quantity)
                          ? Number(newCollection.quantity)
                          : 0,
                        price: Number(newCollection.price)
                          ? Number(newCollection.price)
                          : 0,
                        collectorId: newCollection.collectorId,
                        collectorName: newCollection.collectorName,
                        aggregatorId: newCollection.aggregatorId,
                        aggregatorName: newCollection.aggregatorName,
                        typeOfPlasticId: newCollection.typeOfPlasticId,
                        typeOfPlasticName: newCollection.typeOfPlasticName,
                        sourceOfPlasticId: newCollection.sourceOfPlasticId,
                        sourceOfPlasticName: newCollection.sourceOfPlasticName,
                        transactionDate: newCollection.transactionDate,
                      },
                    ];
                    formik.setFieldValue("plastics", newPlastics);
                    setNewCollection({
                      amount: 0,
                      quantity: 0,
                      price: 0,
                      collectorId: newCollection.collectorId,
                      collectorName: newCollection.collectorName,
                      aggregatorId: newCollection.aggregatorId,
                      aggregatorName: newCollection.aggregatorName,
                      typeOfPlasticId: newCollection.typeOfPlasticId,
                      typeOfPlasticName: newCollection.typeOfPlasticName,
                      sourceOfPlasticId: newCollection.sourceOfPlasticId,
                      sourceOfPlasticName: newCollection.sourceOfPlasticName,
                      transactionDate: newCollection.transactionDate,
                    });
                  }}
                  className="bg-white text-black border border-gray-300 flex justify-center items-center h-[40px] w-[275px] lg:w-[475px] rounded-[8px]"
                >
                  Add Item
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[300px] lg:w-[448px] bg-gray-200 h-max ">
          <div className="h-[160px] p-[24px] mb-[20px]">
            <div className="flex justify-end p-[8px] sm:hidden">
              <IoCloseOutline
                style={{
                  width: 26,
                  height: 26,
                  cursor: "pointer",
                }}
                onClick={() => closeModal()}
              />
            </div>
            <div className="mb-[20px]">
              <h2 className="font-semibold">Collection items</h2>
              <p className="text-sm">All items being collected</p>
            </div>
          </div>
          <div
            className={`pl-[24px] overflow-auto ${
              program === 2 ? "h-[430px]" : "h-[510px]"
            } mb-[26px] mt-[50px]`}
          >
            <FieldArray name="plastics">
              {({ form, insert, remove, push }) => (
                <>
                  {form.values.plastics.map((data, index) => (
                    <div
                      className="bg-white mb-2 w-[396px] p-[12px] rounded-[8px] relative"
                      key={index}
                    >
                      {user.userType !== "AGGREGATOR" ? (
                        <div className="flex items-center gap-4">
                          <p>Aggregator name</p>{" "}
                          <p className="text-sm">{data?.aggregatorName}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="flex items-center gap-4">
                        <p>Collectors name</p>{" "}
                        <p className="text-sm">{data?.collectorName}</p>
                      </div>
                      <div className="flex items-center gap-6">
                        <p>Quantity</p>{" "}
                        <p className="text-sm">{data?.quantity}</p>
                      </div>
                      <div className="flex items-center gap-4">
                        <p>Amount</p> <p className="text-sm">{data?.amount}</p>
                      </div>
                      <div className="flex items-center gap-4">
                        <p>Type of Plastic</p>{" "}
                        <p className="text-sm">{data?.typeOfPlasticName}</p>
                      </div>
                      <div className="flex items-center gap-4">
                        <p>Source of Plastic</p>{" "}
                        <p className="text-sm">{data?.sourceOfPlasticName}</p>
                      </div>
                      <div className="flex items-center gap-4">
                        <p>Transaction Date</p>{" "}
                        <p className="text-sm">{data?.transactionDate}</p>
                      </div>
                      <div className="flex h-[120px] absolute top-[10px] right-[12px]">
                        <IoCloseOutline
                          style={{
                            width: 26,
                            height: 26,
                            cursor: "pointer",
                            justifySelf: "flex-end",
                          }}
                          onClick={() => remove(index)}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          </div>
          <div className="flex gap-2 justify-center items-center mb-[20px] mt-[60px]">
            <button
              className="px-2 w-[120px] lg:w-[194px] h-[40px] bg-white font-normal text-xs flex justify-center items-center disabled:cursor-not-allowed border border-pfBlack md:w-272"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading ? true : false}
              onClick={formik.handleSubmit}
              className="bg-green-700 text-white flex justify-center items-center h-[40px] w-[135px] lg:w-[194px]"
            >
              {loading ? "Creating..." : "Create Collection"}
            </button>
          </div>
        </div>
      </div>
      {/* </Form>
          );
        }}
      </Formik> */}
    </FormikProvider>
  );
};

// <div className="pl-[24px] overflow-auto h-[445px] mb-[10px]">
//     <div className="bg-white mb-2 w-[396px] p-[12px] rounded-[8px] relative">
//       <div className="flex gap-4">
//         <p>Aggregator name</p> <p>{data?.aggregatorName}</p>
//       </div>
//       <div className="flex gap-4">
//         <p>Collectors name</p> <p>{data?.collectorName}</p>
//       </div>
//       <div className="flex gap-6">
//         <p>Quantity</p> <p>{data?.quantity}</p>
//       </div>
//       <div className="flex gap-4">
//         <p>Amount</p> <p>{data?.amount}</p>
//       </div>
//       <div className="flex gap-4">
//         <p>Type of Plastic</p> <p>{data?.typeOfPlasticName}</p>
//       </div>
//       <div className="flex gap-4">
//         <p>Source of Plastic</p> <p>{data?.sourceOfPlasticName}</p>
//       </div>
//       <div className="flex h-[120px] absolute top-[10px] right-[12px]">
//         <IoCloseOutline
//           style={{
//             width: 26,
//             height: 26,
//             cursor: "pointer",
//             justifySelf: "flex-end",
//           }}
//           onClick={() => remove(index)}
//         />
//       </div>
//     </div>
//   </div>
