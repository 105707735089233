import React, { useCallback, useEffect, useState } from "react";
import { login } from "../ds/auth";
import { useDispatch } from "react-redux";
import { setUser } from "../feature/auth";
import toast from "react-hot-toast";

import {
  createAggregator,
  gatAllAggregator,
  getAllAggregatorList,
  getAggregatorDetail,
  updateAggregator,
  downloadAggregator,
} from "../ds/aggregators";
import { getTopAggregators } from "../ds/resource";
import { downloadCsvFile } from "../utils/utils";

const useAggregator = () => {
  const [loading, setLoading] = useState();
  const [aggregatorCount, setAggregatorCount] = useState();
  const [downloadData, setDownloadData] = useState("");
  const dispatch = useDispatch();
  // console.log({ program });

  const gatAllAggregators = async (
    page = 1,
    size = 10,
    query,
    selectedState,
    program,
    startDate,
    endDate
  ) => {
    setLoading(true);
    const res = await gatAllAggregator({
      page,
      size,
      nameOrEmailOrPhoneNumber: query || "",
      state: selectedState || "",
      program,
      startDate: startDate || "",
      endDate: endDate || "",
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const resetAggregators = async (
    page = 1,
    size = 10,
    nameOrEmailOrPhoneNumber = "",
    state = "",
    location = ""
  ) => {
    setLoading(true);
    const res = await gatAllAggregator({
      page,
      size,
      nameOrEmailOrPhoneNumber,
      state,
      location,
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const getSingleAggregator = async (aggregatorId) => {
    setLoading(true);
    const res = await getAggregatorDetail({ id: aggregatorId });
    setLoading(false);
    return res;
  };

  const gatAllAggregatorLists = useCallback(async (program) => {
    setLoading(true);
    const res = await getAllAggregatorList(program);
    // console.log({ res }, "hook");
    setAggregatorCount(res?.data?.length);
    return res;
  }, []);

  // useEffect(() => {
  //   gatAllAggregatorLists();
  // }, []);

  const createNewAggregator = async (data) => {
    const res = await createAggregator(data);
    return res;
  };

  const updateExistingAggregator = async (data) => {
    const res = await updateAggregator(data);
    return res;
  };

  const aggregatorDownload = async ({
    program,
    query,
    state,
    startDate,
    endDate,
  }) => {
    toast.success("download...");
    const res = await downloadAggregator({
      program,
      nameOrEmailOrPhoneNumber: query || "",
      state: state || "",
      startDate: startDate || "",
      endDate: endDate || "",
    });
    return downloadCsvFile(res, "aggregator.csv", setDownloadData, toast);
  };

  return {
    loading,
    gatAllAggregators,
    getSingleAggregator,
    gatAllAggregatorLists,
    aggregatorCount,
    createNewAggregator,
    updateExistingAggregator,
    resetAggregators,
    aggregatorDownload,
  };
};

export default useAggregator;
