import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import TopCard from "../../components/card/TopCard";
import CustomTable from "../../components/table/CustomTable";
import { GoPlus } from "react-icons/go";
import DataCard from "../../components/card/DataCard";
import InputSearch from "../../components/input/InputSearch";
import Character from "../../assets/images/Character.png";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/Modal";
import { CollectorModal } from "../../components/modal/CollectorModal";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import ViewDetail from "../../components/modal/ViewDetail";
import PaginationPane from "../../components/table/PaginationPane";
import { getLga, getLgaByState, getState } from "../../ds/resource";
import useCollector from "../../hooks/useCollector";
import useAggregator from "../../hooks/useAggregator";
import useResource from "../../hooks/useResource";
import { toMoney } from "../../utils/utils";
import { useSelector } from "react-redux";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const headers = [
  "Collectors",
  "Aggregators",
  "Phone Number",
  "Age",
  "Disability",
  "State",
  "Enrollment Date",
  "Entry Date",
];
const headersNpsa = [
  "Collectors",
  "Aggregators",
  "Phone Number",
  "Age Range",
  "Disability",
  "State",
  "Enrollment Date",
  "Entry sDate",
];

const Collector = () => {
  const wrapperRef = useRef(null);
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [viewDetail, setViewDetail] = useOutsideClick(wrapperRef);
  const [editDetail, setEditDetail] = useOutsideClick(wrapperRef);
  const { gatAllAggregatorLists } = useAggregator();
  const { loading, gatAllCollectors, collectorDownload } = useCollector();
  const [states, setStates] = useState([]);
  const [lga, setLga] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedLga, setSelectedLga] = useState(null);
  const [aggregatorList, setAggregatorList] = useState([]);
  const [aggId, setAggId] = useState(null);
  const [collectors, setCollectors] = useState([]);
  const [collectorId, setCollectorId] = useState(1);
  const [collectorDetail, setCollectorDetail] = useState({});
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const { program, user } = useSelector((state) => state.auth);
  const limit = 10;

  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);

  const getCollectors = useCallback(
    async (
      newQuery,
      selState,
      selLga,
      newaggId,
      formatStaDate,
      formatEdDate
    ) => {
      const queryData = query || newQuery;
      const newState = selectedState?.label || selState?.label;
      const newlga = selectedLga?.value || selLga?.value;
      const newAgg = aggId?.value || newaggId?.value;
      const newEdDate = formatEndDate || formatEdDate;
      const newStaDate = formatStartDate || formatStaDate;
      const res = await gatAllCollectors({
        page: page,
        limit: limit,
        query: queryData,
        selectedState: newState,
        selectedLga: newlga,
        aggId: newAgg,
        program: program,
        startDate: newStaDate,
        endDate: newEdDate,
      });
      setTotalPages(res?.data?.totalPages);
      setTotalElements(res?.data?.totalElements);
      setCollectors(res?.data?.content);
    },
    [page, program, query]
  );

  useEffect(() => {
    getCollectors();
  }, [page, program, query, getCollectors]);

  const reset = () => {
    setAggId(null);
    setSelectedState(null);
    setSelectedLga(null);
    setPage(1);
    setQuery("");
    setStartDate("");
    setEndDate("");
    getCollectors();
  };
  useEffect(() => {
    const getAllState = async () => {
      const res = await getState(program);
      const list = JSON.parse(res)?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setStates([...list]);
    };
    getAllState();
  }, []);

  useEffect(() => {
    if (selectedStateId) {
      const getAllLga = async () => {
        const res = await getLgaByState(selectedStateId);
        // setLga(res.data);

        const list = res.data.map((item) => {
          return {
            label: item.name,
            value: item.name,
          };
        });
        setLga([...list]);
      };
      getAllLga();
    }
  }, [selectedStateId]);
  useEffect(() => {
    const getAllLgas = async () => {
      const res = await getLga();
      // setLga(res.data);

      const list = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setLgas([...list]);
    };
    getAllLgas();
  }, [selectedStateId]);
  useEffect(() => {
    const getAggregatorsList = async () => {
      const res = await gatAllAggregatorLists(program);
      const list = JSON.parse(res)?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setAggregatorList([...list]);
    };
    getAggregatorsList();
  }, [program]);

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    start
      ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
      : setFormatStartDate(null);

    end
      ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
      : setFormatEndDate(null);
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[260px] md:w-[170px] lg:w-[230px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date"}</h3>
      </div>
    </div>
  ));

  const nextPage = () => {
    setPage((prev) => (prev >= totalPages ? 1 : prev + 1));
    getCollectors(
      query,
      selectedState,
      selectedLga,
      aggId,
      collectorId,
      formatStartDate,
      formatEndDate
    );
  };
  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
    getCollectors(
      query,
      selectedState,
      selectedLga,
      aggId,
      collectorId,
      formatStartDate,
      formatEndDate
    );
  };

  const isAggregator = user.userType === "AGGREGATOR";

  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"Collectors"}
          subtitle={"List of collectors on the platform."}
          buttonTitle={"New Collector"}
          Icon={GoPlus}
          setShowModal={() => setShowModal(true)}
          exportType="collector"
          disabled={user?.userType === "AGGREGATOR" ? true : false}
          showCreateButtuon={user?.userType !== "AGGREGATOR" ? true : false}
          userType={user?.userType}
          downloadData={() =>
            collectorDownload({
              program: program,
              query: query,
              state: selectedState?.value,
              location: selectedLga?.value,
              aggregatorId: aggId?.value,
              startDate: formatStartDate,
              endDate: formatEndDate,
            })
          }
        />
      </div>
      <div className="mb-10">
        <DataCard
          css={"bg-[#FFFAEB]"}
          image={Character}
          title={"Total collectors"}
          subtitle={toMoney(totalElements) || 0}
        />
      </div>
      <div className="mb-10 flex flex-col justify-between md:flex-row lg:flex-row gap-2">
        <div className="flex flex-wrap lg:flex-row lg:flex-nowrap gap-2">
          {/* <SearchableDropdown
            options={aggregatorList}
            selectedOption={aggId}
            dropDownStyle={{ display: isAggregator ? "none" : "block" }}
            defaultInputValue="Filter by aggregator"
            handleChange={(e) => {
              setAggId(e);
            }}
          /> */}
          <SearchableDropdown
            options={aggregatorList || []}
            fieldRequired={true}
            selectedOption={aggId}
            defaultValue={null}
            dropDownStyle={{ display: isAggregator ? "none" : "block" }}
            placeholder="Filter by aggregator"
            handleChange={(e) => {
              setAggId(e);
            }}
          />
          <SearchableDropdown
            options={states || []}
            fieldRequired={true}
            defaultValue={null}
            selectedOption={selectedState}
            placeholder="Filter by state"
            handleChange={(e) => {
              setSelectedStateId(e?.value);
              setSelectedState(e);
            }}
          />
          <SearchableDropdown
            options={lga || []}
            fieldRequired={true}
            defaultValue={null}
            selectedOption={selectedLga}
            placeholder="Filter by lga"
            handleChange={(e) => {
              setSelectedLga(e);
            }}
          />
        </div>
        <div className="flex lg:flex-row lg:flex-nowrap gap-2">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<ExampleCustomInput />}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            isClearable
          />
          <InputSearch
            placeholder={"search"}
            inputValue={query}
            setInputValue={setQuery}
          />

          <button
            className="flex justify-center items-center h-[44px] w-[80px] lg:w-[101px] border border-gray-300 gap-2 rounded-md"
            onClick={() => {
              getCollectors(
                query,
                selectedState,
                selectedLga,
                aggId,
                collectorId,
                formatStartDate,
                formatEndDate
              );
            }}
          >
            Apply
          </button>
          <button
            className="flex justify-center items-center h-[44px] w-[60px] border border-gray-300 gap-2 rounded-md"
            onClick={() => reset()}
          >
            refresh
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center">
          <p className="text-center">Loading...</p>
        </div>
      ) : collectors?.length === 0 ? (
        <div className="flex justify-center">
          <p className="text-center">No data.</p>
        </div>
      ) : (
        <CustomTable
          headers={
            user.userType !== "AGGREGATOR" && program !== 2
              ? headers
              : program === 2
              ? headersNpsa
              : headers.filter((data) => data !== "Aggregators")
          }
          rows={
            user.userType !== "AGGREGATOR" && program !== 2
              ? collectors?.map((data, index) => {
                  return {
                    collector: (
                      <div className="flex flex-col" key={index}>
                        <p>
                          {data.firstName} {data.lastName}
                        </p>
                      </div>
                    ),
                    aggregator: data.aggregator ? data.aggregator : "N/A",
                    phone_number: data.phoneNumber ? data.phoneNumber : "N/A",
                    Age: data.age ? data.age : "N/A",
                    disability: data.disabilityStatus
                      ? data.disabilityStatus
                      : "N/A",
                    state: data.state ? data.state : "N/A",
                    enrollmentDate: data.enrollmentDate
                      ? data.enrollmentDate
                      : "N/A",
                    date: data.createdAt ? data.createdAt : "N/A",
                    edit: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setCollectorDetail(data);
                          setViewDetail(true);
                        }}
                      />
                    ),
                    open: user?.userType !== "PARTNER" && (
                      <FiEdit
                        key={index}
                        onClick={() => {
                          setCollectorDetail(data);
                          setEditDetail(true);
                        }}
                      />
                    ),
                  };
                })
              : program === 2
              ? collectors?.map((data, index) => {
                  return {
                    collector: (
                      <div className="flex flex-col" key={index}>
                        <p>
                          {data.firstName} {data.lastName}
                        </p>
                      </div>
                    ),
                    aggregator: data.aggregator ? data.aggregator : "N/A",
                    phone_number: data.phoneNumber ? data.phoneNumber : "N/A",
                    "Age Range": data.ageRange ? data.ageRange : "N/A",
                    disability: data.disabilityStatus
                      ? data.disabilityStatus
                      : "N/A",
                    state: data.state ? data.state : "N/A",
                    enrollmentDate: data.enrollmentDate
                      ? data.enrollmentDate
                      : "N/A",
                    date: data.createdAt ? data.createdAt : "N/A",
                    edit: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setCollectorDetail(data);
                          setViewDetail(true);
                        }}
                      />
                    ),
                    open: user?.userType !== "PARTNER" && (
                      <FiEdit
                        key={index}
                        onClick={() => {
                          setCollectorDetail(data);
                          setEditDetail(true);
                        }}
                      />
                    ),
                  };
                })
              : collectors?.map((data, index) => {
                  return {
                    collector: (
                      <div className="flex flex-col" key={index}>
                        <p>
                          {data.firstName} {data.lastName}
                        </p>
                      </div>
                    ),
                    // aggregator: data.aggregator ? data.aggregator : "N/A",
                    phone_number: data.phoneNumber ? data.phoneNumber : "N/A",
                    Age: data.age ? data.age : "N/A",
                    disability: data.disabilityStatus
                      ? data.disabilityStatus
                      : "N/A",
                    state: data.state ? data.state : "N/A",
                    enrollmentDate: data.enrollmentDate
                      ? data.enrollmentDate
                      : "N/A",

                    date: data.createdAt ? data.createdAt : "N/A",
                    edit: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setCollectorDetail(data);
                          setViewDetail(true);
                        }}
                      />
                    ),
                    open: user?.userType !== "PARTNER" && (
                      <FiEdit
                        key={index}
                        onClick={() => {
                          setCollectorDetail(data);
                          setEditDetail(true);
                        }}
                      />
                    ),
                  };
                })
          }
        />
      )}
      <PaginationPane
        currentPage={page > 1 ? page : 1}
        totalPages={totalPages || 1}
        nextPage={() => nextPage()}
        prevPage={() => prevPage()}
      />
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <CollectorModal
            closeModal={() => setShowModal(false)}
            aggregatorList={aggregatorList}
            getCollectors={getCollectors}
            states={states}
            lgas={lga}
            setSelectedStateId={setSelectedStateId}
            isAggregator={isAggregator}
            selectOptDefault={
              isAggregator
                ? { label: user.name, value: user?.aggregatorId }
                : {
                    label: collectorDetail.aggregator,
                    value: collectorDetail.aggregatorId,
                  }
            }
          />
        </Modal>
      )}
      {editDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditDetail(false)}
        >
          <CollectorModal
            closeModal={() => setEditDetail(false)}
            requestType={"edit"}
            aggregatorList={aggregatorList}
            getCollectors={getCollectors}
            userData={collectorDetail}
            states={states}
            lgas={lga}
            setSelectedStateId={setSelectedStateId}
            isAggregator={isAggregator}
            selectOptDefault={
              isAggregator
                ? { label: user.name, value: user?.aggregatorId }
                : {
                    label: collectorDetail.aggregator,
                    value: collectorDetail.aggregatorId,
                  }
            }
          />
        </Modal>
      )}
      {viewDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewDetail(false)}
        >
          <ViewDetail
            detail={
              user.userType !== "AGGREGATOR" && program !== 2
                ? {
                    "First Name": collectorDetail?.firstName
                      ? collectorDetail.firstName
                      : "N/A",
                    "Last Name": collectorDetail?.lastName
                      ? collectorDetail.lastName
                      : "N/A",
                    "Phone Number": collectorDetail.phoneNumber
                      ? collectorDetail.phoneNumber
                      : "N/A",
                    email: collectorDetail?.email
                      ? collectorDetail?.email
                      : "N/A",
                    Gender: collectorDetail?.gender
                      ? collectorDetail?.gender
                      : "N/A",
                    "Date Of Birth": collectorDetail?.dateOfBirth
                      ? collectorDetail?.dateOfBirth
                      : "N/A",
                    Address: collectorDetail.address
                      ? collectorDetail.address
                      : "N/A",
                    State: collectorDetail.state
                      ? collectorDetail.state
                      : "N/A",
                    Location: collectorDetail.location
                      ? collectorDetail.location
                      : "N/A",
                    "Disability Status": collectorDetail.disabilityStatus
                      ? collectorDetail.disabilityStatus
                      : "N/A",
                    "Baseline Income(Monthly)":
                      collectorDetail?.baselineIncomeMonthly
                        ? toMoney(collectorDetail?.baselineIncomeMonthly)
                        : "0",
                    Aggregator: collectorDetail.aggregator
                      ? collectorDetail.aggregator
                      : "N/A",
                  }
                : program === 2
                ? {
                    "First Name": collectorDetail?.firstName
                      ? collectorDetail?.firstName
                      : "N/A",
                    "Last Name": collectorDetail?.lastName
                      ? collectorDetail?.lastName
                      : "N/A",
                    "Phone Number": collectorDetail?.phoneNumber
                      ? collectorDetail.phoneNumber
                      : "N/A",
                    email: collectorDetail?.email
                      ? collectorDetail?.email
                      : "N/A",
                    Gender: collectorDetail?.gender
                      ? collectorDetail?.gender
                      : "N/A",
                    ageRange: collectorDetail?.ageRange
                      ? collectorDetail.ageRange
                      : "N/A",
                    Address: collectorDetail?.address
                      ? collectorDetail?.address
                      : "N/A",
                    State: collectorDetail?.state
                      ? collectorDetail?.state
                      : "N/A",
                    Location: collectorDetail?.location
                      ? collectorDetail?.location
                      : "N/A",
                    "Disability Status": collectorDetail?.disabilityStatus
                      ? collectorDetail?.disabilityStatus
                      : "N/A",
                    "Baseline Income(Monthly)":
                      collectorDetail?.baselineIncomeMonthly
                        ? collectorDetail?.baselineIncomeMonthly
                        : "0",
                  }
                : {
                    "First Name": collectorDetail?.firstName
                      ? collectorDetail?.firstName
                      : "N/A",
                    "Last Name": collectorDetail?.lastName
                      ? collectorDetail?.lastName
                      : "N/A",
                    "Phone Number": collectorDetail?.phoneNumber
                      ? collectorDetail.phoneNumber
                      : "N/A",
                    email: collectorDetail?.email
                      ? collectorDetail?.email
                      : "N/A",
                    Gender: collectorDetail?.gender
                      ? collectorDetail?.gender
                      : "N/A",
                    "Date Of Birth": collectorDetail?.DateOfBirth
                      ? collectorDetail.DateOfBirth
                      : "N/A",
                    Address: collectorDetail?.address
                      ? collectorDetail?.address
                      : "N/A",
                    State: collectorDetail?.state
                      ? collectorDetail?.state
                      : "N/A",
                    Location: collectorDetail?.location
                      ? collectorDetail?.location
                      : "N/A",
                    "Disability Status": collectorDetail?.disabilityStatus
                      ? collectorDetail?.disabilityStatus
                      : "N/A",
                    "Baseline Income(Monthly)":
                      collectorDetail?.baselineIncomeMonthly
                        ? collectorDetail?.baselineIncomeMonthly
                        : "0",
                  }
            }
            closeModal={() => setViewDetail(false)}
            title={"Collector Details"}
            subtitle={"Collector details below"}
            loading={loading}
            editbutton={true}
            id={collectorId}
            createdAt={collectorDetail.createdAt}
          />
        </Modal>
      )}
    </div>
  );
};

export default Collector;
