import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { gatAllUser } from "../ds/user";
import toast from "react-hot-toast";

import {
  gatAllWaybill,
  createWaybill,
  deleteWaybill,
  downloadWaybill,
  createWaybillAlt,
} from "../ds/waybill";
import { downloadCsvFile } from "../utils/utils";
import moment from "moment";

const useWaybill = () => {
  const [loading, setLoading] = useState();
  const [downloadData, setDownloadData] = useState("");

  const dispatch = useDispatch();

  const getAllWaybills = async (
    page = 1,
    size = 10,
    aggId,
    recycId,
    startDate,
    endDate,
    program
  ) => {
    setLoading(true);
    const res = await gatAllWaybill({
      page,
      size,
      aggregatorId: aggId || "",
      recycId: recycId || "",
      startDate: startDate || "",
      endDate: endDate || "",
      program,
    });
    setLoading(false);
    return JSON.parse(res);
  };

  const createNewWaybill = async (data) => {
    // for (var key of data.entries()) {
    //   console.log({ data }, "hook");
    //   console.log(key[0] + ", " + key[1]);
    // }
    const res = await createWaybill(data);

    return res;
  };
  const createNewWaybillAlte = async (data) => {
    const res = await createWaybillAlt(data);

    return res;
  };
  const deleteOneWaybill = async (data) => {
    const res = await deleteWaybill(data);
    return res;
  };

  const waybillDownload = async ({
    recyclerId,
    aggregatorId,
    startDate,
    endDate,
    program,
  }) => {
    toast.success("download...");
    const res = await downloadWaybill({
      recyclerId: recyclerId || "",
      aggregatorId: aggregatorId || "",
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      program,
    });
    return downloadCsvFile(res, "offtake.csv", setDownloadData, toast);
  };

  return {
    loading,
    getAllWaybills,
    createNewWaybill,
    deleteOneWaybill,
    waybillDownload,
    createNewWaybillAlte,
  };
};

export default useWaybill;
