async function fileFetch(url, options, searchParams, download) {
  console.log({ options });
  const apiUrl = process.env.REACT_APP_BACKEND_URL + url;

  const urlWithParams = new URL(apiUrl);
  urlWithParams.search = new URLSearchParams(searchParams).toString();

  const res = await fetch(urlWithParams, {
    ...options,
    // ...(options.cache && { cache: options.cache }),
    headers: {
      // "Content-Type": "multipart/form-data",
      ...options.headers,
    },
  });
  if (download) {
    const data = await res.text();
    return data;
  }

  const data = await res.json();
  return data;
}

export default fileFetch;
