import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import {
  createTransaction,
  deleteTransaction,
  downloadTransaction,
  getAllTransactions,
} from "../ds/transaction";
import { downloadCsvFile } from "../utils/utils";
import moment from "moment";

const useTransaction = (
  query,
  selectedState,
  aggId,
  colId,
  formatStartDate,
  formatEndDate,
  program
) => {
  const [loading, setLoading] = useState();
  const [downloadData, setDownloadData] = useState("");

  const getTransactions = async (
    page = 1,
    size = 10,
    query,
    selectedState,
    aggregatorId,
    collectorId,
    startDate,
    endDate,
    program
  ) => {
    setLoading(true);
    const res = await getAllTransactions({
      page,
      size,
      nameOrEmailOrPhoneNumber: query || "",
      state: selectedState || "",
      aggregatorId: aggregatorId || "",
      collectorId: collectorId || "",
      startDate: startDate || "",
      endDate: endDate || "",
      program,
    });

    setLoading(false);
    return JSON.parse(res);
  };

  const createNewTransaction = async (data) => {
    const res = await createTransaction(data);
    return res;
  };
  const deleteOneTransaction = async (id) => {
    const res = await deleteTransaction(id);

    return res;
  };

  const transactionDownload = async ({
    collectorId,
    aggregatorId,
    state,
    startDate,
    endDate,
    program,
  }) => {
    toast.success("download...");
    const res = await downloadTransaction({
      collectorId: collectorId || "",
      aggregatorId: aggregatorId || "",
      state: state || "",
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      endDate: startDate ? moment(endDate).format("YYYY-MM-DD") : "",
      program,
    });
    return downloadCsvFile(res, "transaction.csv", setDownloadData, toast);
  };

  return {
    loading,
    getTransactions,
    createNewTransaction,
    deleteOneTransaction,
    transactionDownload,
  };
};

export default useTransaction;
