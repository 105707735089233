import React, { useEffect, useState } from "react";
import DashboardSidebar from "../../components/sidebar/DashboardSidebar";
import Header from "../../components/header/Header";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import useNav from "../../hooks/useNav";
import { PiUserSquareLight, PiUsers } from "react-icons/pi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { BsFileBarGraph } from "react-icons/bs";
import { BiPieChartAlt2 } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { GoGear } from "react-icons/go";
import { BsBoxArrowRight } from "react-icons/bs";
import { BsArchive } from "react-icons/bs";
import { GoTag } from "react-icons/go";
import { useSelector } from "react-redux";
const navs = [
  {
    id: 1,
    route: "/admin/dashboard",
    name: "Dashboard",
    Icon: BsFileBarGraph,
    showArrow: false,
  },
  {
    id: 2,
    route: "/admin/aggregator",
    name: "Aggregators",
    Icon: PiUsers,
    showArrow: false,
  },
  {
    id: 3,
    route: "/admin/collector",
    name: "Collectors",
    Icon: BsArchive,
    showArrow: false,
  },
  {
    id: 4,
    route: "/admin/recycler",
    name: "Recyclers",
    Icon: RiDeleteBin7Line,
    showArrow: false,
  },
  {
    id: 5,
    route: "#",
    name: "Transactions",
    Icon: BiPieChartAlt2,
    showArrow: true,
    children: [
      {
        id: 1,
        route: "/admin/collection",
        name: "Collections",
        Icon: GoTag,
      },
      {
        id: 2,
        route: "/admin/offtake",
        name: "Offtake",
        Icon: CgFileDocument,
      },
    ],
  },
  {
    id: 6,
    route: "/admin/user",
    name: "Users",
    Icon: PiUserSquareLight,
    showArrow: false,
  },
];
const Admin = () => {
  const [showmobileNav, setShowmobileNav] = useState(false);
  const navigate = useNavigate();
  const { closeNav, setCloseNav } = useNav();
  const { program, user } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   navigate("/admin/dashboard");
  // }, []);

  return (
    <div className="flex relative">
      {showmobileNav ? (
        <aside
          className={`sm:w-[300px] md:w-[400px] lg:hidden z-50 bg-white h-screen fixed left-0 top-0 bottom-0 p-2 border-r border-gray-300 `}
        >
          <DashboardSidebar
            setCloseNav={setCloseNav}
            closeNav={closeNav}
            navs={
              user.userType !== "PARTNER" && user.userType !== "FIELD_OFFICER"
                ? navs
                : navs.filter((data) => data.name !== "Users")
            }
            setShowmobileNav={setShowmobileNav}
          />
        </aside>
      ) : (
        <aside
          className={`sm:hidden md:hidden lg:block h-screen fixed left-0 top-0 bottom-0 p-2     ${
            closeNav ? "lg:w-[113.75px]" : "lg:w-[224px] xl:w-[280px] mb-20"
          } border-r border-gray-300 `}
        >
          <DashboardSidebar
            setCloseNav={setCloseNav}
            closeNav={closeNav}
            navs={
              user.userType !== "PARTNER" && user.userType !== "FIELD_OFFICER"
                ? navs
                : navs.filter((data) => data.name !== "Users")
            }
            setShowmobileNav={setShowmobileNav}
          />
        </aside>
      )}
      <main
        className={`w-full ${
          closeNav ? "lg:pl-[113.75px]" : "lg:pl-[224px] xl:pl-[280px]"
        } `}
      >
        <Header setShowmobileNav={setShowmobileNav} />
        <Outlet />
      </main>
    </div>
  );
};

export default Admin;

// ${
//           closeNav ? "w-full pl-[100px]" : "w-full pl-[280px]"
//         }`

// ${closeNav ? "lg:w-lgclose" : "lg:w-lgopen"}
