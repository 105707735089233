import React from "react";
import SignIn from "../pages/auth/SignIn";
import DashGuard from "../route-guard/DashGuard";
import ChangePassword from "../pages/auth/ChangePassword";
import ResetPassword from "../pages/auth/ForgotPassword";

export const getAuthRouter = () => {
  return [
    {
      path: "/",
      element: (
        <DashGuard>
          <SignIn />,
        </DashGuard>
      ),
      // errorElement: <ErrorPage />,
    },
    {
      path: "signin",
      element: (
        <DashGuard>
          <SignIn />,
        </DashGuard>
      ),
      // errorElement: <ErrorPage />,
    },
    {
      path: "change_password",
      element: (
        <DashGuard>
          <ChangePassword />,
        </DashGuard>
      ),
      // errorElement: <ErrorPage />,
    },
    {
      path: "resetpassword",
      element: (
        <DashGuard>
          <ResetPassword />,
        </DashGuard>
      ),
      // errorElement: <ErrorPage />,
    },
  ];
};
