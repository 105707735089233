import api from "../api/axios";
import fetcher from "../api/fetacher";

export const gatAllCollector = async ({
  page,
  size,
  nameOrEmailOrPhoneNumber,
  aggregatorId,
  state,
  location,
  program,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
      programId: program,
      startDate,
      endDate,
    };
    if (nameOrEmailOrPhoneNumber) {
      params.nameOrEmailOrPhoneNumber = nameOrEmailOrPhoneNumber;
    }
    if (!!state && state !== "All States") {
      params.state = state;
    }
    if (!!location && location !== "All LGAs") {
      params.location = location;
    }
    if (!!aggregatorId) {
      params.aggregatorId = aggregatorId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/collector",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCollectorDetail = async ({ id }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      id,
    };
    const res = await fetcher(
      `/collector/${params.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllCollectorList = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    const res = await fetcher(
      "/collector/list",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    // console.log({ res });
    return res;
  } catch (error) {
    throw error;
  }
};

export const createCollector = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/collector", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const updateCollector = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/collector/update", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadCollector = async ({
  program,
  query,
  state,
  location,
  aggregatorId,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    if (state) {
      params.state = state;
    }
    if (location) {
      params.location = location;
    }
    if (query) {
      params.query = query;
    }
    if (aggregatorId) {
      params.aggregatorId = aggregatorId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/collector/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
