import React from "react";

const InputSelect = ({
  options = [],
  label,
  css,
  handleChange,
  value,
  name,
  placeholder,
  error,
  fieldRequired,
  containerClass,
  ...props
}) => {
  return (
    <div
      className={`flex flex-col ${containerClass ? containerClass : ""} gap 2`}
    >
      {label && (
        <label
          className={`${
            fieldRequired ? "after:content-['*'] after:text-red-500" : ""
          }`}
        >
          {label}
        </label>
      )}
      <select
        className={`border border-gray-300 h-[44px] ${
          css ? css : "lg:w-[288px] sm:w-[140px] md:w-[200px]"
        }"
        } p-2 rounded-md`}
        onChange={handleChange}
        value={value}
        name={name}
        {...props}
        style={{ outline: "none" }}
      >
        <option>{placeholder}</option>
        {options.map((data, index) => {
          return (
            <option className="p-2" key={index}>
              {data}
            </option>
          );
        })}
      </select>
      {error ? <p className="text-red-500">{error}</p> : ""}
    </div>
  );
};

export default InputSelect;
