import React, { useState } from "react";
import Header from "../components/header/Header";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../components/sidebar/DashboardSidebar";
import useNav from "../hooks/useNav";
import { PiUserSquareLight, PiUsers } from "react-icons/pi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { BsFileBarGraph } from "react-icons/bs";
import { BiPieChartAlt2 } from "react-icons/bi";
import { CgFileDocument } from "react-icons/cg";
import { GoGear } from "react-icons/go";
import { BsBoxArrowRight } from "react-icons/bs";
import { BsArchive } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { PiArrowFatLinesRight, PiArrowFatLinesLeft } from "react-icons/pi";
import { GoTag } from "react-icons/go";
import ErrorBoundary from "../components/ErrorBoundary/ErrorBondary";

const navs = [
  {
    route: "/dashboard",
    name: "Dashboard",
    Icon: BsFileBarGraph,
    showArrow: false,
  },
  {
    route: "/collector",
    name: "Collectors",
    Icon: BsArchive,
    showArrow: false,
  },
  {
    route: "/recycler",
    name: "Recyclers",
    Icon: RiDeleteBin7Line,
    showArrow: false,
  },
  {
    route: "#",
    name: "Transactions",
    Icon: BiPieChartAlt2,
    showArrow: true,
    children: [
      {
        id: 1,
        route: "/collection",
        name: "Collections",
        Icon: GoTag,
      },
      {
        id: 2,
        route: "/offtake",
        name: "Offtake",
        Icon: CgFileDocument,
      },
    ],
  },
];

const DashboardLayout = () => {
  const { closeNav, setCloseNav } = useNav();
  const [showmobileNav, setShowmobileNav] = useState(false);
  return (
    <div>
      {showmobileNav ? (
        <aside
          className={`sm:w-[50%] lg:hidden z-50 bg-white h-screen fixed left-0 top-0 bottom-0 p-2 border-r border-gray-300`}
        >
          <DashboardSidebar
            setCloseNav={setCloseNav}
            closeNav={closeNav}
            navs={navs}
            setShowmobileNav={setShowmobileNav}
          />
        </aside>
      ) : (
        <aside
          className={`sm:hidden md:hidden lg:block h-screen fixed left-0 top-0 bottom-0 p-2     ${
            closeNav ? "lg:w-[113.75px]" : "lg:w-[224px] xl:w-[280px] mb-20"
          } border-r border-gray-300 `}
        >
          <DashboardSidebar
            setCloseNav={setCloseNav}
            closeNav={closeNav}
            navs={navs}
            setShowmobileNav={setShowmobileNav}
          />
        </aside>
      )}
      <main
        className={`w-full ${
          closeNav ? "lg:pl-[113.75px]" : "lg:pl-[224px] xl:pl-[280px]"
        }`}
      >
        <Header setShowmobileNav={setShowmobileNav} />
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
};

export default DashboardLayout;
