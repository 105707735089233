import * as yup from "yup";
export const createRecyclerSchema = yup.object({
  name: yup.string().required("Name is required"),
  address: yup.string().required("Address is required"),
  state: yup.string().required("State is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNumber: yup.string(),
  enrollmentDate: yup.string().required("Enrollment date is required"),
  contactPersonFirstName: yup
    .string()
    .required("Contact person first name is required"),
  contactPersonLastName: yup
    .string()
    .required("Contact person Last name is required"),
});
