import React from "react";

const Ebonyi = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M301.996 367.942L302.76 370.721L304.776 372.913L305.458 373.447L306.207 373.877L306.605 374.36L306.769 374.933L307.687 375.889L307.927 377.455L308.457 378.839L309.576 380.187L309.762 381.772L306.479 383.532L306.07 385.19L307.086 386.691L306.644 390.114L303.663 392.976L302.445 394.451L301.452 395.961L300.485 399.755L299.594 401.077L298.088 400.43L296.657 400.426L295.99 401.505L295.149 402.481L292.708 403.575L291.746 404.456L290.386 404.512L289.338 402.238L287.372 401.701L285.232 402.351L284.614 403.62L285.467 405.195L284.817 407.434L282.933 410.165L282.218 410.833L282.147 412.024L282.339 413.204L281.645 414.189L280.783 415.125L280.313 416.306L280.116 417.567L278.161 416.892L276.446 415.65L275.589 414.1L275.196 412.357L272.356 411.098L264.677 411.011L261.537 409.517L262.013 406.284L261.925 403.341L264.563 402.549L265.928 403.2L267.054 403.941L268.943 405.6L270.362 404.905L269.767 402.271L270.111 399.74L271.399 398.403L272.437 396.896L272.339 395.381L271.956 393.797L271.864 392.277L271.246 390.16L271.169 389.477L270.219 388.134L272.525 381.452L272.574 377.865L270.493 371.426L272.175 370.43L273.95 370.576L277.467 370.231L279.193 373.696L282.824 375.824L285.162 372.824L285.681 368.593L286.5 366.927L288.756 366.658L290.831 366.631L292.868 366.174L294.844 363.669L296.04 363.425L297.269 363.447L297.842 362.634L298.082 362.095L299.13 362.194L299.699 363.007L300.13 364.967L300.786 366.64L301.996 367.942Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Ebonyi;
