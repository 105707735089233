import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
} from "react";
import TopCard from "../../components/card/TopCard";
import CustomTable from "../../components/table/CustomTable";
import { GoPlus } from "react-icons/go";
import useTransaction from "../../hooks/useTransaction";
import { getLga, getState } from "../../ds/resource";
import useOutsideClick from "../../hooks/useOutsideClick";
import { toMoney, toMoneyDecimal } from "../../utils/utils";
import Modal from "../../components/Modal";
import ViewDetail from "../../components/modal/ViewDetail";
import PaginationPane from "../../components/table/PaginationPane";
import { AggregatorModal } from "../../components/modal/AggregatorModal";
import { TransactionModal } from "../../components/modal/TransactionModal";
import useAggregator from "../../hooks/useAggregator";
import useCollector from "../../hooks/useCollector";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import { MdDelete } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";

//date range
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import DeleteModal from "../../components/modal/DeleteModal";
import toast from "react-hot-toast";

const headers = [
  "Aggregators",
  "Collectors",
  "Price",
  "Quantity(KG)",
  "Amount",
  "State",
  "Transaction Date",
  "Entry Date",
];
const headers3 = [
  "Aggregators",
  "Collectors",
  "Quantity(KG)",
  "Amount",
  "State",
  "Transaction Date",
  "Entry Date",
];
const headers2 = [
  "Collectors",
  "Price",
  "Quantity",
  "Amount",
  "State",
  "Transaction Date",
  "Entry Date",
];

const Collection = () => {
  const wrapperRef = useRef(null);
  const [query, setQuery] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [transactionDetail, setTransactionDetail] = useState({});
  const [states, setStates] = useState([]);
  const [lga, setLga] = useState([]);
  const [aggregatorList, setAggregatorList] = useState([]);
  const [aggId, setAggId] = useState(null);

  const [collectorList, setCollectorList] = useState([]);
  const [colId, setColId] = useState(null);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [viewDetail, setViewDetail] = useOutsideClick(wrapperRef);
  const [editDetail, setEditDetail] = useOutsideClick(wrapperRef);
  const [showDeleteModal, setShowDeleteModal] = useOutsideClick(wrapperRef);
  const [totalPages, setTotalPages] = useState(0);
  const [userId, setUserId] = useState(0);
  const { program, user } = useSelector((state) => state.auth);

  const limit = 10;
  console.log({ transactions });

  const { gatAllAggregatorLists } = useAggregator();
  const { gatAllCollectorList } = useCollector();
  const {
    loading,
    getTransactions,
    deleteOneTransaction,
    transactionDownload,
  } = useTransaction();

  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    {
      start
        ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
        : setFormatStartDate(null);
    }

    {
      end
        ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
        : setFormatEndDate(null);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[260px] md:w-[170px] lg:w-[280px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date range"}</h3>
      </div>
    </div>
  ));

  //fetch transactions
  const fetchTransactions = useCallback(
    async (
      newQuery,
      selState,
      newaggId,
      colectorId,
      formatStaDate,
      formatEdDate
    ) => {
      const queryData = query || newQuery;
      const newState = selectedState?.value || selState?.value;
      const newCol = colId?.value || colectorId?.value;
      const newAgg = aggId?.value || newaggId?.value;
      const newEdDate = formatEndDate || formatEdDate;
      const newStaDate = formatStartDate || formatStaDate;
      const res = await getTransactions(
        page,
        limit,
        queryData,
        newState,
        newAgg,
        newCol,
        newStaDate,
        newEdDate,
        program
      );
      setTotalPages(res?.data?.totalPages);
      setTransactions(res?.data?.content);
    },
    [page, program]
  );

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions, page, program]);

  useEffect(() => {
    const getAggregatorsList = async () => {
      const res = await gatAllAggregatorLists(program);
      const list = JSON.parse(res)?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      // console.log(list);
      setAggregatorList([...list]);
      // console.log(res.data);
    };

    const getCollectorsList = async () => {
      const res = await gatAllCollectorList(program);
      const list = JSON.parse(res)?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      // console.log(list);
      setCollectorList([...list]);
      // console.log(res.data);
    };

    getAggregatorsList();
    getCollectorsList();
  }, [program]);
  const handleDelete = (id) => {
    deleteOneTransaction(id);
    setTimeout(() => {
      fetchTransactions();
    }, 1000);
    toast.success("Deleted succesfully");
  };
  const reset = () => {
    setAggId(null);
    setColId(null);
    setSelectedState(null);
    setPage(1);
    setStartDate("");
    setEndDate("");
    fetchTransactions();
  };
  useEffect(() => {
    const getAllState = async () => {
      const res = await getState(program);
      const list = JSON.parse(res).data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });

      setStates([...list]);
    };
    getAllState();
  }, []);

  useEffect(() => {
    const getAllLga = async () => {
      const res = await getLga();
      setLga(res.data);

      const list = res.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
      setLga([...list]);
    };
    getAllLga();
  }, []);

  const nextPage = () => {
    setPage((prev) => (prev >= totalPages ? 1 : prev + 1));
    fetchTransactions(
      query,
      selectedState,
      aggId,
      colId,
      formatStartDate,
      formatEndDate
    );
  };
  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
    fetchTransactions(
      query,
      selectedState,
      aggId,
      colId,
      formatStartDate,
      formatEndDate
    );
  };
  const isAggregator = user.userType === "AGGREGATOR";

  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"Collections"}
          subtitle={"List of collections on the platform."}
          buttonTitle={"New Collections"}
          Icon={GoPlus}
          setShowModal={() => setShowModal(true)}
          exportType="transaction"
          userType={user?.userType}
          downloadData={() =>
            transactionDownload({
              collectorId: colId?.value,
              aggregatorId: aggId?.value,
              selectedState: selectedState?.value,
              startDate,
              endDate,
              program,
            })
          }
        />
      </div>
      <div className="mb-10 flex flex-col justify-between md:flex-row lg:flex-row gap-2">
        <div className="flex flex-wrap gap-4 md:gap-2 lg:gap-2">
          <SearchableDropdown
            options={aggregatorList || []}
            dropDownStyle={{ display: isAggregator ? "none" : "block" }}
            selectedOption={aggId}
            defaultValue={null}
            placeholder="Filter by aggregator"
            handleChange={(e) => {
              setAggId(e);
            }}
          />
          <SearchableDropdown
            options={collectorList || []}
            selectedOption={colId}
            defaultValue={null}
            placeholder="Filter by collector"
            handleChange={(value) => {
              setColId(value);
            }}
          />
          <SearchableDropdown
            options={states || []}
            selectedOption={selectedState}
            defaultValue={null}
            placeholder="Filter by state"
            handleChange={(e) => {
              setSelectedState(e);
            }}
          />
        </div>
        <div className="flex lg:flex-row lg:flex-nowrap gap-2">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<ExampleCustomInput />}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            isClearable
          />
          <button
            className="flex justify-center items-center h-[44px] w-[80px] lg:w-[101px] border border-gray-300 gap-2 rounded-md"
            onClick={() => {
              fetchTransactions(
                query,
                selectedState,
                aggId,
                colId,
                formatStartDate,
                formatEndDate
              );
            }}
          >
            Apply
          </button>
          <button
            className="flex justify-center items-center h-[44px] w-[60px] border border-gray-300 gap-2 rounded-md"
            onClick={() => reset()}
          >
            refresh
          </button>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center">
          <p className="text-center">Loading...</p>
        </div>
      ) : transactions?.length === 0 ? (
        <div className="flex justify-center">
          <p className="text-center">No data.</p>
        </div>
      ) : (
        <CustomTable
          headers={
            user?.userType !== "AGGREGATOR" && program !== 2
              ? headers
              : program === 2
              ? headers3
              : headers2
          }
          rows={
            user?.userType !== "AGGREGATOR" && program !== 2
              ? transactions?.map((data, index) => {
                  return {
                    aggregator: data.aggregator,
                    collector: data.collector,
                    price: "NGN " + toMoney(data.price),
                    quantity: toMoneyDecimal(data.quantity),
                    amount: "NGN " + toMoney(data.amount),
                    state: data.state,
                    transactionDate: data.transactionDate
                      ? data.transactionDate
                      : "N/A",
                    date: data.createdAt,
                    open: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setViewDetail(true);
                          setTransactionDetail(data);
                        }}
                      />
                    ),
                    delete: user?.userType !== "PARTNER" && (
                      <MdDelete
                        key={index}
                        width={30}
                        onClick={() => {
                          setUserId(data.id);
                          setShowDeleteModal(true);
                        }}
                      />
                    ),
                  };
                })
              : program === 2
              ? transactions?.map((data, index) => {
                  return {
                    aggregator: data.aggregator,
                    collector: data.collector,
                    // price: "NGN " + toMoney(data.price),
                    quantity: toMoneyDecimal(data.quantity),
                    amount: "NGN " + toMoney(data.amount),
                    state: data.state,
                    transactionDate: data.transactionDate
                      ? data.transactionDate
                      : "N/A",
                    date: data.createdAt,
                    open: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setViewDetail(true);
                          setTransactionDetail(data);
                        }}
                      />
                    ),
                    delete: user?.userType !== "PARTNER" && (
                      <MdDelete
                        key={index}
                        width={30}
                        onClick={() => {
                          setUserId(data.id);
                          setShowDeleteModal(true);
                        }}
                      />
                    ),
                  };
                })
              : transactions?.map((data, index) => {
                  return {
                    collector: data.collector,
                    price: "NGN " + toMoney(data.price),
                    quantity: toMoneyDecimal(data.quantity),
                    amount: "NGN " + toMoneyDecimal(data.amount),
                    state: data.state,
                    transactionDate: data.transactionDate
                      ? data.transactionDate
                      : "N/A",
                    date: data.createdAt,
                    open: (
                      <MdOutlineRemoveRedEye
                        key={index}
                        onClick={() => {
                          setViewDetail(true);
                          setTransactionDetail(data);
                        }}
                      />
                    ),
                    delete: user?.userType !== "PARTNER" && (
                      <MdDelete
                        key={index}
                        width={30}
                        onClick={() => {
                          setUserId(data.id);
                          setShowDeleteModal(true);
                        }}
                      />
                    ),
                  };
                })
          }
        />
      )}

      <PaginationPane
        currentPage={page > 1 ? page : 1}
        totalPages={totalPages || 1}
        nextPage={() => nextPage()}
        prevPage={() => prevPage()}
      />
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <TransactionModal
            closeModal={() => setShowModal(false)}
            aggregatorList={aggregatorList}
            collectorList={collectorList}
            fetchTransactions={fetchTransactions}
            isAggregator={isAggregator}
            selectOptDefault={{ label: user.name, value: user?.aggregatorId }}
          />{" "}
        </Modal>
      )}
      {editDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditDetail(false)}
        >
          <AggregatorModal
            closeModal={() => setEditDetail(false)}
            requestType={"edit"}
            fetchTransactions={fetchTransactions}
            aggregatorList={aggregatorList}
            collectorList={collectorList}
            isAggregator={isAggregator}
            selectOptDefault={{ label: user.name, value: user?.aggregatorId }}
          />
        </Modal>
      )}
      {viewDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewDetail(false)}
        >
          <ViewDetail
            detail={
              user.userType !== "AGGREGATOR"
                ? {
                    aggregator: transactionDetail?.aggregator
                      ? transactionDetail?.aggregator
                      : "N/A",
                    collector: transactionDetail?.collector
                      ? transactionDetail?.collector
                      : "N/A",
                    state: transactionDetail?.state
                      ? transactionDetail?.state
                      : "N/A",
                    "Type Of Plastic": transactionDetail?.typeOfPlastic
                      ? transactionDetail?.typeOfPlastic
                      : "N/A",
                    "Source Of Plastic": transactionDetail?.sourceOfPlastic
                      ? transactionDetail?.sourceOfPlastic
                      : "N/A",
                    "quantity(Kg)": transactionDetail?.quantity
                      ? toMoney(transactionDetail?.quantity)
                      : 0,
                    "price(NGN)": transactionDetail?.price
                      ? toMoney(transactionDetail?.price)
                      : 0,
                    "amount(NGN)": transactionDetail?.amount
                      ? toMoney(transactionDetail?.amount)
                      : 0,
                  }
                : {
                    collector: transactionDetail?.collector
                      ? transactionDetail?.collector
                      : "N/A",
                    state: transactionDetail?.state
                      ? transactionDetail?.state
                      : "N/A",
                    "Type Of Plastic": transactionDetail?.typeOfPlastic
                      ? transactionDetail?.typeOfPlastic
                      : "N/A",
                    "Source Of Plastic": transactionDetail?.sourceOfPlastic
                      ? transactionDetail?.sourceOfPlastic
                      : "N/A",
                    "quantity(Kg)": transactionDetail?.quantity
                      ? toMoney(transactionDetail?.quantity)
                      : 0,
                    "price(NGN)": transactionDetail?.price
                      ? toMoney(transactionDetail?.price)
                      : 0,
                    "amount(NGN)": transactionDetail?.amount
                      ? toMoney(transactionDetail?.amount)
                      : 0,
                  }
            }
            closeModal={() => setViewDetail(false)}
            title={"Transaction Details"}
            subtitle={"Transaction details below"}
            dateCreated={"14 January 2024"}
            editbutton={true}
            createdAt={transactionDetail.createdAt}
          />
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowDeleteModal(false)}
        >
          <DeleteModal
            closeModal={() => setShowDeleteModal(false)}
            handleDelete={() => handleDelete(userId)}
          />
        </Modal>
      )}
    </div>
  );
};

export default Collection;
