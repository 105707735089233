import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import {
  createRecycler,
  gatAllRecycler,
  getRecyclerDetail,
  updateRecycler,
  getAllRecyclerList,
  downloadRecycler,
} from "../ds/recycler";
import { downloadCsvFile } from "../utils/utils";
const useRecycler = () => {
  const [loading, setLoading] = useState();
  const [downloadData, setDownloadData] = useState("");

  const gatAllRecyclers = async (
    page = 1,
    size = 10,
    query,
    selectedState,
    program,
    startDate,
    endDate
  ) => {
    setLoading(true);
    const res = await gatAllRecycler({
      page,
      size,
      name: query || "",
      state: selectedState || "",
      program,
      startDate: startDate || "",
      endDate: endDate || "",
    });
    setLoading(false);
    return JSON.parse(res);
  };

  const getSingleRecycler = async (recyclerId) => {
    setLoading(true);
    const res = await getRecyclerDetail({ id: recyclerId });
    setLoading(false);
    return res;
  };

  const createNewRecycler = async (data) => {
    const res = await createRecycler(data);
    return res;
  };
  const updateRecyclerDetail = async (data) => {
    const res = await updateRecycler(data);
    return res;
  };

  const gatAllRecyclerLists = useCallback(async (program) => {
    setLoading(true);
    const res = await getAllRecyclerList(program);
    // console.log({ res }, "hook");
    return JSON.parse(res);
  }, []);

  useEffect(() => {
    gatAllRecyclerLists();
  }, []);

  const recyclerDownload = async ({
    query,
    state,
    program,
    startDate,
    endDate,
  }) => {
    toast.success("download...");
    const res = await downloadRecycler({
      nameOrEmailOrPhoneNumber: query || "",
      state: state || "",
      program: program,
      startDate: startDate || "",
      endDate: endDate || "",
    });
    return downloadCsvFile(res, "recycler.csv", setDownloadData, toast);
  };

  return {
    loading,
    gatAllRecyclers,
    getSingleRecycler,
    createNewRecycler,
    updateRecyclerDetail,
    gatAllRecyclerLists,
    recyclerDownload,
  };
};

export default useRecycler;
