import React, { useEffect, useState } from "react";
import useProgram from "../../hooks/useProgram";
import { useDispatch, useSelector } from "react-redux";
import { setProgram } from "../../feature/auth";
import EciLogo from "../../assets/images/gbf_logo.svg";
import NpsapLogo from "../../assets/images/npsap_logo.jpeg";
import { HiMenuAlt2 } from "react-icons/hi";

const Header = ({ setShowmobileNav }) => {
  const dispatch = useDispatch();
  const { program, user } = useSelector((state) => state.auth);
  const { programs } = useProgram();
  console.log({ user });

  const users = ["PARTNER", "ADMIN", "AGGREGATOR", "BA_FO", "FIELD_OFFICER"];

  return (
    <div className="w-full flex flex-col lg:flex-row-reverse p-4 border-b border-gray-300">
      <div className="flex items-center justify-start gap-24 mb-2">
        <HiMenuAlt2
          style={{ width: "96px", height: "40px" }}
          className="lg:hidden"
          width={96}
          height={40}
          onClick={() => setShowmobileNav((prev) => !prev)}
        />
        <div className="h-[60px] w-[60px] rounded-full">
          <img
            src={program === 1 ? EciLogo : NpsapLogo}
            alt="profile"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
      </div>
      <div className="w-full">
        {!users.includes(user.userType) ? (
          <select
            defaultValue={program}
            className="w-full lg:w-[231px] bg-black text-white h-[44px] rounded-md p-2 mb-2"
            onChange={(e) => dispatch(setProgram(parseInt(e.target.value)))}
          >
            {programs?.map((data, index) => {
              return (
                <option
                  key={index}
                  className="text-white"
                  id="program"
                  value={data.id}
                >
                  {data.name}
                </option>
              );
            })}
          </select>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
