import * as yup from "yup";
export const createCollectorSchema = yup.object({
  email: yup.string().email("Invalid email format"),
  firstName: yup.string().trim().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  location: yup.string().trim().required("Location is required"),
  state: yup.string().trim().required("State is required"),
  gender: yup.string().required("Gender is required"),
  dateOfBirth: yup.string().required("Date of birth is required"),
  aggregatorId: yup.string().required("aggregator is required"),
  disabilityStatus: yup.string().required("Disability is required"),
  baselineIncomeMonthly: yup.number("Enter a valid number"),
  ageRange: yup.string().required("Age range is required"),
  enrollmentDate: yup.string().required("Enrollment date is required"),
  existingCollector: yup.string(),
  institution: yup.string(),
});
export const createCollectorTwoSchema = yup.object({
  email: yup.string().email("Invalid email format"),
  firstName: yup.string().trim().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  location: yup.string().trim().required("Location is required"),
  state: yup.string().trim().required("State is required"),
  gender: yup.string().required("Gender is required"),
  aggregatorId: yup.string(),
  disabilityStatus: yup.string().required("Disability is required"),
  baselineIncomeMonthly: yup.number("Enter a valid number"),
  ageRange: yup.string().required("Age range is required"),
  enrollmentDate: yup.string().required("Enrollment date is required"),
  existingCollector: yup.string(),
  institution: yup.string(),
});
export const createCollectorSchemaTwo = yup.object({
  email: yup.string().email("Invalid email format"),
  firstName: yup.string().trim().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  location: yup.string().trim().required("Location is required"),
  state: yup.string().trim().required("State is required"),
  gender: yup.string().required("Gender is required"),
  dateOfBirth: yup.string().required("Date of birth is required"),
  aggregatorId: yup.string(),
  disabilityStatus: yup.string().required("Disability is required"),
  baselineIncomeMonthly: yup.number("Enter a valid number"),
  ageRange: yup.string().required("Age range is required"),
  enrollmentDate: yup.string().required("Enrollment date is required"),
  existingCollector: yup.string(),
  institution: yup.string(),
});
