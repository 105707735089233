import fetcher from "../api/fetacher";

export const getAllTransactions = async ({
  page,
  size,
  nameOrEmailOrPhoneNumber,
  state,
  aggregatorId,
  collectorId,
  startDate,
  endDate,
  program,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
      programId: program,
    };
    if (nameOrEmailOrPhoneNumber) {
      params.nameOrEmailOrPhoneNumber = nameOrEmailOrPhoneNumber;
    }
    if (state) {
      params.state = state;
    }
    if (aggregatorId) {
      params.aggregatorId = aggregatorId;
    }

    if (collectorId) {
      params.collectorId = collectorId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/transaction",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const createTransaction = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/transaction", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const deleteTransaction = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/transaction/${data}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadTransaction = async ({
  collectorId,
  aggregatorId,
  state,
  startDate,
  endDate,
  program,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    console.log(
      {
        collectorId,
        aggregatorId,
        state,
        startDate,
        endDate,
        program,
      },
      "collection"
    );
    const params = {
      programId: program,
    };

    if (state) {
      params.state = state;
    }
    if (aggregatorId) {
      params.aggregatorId = aggregatorId;
    }

    if (collectorId) {
      params.collectorId = collectorId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/transaction/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Accept: "text/csv",
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
