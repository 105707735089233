import React, { useEffect, useState } from "react";
import { login } from "../ds/user";
import fetcher from "../api/fetacher";
import { getProgram } from "../ds/resource";

const useProgram = () => {
  const [loading, setLoading] = useState();
  const [programs, setPrograms] = useState([]);
  const [programvalue, setProgramValue] = useState(1);
  useEffect(() => {
    const getPrograms = async () => {
      const res = await getProgram();
      setPrograms(res.data);
    };
    getPrograms();
  }, []);
  return {
    loading,
    programvalue,
    setProgramValue,
    programs,
  };
};

export default useProgram;
