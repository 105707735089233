import fetcher from "../api/fetacher";

export const getState = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {};
    if (program) {
      params.programId = program;
    }
    const res = await fetcher(
      "/state",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getLga = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher("/lga", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getTypeOfPlastic = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    const res = await fetcher(
      "/typeOfPlastic",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getSourceOfPlastic = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    const res = await fetcher(
      "/sourceOfPlastic",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getTopAggregators = async (year, program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    const res = await fetcher(
      "/dashboard/topFiveAggregator",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getTopCollectors = async (year, program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    const res = await fetcher(
      "/dashboard/topFiveCollectorReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getTopState = async (year, program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    const res = await fetcher(
      "/dashboard/topFiveStateReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getTopLocation = async (year, program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    const res = await fetcher(
      "/dashboard/topFiveLocationReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getProgram = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/program", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const getParticipantReport = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    const res = await fetcher(
      "/dashboard/participantReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getPerformanceReport = async (
  program,
  sourceOfPlasticId,
  typeOfPlasticId,
  aggregatorId
) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    if (sourceOfPlasticId) {
      params.sourceOfPlasticId = sourceOfPlasticId;
    }
    if (typeOfPlasticId) {
      params.typeOfPlasticId = typeOfPlasticId;
    }
    if (aggregatorId) {
      params.aggregatorId = aggregatorId;
    }
    const res = await fetcher(
      "/dashboard/performanceReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getPlasticCollectionReport = async (
  year,
  calendarType,
  program
) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    if (calendarType === "Quarterly") {
      params.calendarType = calendarType;
    }
    const res = await fetcher(
      "/dashboard/plasticCollectionReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getPlasticProcessedReport = async (
  year,
  calendarType,
  program
) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    if (calendarType === "Quarterly") {
      params.calendarType = calendarType;
    }
    const res = await fetcher(
      "/dashboard/plasticProcessedReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getStateCollectionReport = async (year, program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    const res = await fetcher(
      "/dashboard/stateCollectionReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};
export const getLocationCollectionReport = async (year, program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      year,
      programId: program,
    };
    const res = await fetcher(
      "/dashboard/locationCollectionReport",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getLgaByState = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const res = await fetcher(`/state/${id}/localgovernment`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
