import React, { useCallback, useEffect, useRef, useState } from "react";
import TopCard from "../../components/card/TopCard";
import CustomTable from "../../components/table/CustomTable";
import { GoPlus } from "react-icons/go";
import DataCard from "../../components/card/DataCard";
import InputSelect from "../../components/input/InputSelect";
import InputSearch from "../../components/input/InputSearch";
import Group from "../../assets/images/group.png";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/Modal";
import { UserModal } from "../../components/modal/UserModal";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import ViewDetail from "../../components/modal/ViewDetail";
import PaginationPane from "../../components/table/PaginationPane";
import { EditUserModal } from "../../components/editmodal/UserModal";
import useUser from "../../hooks/useUser";
import useResource from "../../hooks/useResource";
import { useSelector } from "react-redux";

const headers = ["User", "Email Address", "Organization", "Type"];

const User = () => {
  const { paReport } = useResource();
  const wrapperRef = useRef(null);
  const [query, setQuery] = useState("");
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [viewDetail, setViewDetail] = useOutsideClick(wrapperRef);
  const [editDetail, setEditDetail] = useOutsideClick(wrapperRef);
  const [userDetail, setUserDetail] = useState({});

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const { program, user } = useSelector((state) => state.auth);
  const { gatAllUsers, userDownload } = useUser(program);

  const limit = 10;

  const getUsers = useCallback(
    async (newQuery) => {
      const queryData = query || newQuery;
      const res = await gatAllUsers(queryData, program);
      setTotalPages(res.data?.totalPages);
      setTotalElements(res?.data?.totalElements);
      setUsers(res.data?.content);
    },
    [program, query]
  );
  useEffect(() => {
    getUsers();
  }, [page, program, getUsers]);

  const nextPage = () => {
    setPage((prev) => (prev >= totalPages ? 1 : prev + 1));
    getUsers(query);
  };
  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
    getUsers(query);
  };

  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"User"}
          subtitle={"List of users on the platform."}
          buttonTitle={"New User"}
          Icon={GoPlus}
          setShowModal={() => setShowModal(true)}
          userType={user?.userType}
          showCreateButtuon={user?.userType === "FIELD_OFFICER" ? false : true}
          downloadData={() => userDownload({ program })}
        />
      </div>
      <div className="mb-10">
        <DataCard
          image={Group}
          title={"Total User"}
          subtitle={totalElements || 0}
          css={"border border-gray-300"}
        />
      </div>
      <div className="mb-10 flex justify-between">
        <div className="flex gap-2"></div>
        <div className="flex gap-2">
          <InputSearch
            placeholder={"search"}
            inputValue={query}
            setInputValue={setQuery}
          />
          <button
            className="flex justify-center items-center h-[44px] w-[101px] border border-gray-300 gap-2 rounded-md"
            onClick={() => getUsers(query)}
          >
            Apply
          </button>
          <button className="flex justify-center items-center h-[44px] w-[60px] border border-gray-300 gap-2 rounded-md">
            refresh
          </button>
        </div>
      </div>
      {users?.length > 0 ? (
        <CustomTable
          headers={headers}
          rows={users.map((data, index) => {
            return {
              name: (
                <div className="flex flex-col">
                  <p>{data.name}</p>
                </div>
              ),
              email: data.email,
              organizationName: data.organization,
              Type: data.userType,
              edit: (
                <MdOutlineRemoveRedEye
                  key={index}
                  onClick={() => {
                    setUserDetail(data);
                    setViewDetail(true);
                  }}
                />
              ),
              open: user?.userType !== "PARTNER" && (
                <FiEdit
                  key={index}
                  onClick={() => {
                    setUserDetail(data);
                    setEditDetail(true);
                  }}
                />
              ),
            };
          })}
        />
      ) : (
        <div className="flex justify-center">
          <p className="text-center">Loading...</p>
        </div>
      )}
      <PaginationPane
        currentPage={page > 1 ? page : 1}
        totalPages={totalPages || 1}
        nextPage={() => nextPage()}
        prevPage={() => prevPage()}
      />
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <UserModal
            closeModal={() => setShowModal(false)}
            getUsers={getUsers}
          />
        </Modal>
      )}
      {editDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditDetail(false)}
        >
          <UserModal
            closeModal={() => setEditDetail(false)}
            requestType={"edit"}
            getUsers={getUsers}
            userData={userDetail}
          />
        </Modal>
      )}
      {viewDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewDetail(false)}
        >
          <ViewDetail
            detail={{
              email: userDetail.email ? userDetail.email : "N/A",
              "First Name": userDetail.name.split(" ")[0] || "N/A",
              "Last Name": userDetail.name.split(" ")[1] || "N/A",
              organization: userDetail.organization
                ? userDetail.organization
                : "N/A",
              Type: userDetail.userType ? userDetail.userType : "N/A",
            }}
            closeModal={() => setViewDetail(false)}
            title={"User Details"}
            subtitle={"User details below"}
            dateCreated={"14 January 2024"}
            editbutton={true}
            createdAt={userDetail.createdAt}
          />
        </Modal>
      )}
    </div>
  );
};

export default User;
