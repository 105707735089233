import api from "../api/axios";
import fetcher from "../api/fetacher";

export const gatAllRecycler = async ({
  page,
  size,
  name,
  state,
  program,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
      programId: program,
      startDate,
      endDate,
    };
    if (name) {
      params.name = name;
    }
    if (!!state && state !== "Select State") {
      params.state = state;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/recycler",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getRecyclerDetail = async ({ id }, program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      id,
      programId: program,
    };
    const res = await fetcher(
      `/recycler/${params.id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const createRecycler = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/recycler", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const updateRecycler = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/recycler/update", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllRecyclerList = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    const res = await fetcher(
      "/recycler/list",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadRecycler = async ({
  program,
  state,
  nameOrEmailOrPhoneNumber,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };

    if (state) {
      params.state = state;
    }
    if (nameOrEmailOrPhoneNumber) {
      params.nameOrEmailOrPhoneNumber = nameOrEmailOrPhoneNumber;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/recycler/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Accept: "text/csv",
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
