import React from "react";

const Osun = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M79.2905 345.908L82.9053 327.639L82.6108 326.236L81.5728 325.034L80.7262 323.437L79.5736 322.028L78.1105 321.177L77.0186 320.024L77.0896 318.549L77.9798 317.281L78.4552 315.857L78.7279 314.37L78.9136 311.076L79.782 308.776L83.3476 309.676L86.0561 307.822L86.0011 306.689L86.4548 306.253L87.0552 305.838L88.0221 304.517L89.1415 303.398L90.7903 304.005L92.2487 305.239L94.1873 308.218L96.3446 308.628L97.4365 307.566L98.966 306.511L99.2832 306.207L99.6052 305.75L100.01 305.386L100.124 305.545L101.031 304.868L102.024 304.281L102.969 303.93L104.138 303.743L104.41 303.432L104.858 302.797L104.967 301.069L104.574 299.35L107.976 299.178L111.427 299.524L114.386 298.915L115.774 298.361L117.227 298.065L117.319 298.408L117.685 298.016L120.64 297.782L122.732 296.954L124.933 297.006L126.058 298.747L127.631 299.745L130.661 299.356L131.443 299.528L130.241 303.639L128.614 305.027L126.593 305.941L124.151 309.009L123.719 315.074L123.151 317.299L123.424 319.916L127.601 330.643L127.563 332.663L124.98 333.338L122.676 334.466L121.742 336.829L121.305 339.234L121.322 343.026L121.169 344.38L121.289 345.536L121.147 346.627L120.596 347.019L119.193 346.913L118.537 346.625L115.878 344.975L113.902 344.187L111.81 344.746L109.565 346.449L108.129 348.783L107.735 350.083L106.906 351.16L104.863 352.172L104.038 351.209L101.903 351.399L100.947 351.655L100.068 352.028L98.9272 351.972L97.8191 351.658L95.962 352.635L94.4543 352.565L94.1759 351.817L94.0338 351.014L93.5367 349.713L92.8927 348.459L91.1501 347.053L88.6983 347.152L86.6991 348.37L84.5635 348.61L84.1326 346.328L83.0294 344.206L79.2905 345.908Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Osun;
