import React, { PureComponent, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const BarChart = ({
  data,
  dataKey,
  outerRadius,
  innerRadius,
  height,
  width,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const onPieEnter = (data, index) => {
  //   setActiveIndex(index);
  // };
  const onPieEnter = (e) => {
    if (e) {
      let toolTipWrapper = document.getElementsByClassName(
        "recharts-tooltip-wrapper"
      )[0];
      toolTipWrapper.style.transition = "transform 400ms ease 0s";
      toolTipWrapper.style.transform =
        "translate(" + (e.chartX + 500) + "px, " + (e.chartY + 100) + "px)";
    }
  };

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function renderCustomToolTip({ active, label, payload, x, y }) {
    if (active) {
      return (
        <div
          className={`bg-white absolute top-0  left-0 border border-gray-300 min-w-max p-2 h-max flex flex-col gap-2 `}
        >
          <p> Location: {payload[0]?.payload.location} </p>
          <p>
            {" "}
            Plastic: {numberWithCommas(
              parseInt(payload[0]?.payload.plastic)
            )}{" "}
          </p>
        </div>
      );
    }
  }
  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          activeIndex={activeIndex}
          // activeShape={renderCustomToolTip}
          // label={renderCustomizedLabel}
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          fill="#8884d8"
          dataKey={dataKey}
          onMouseEnter={onPieEnter}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip
          content={renderCustomToolTip}
          active={true}
          // viewBox={{ x: 500, y: 500, width: 500, height: 500 }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
