import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { gatAllUser, createUser, downloadUser } from "../ds/user";
import { downloadCsvFile } from "../utils/utils";
import toast from "react-hot-toast";

const useUser = () => {
  const [loading, setLoading] = useState();
  const [downloadData, setDownloadData] = useState("");
  const dispatch = useDispatch();
  const gatAllUsers = async (query, program) => {
    setLoading(true);
    const res = await gatAllUser({
      nameOrEmail: query || "",
      program,
    });
    return JSON.parse(res);
  };
  const createNewUser = async (data) => {
    return await createUser(data);
  };

  const userDownload = async ({ nameOrEmail, program }) => {
    const res = await downloadUser({
      nameOrEmail: nameOrEmail || "",
      program,
    });
    return downloadCsvFile(res, "user.csv", setDownloadData, toast);
  };

  return {
    loading,
    gatAllUsers,
    createNewUser,
    userDownload,
  };
};

export default useUser;
