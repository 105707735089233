import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { GoGear } from "react-icons/go";
import { Switch } from "@mui/material";

const SettingsModal = ({ closeModal }) => {
  return (
    <div className="flex flex-col gap-y-2 w-[640px] bg-white p-4 h-max h-[524px]">
      <div className="flex justify-between">
        <GoGear style={{ width: 26, height: 26 }} />
        <IoCloseOutline
          style={{ width: 26, height: 26, cursor: "pointer" }}
          onClick={() => closeModal()}
        />
      </div>
      <div className="flex justify-between items-center mb-3">
        <div>
          <h1 className="capitalize font-bold">Settings</h1>
        </div>
      </div>
      <div className="flex flex-col">
        <div>
          <Switch color="success" />
          <label>Bottles</label>
        </div>
        <div>
          <Switch color="success" />
          <label>Kilogram(kg)</label>
        </div>
        <div>
          <Switch color="success" />
          <label>Metric Tonnes</label>
        </div>
      </div>
      <div className="flex gap-2 justify-center">
        <button
          className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          //   onClick={() => handleSubmit()}
          className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default SettingsModal;
