import React, { useEffect, useState } from "react";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import BottleWater from "../../assets/images/bottle-of-water.png";
import DashCard from "../../components/card/DashCard";
import useNav from "../../hooks/useNav";
import InputSelect from "../../components/input/InputSelect";
import DataCard from "../../components/card/DataCard";
import Group from "../../assets/images/group.png";
import Character from "../../assets/images/Characters.png";
import rafiki from "../../assets/images/rafiki.png";
import Character2 from "../../assets/images/Character.png";
import LineCharts from "../../components/charts/LineChart";
import Granular from "../../assets/images/granular.png";
import Pana from "../../assets/images/pana.png";
import { AiOutlineRight } from "react-icons/ai";
import BarChart from "../../components/charts/BarChart";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useResource from "../../hooks/useResource";
import { useSelector } from "react-redux";
import InputSelect2 from "../../components/input/InputSelect2";
import SearchableDropdown from "../../components/input/SearchableDropdown";

const pieColors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
];
const Dashboard = () => {
  const { program } = useSelector((state) => state.auth);

  const {
    typeOfPlastic,
    sourceOfPlastic,
    paReport,
    peReport,
    setProcessedYear,
    processedReport,
    setCollectionYear,
    collectionReport,
    topFiveCollector,
    setTopFiveColYear,
    locationCollectionReport,
    setLocColYear,
    setCollectionReportTimeline,
    collectionReportTimeline,
    setProcessedReportTimeline,
    processedReportTimeline,
    setSourceOfPlasticId,
    setTypeOfPlasticId,
  } = useResource(program);
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.userType === "SUPER_ADMIN") {
      navigate("/admin", { replace: true });
    }
  }, []);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const peMetrics = [
    {
      title: "Total Collections",
      subtitle: numberWithCommas(parseInt(peReport?.noOfPlasticCollected) || 0),
      figure: `${numberWithCommas(
        parseInt(peReport?.noOfPlasticCollected * 40) || 0
      )} Bottles`,
      image: BottleWater,
      unit: "Kg",
      css: "bg-[#DCFAE6]",
    },
    {
      title: "Total Offtake",
      subtitle: numberWithCommas(parseInt(peReport?.noOfPlasticProcessed) || 0),
      figure: `${
        numberWithCommas(parseInt(peReport?.noOfPlasticProcessed) * 40) || 0
      } Bottles`,
      image: BottleWater,
      unit: "Kg",
      css: "bg-[#F4EBFF]",
    },
    {
      title: "Transaction value",
      subtitle: `NGN ${numberWithCommas(
        parseInt(peReport?.transactionValue) || 0
      )}`,
      figure: `${numberWithCommas(
        parseInt(peReport?.noOfPlasticCollected) || 0
      )} kg`,
      image: Granular,
      css: "bg-[#FFE6D5]",
    },
    {
      title: "Average Income per collector",
      caption: "Across program duration",
      subtitle: `NGN ${numberWithCommas(
        parseInt(peReport?.averageCollector) || 0
      )}`,
      image: Pana,
      css: "bg-[#E0F2FE]",
    },
  ];
  const data2 = [
    {
      title: "Total Collectors",
      subtitle: paReport?.noOfCollector || 0,
      image: rafiki,
      css: "bg-[#FFFAEB]",
    },
    {
      title: "Total Recyclers",
      subtitle: paReport?.noOfRecycler || 0,
      image: Character2,
      css: "bg-white border border-gray-300",
    },
  ];

  return (
    <div className="p-4 h-max">
      <div className="flex flex-col gap-2 mb-2">
        <h1 className="font-bold text-xl">Dashboard</h1>
        <p className="pr-2">Gain insights from the metrics below.</p>
      </div>
      <div className="flex flex-col items-center border border-gray-200 p-4 mb-10 rounded-md">
        <div className="flex flex-col md:w-full xl:flex-row mb-3">
          <p className="font-bold xl:w-[200px]">Performance Metrics</p>
          <div className="flex md:w-full xl:justify-end gap-2">
            <SearchableDropdown
              options={
                [
                  { label: "All plastics", value: 0 },
                  ...typeOfPlastic?.map((data) => {
                    return {
                      label: data.name,
                      value: data.id,
                    };
                  }),
                ] || []
              }
              placeholder={"Type Of Plastic"}
              // selectedOption={selectedState}
              defaultValue={null}
              handleChange={(e) => {
                setTypeOfPlasticId(e);
              }}
              containerClass={"md:w-full xl:w-[185px]"}
              css={"w-[176px] md:w-full xl:w-[185px]"}
            />
            <SearchableDropdown
              containerClass={"md:w-full xl:w-[185px]"}
              css={"w-[175px] md:w-full xl:w-[185px]"}
              placeholder={"Source Of Plastic"}
              options={[
                { label: "All sources", value: 0 },
                ...sourceOfPlastic?.map((data) => {
                  return {
                    label: data.name,
                    value: data.id,
                  };
                }),
              ]}
              handleChange={(e) => {
                setSourceOfPlasticId(e);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col xl:flex-row gap-2 w-full">
          <div className="flex flex-wrap gap-4 basis-[80%]">
            {peMetrics?.map(
              (
                { title, subtitle, figure, image, css, unit, caption },
                index
              ) => {
                return (
                  <>
                    <DashCard
                      key={index}
                      title={title}
                      subtitle={subtitle}
                      figure={figure}
                      caption={caption}
                      image={image}
                      unit={unit}
                      css={`
                        ${css} w-full lg:basis-[48.5%] xl:basis-[48.5%]
                      `}
                    />
                  </>
                );
              }
            )}
          </div>
          <div className="flex justify-center gap-4 lg:gap-8 bg-black rounded-md h-max mt-6 p-4 xl:w-[278px] xl:flex-col xl:mt-0 basis-[20%]">
            <div className="">
              <p className="text-white text-[12px] mb-2">Carbon Credits</p>
              <div className="flex flex-col items-center justify-center bg-slate-100 rounded-md w-[158px] h-[120px] md:w-[300px] lg:basis-[370px] xl:w-[248px] lg:h-[150px]">
                <p className="text-sm">Total Credit</p>
                <div className="">
                  <div className="flex items-center gap-2 text-xl">
                    <p className="font-bold text-[18px]">
                      {numberWithCommas(parseInt(peReport?.carbonCredit) || 0)}
                    </p>
                    <p className="text-green-400 text-sm">Kg C02e</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <p className="text-white text-[12px] mb-2">Plastokens</p>
              <div className="flex flex-col items-center justify-center text-white rounded-md bg-new-plas bg-cover bg-center bg-no-repeat w-[158px] h-[120px] md:w-[300px] lg:w-[370px] xl:w-[248px] lg:h-[150px]">
                <p className="text-sm">Total Tokens</p>
                <div className="">
                  <div className="flex items-center gap-2 text-xl">
                    <p className="font-bold text-[18px]">
                      {numberWithCommas(
                        parseInt(peReport?.noOfPlasticCollected) || 0
                      )}
                    </p>
                    <p className="text-white text-sm">tokens</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col border border-gray-200 gap-4 p-4 mb-10 rounded-md">
        <div className="flex justify-between mb-3">
          <p className="font-bold">Participants Metrics</p>
        </div>
        <div className="flex flex-wrap w-full gap-4">
          {data2?.map(({ title, subtitle, figure, image, css }, index) => {
            return (
              <>
                <DataCard
                  key={index}
                  title={title}
                  subtitle={subtitle}
                  figure={figure}
                  image={image}
                  css={`
                    ${css} h-max w-full lg:w-[48%] xl:basis-[49%]
                  `}
                  icon={true}
                  imageHeight={"h-[96px] w-[68px]"}
                />
              </>
            );
          })}
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md mb-10 p-2 relative">
        <div className="flex flex-col xl:flex-row xl:justify-between border-b border-gray-300 mb-16 h-max p-4">
          <div className="mb-2">
            <p className="font-bold">Collector Performance metric</p>
            <p className="text-sm">Track the rate of collection over time.</p>
          </div>
          <div className="flex rounded-md w-max">
            {[
              { name: "Project Overview", year: 1 },
              { name: "2022", year: 2022 },
              { name: "2023", year: 2023 },
              { name: "2024", year: 2024 },
            ]?.map((data, index) => {
              return (
                <button
                  key={index}
                  className={`w-max border border-gray-300 p-2 text-sm ${
                    index === 2 ? "rounded-br rounded-tr" : ""
                  } ${
                    index === 0
                      ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                      : ""
                  }`}
                  onClick={() => setCollectionYear(data.year)}
                >
                  {data.name}
                </button>
              );
            })}
          </div>
          <div className="flex xl:top-28 self-end absolute top-36 right-5">
            <InputSelect
              css={"w-[185px]"}
              placeholder={"Calendar Type"}
              value={collectionReportTimeline}
              options={["Monthly", "Quarterly"]}
              handleChange={(e) => setCollectionReportTimeline(e.target.value)}
            />
          </div>
        </div>
        <div className="h-[500px]">
          <LineCharts
            fill="#8884d8"
            datakeyX={"date"}
            dataKeyB={"plastics"}
            lineData={collectionReport}
          />
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md mb-10 p-2">
        <div className="flex flex-col border-b border-gray-300 mb-14 h-max p-4">
          <div className="mb-2">
            <p className="font-bold">Collector Performance metric</p>
          </div>
          <div className="flex rounded-md w-max">
            {[
              { name: "Project Overview", year: 1 },
              { name: "2022", year: 2022 },
              { name: "2023", year: 2023 },
              { name: "2024", year: 2024 },
            ]?.map((data, index) => {
              return (
                <button
                  key={index}
                  className={`w-max border border-gray-300 p-2 text-sm ${
                    index === 2 ? "rounded-br rounded-tr" : ""
                  } ${
                    index === 0
                      ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                      : ""
                  }`}
                  onClick={() => {
                    setTopFiveColYear(data.year);
                  }}
                >
                  {data.name}
                </button>
              );
            })}
          </div>
        </div>
        <div className="w-11/12 bg-[#F9F5FF] p-4 rounded-md">
          <h1 className="h-[49px] border-b border-[#EAECF0] mb-5 font-bold">
            Top 5 collectors
          </h1>
          <div className="w-11/12 ">
            {topFiveCollector?.length > 0 ? (
              topFiveCollector
                ?.map((data) => {
                  return {
                    ...data,
                    quantity: numberWithCommas(parseInt(data.quantity)),
                  };
                })
                .map((data, index) => {
                  return (
                    <div
                      className="border-b border-[#EAECF0] h-[60px]"
                      key={index}
                    >
                      <div className="flex justify-between items-center mb-3 h-full">
                        <div className="basis-[55%]">
                          <p className="font-bold text-sm">{data.collector}</p>
                          <p className="text-sm">{data.address}</p>
                        </div>
                        <p className="text-sm basis-[35%]">
                          {data.quantity}kg Collected
                        </p>
                        <p className="basis-[10%]">
                          <AiOutlineRight />
                        </p>
                      </div>
                    </div>
                  );
                })
            ) : (
              <div className="text-center">loading...</div>
            )}
          </div>
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md mb-10 p-2">
        <div className="flex flex-col xl:flex-row xl:justify-between border-b border-gray-300 mb-14 h-max p-4">
          <div className="mb-2">
            <p className="font-bold">State Locations</p>
          </div>
          <div className="flex rounded-md w-max">
            {[
              { name: "Project Overview", year: 1 },
              { name: "2022", year: 2022 },
              { name: "2023", year: 2023 },
              { name: "2024", year: 2024 },
            ]?.map((data, index) => {
              return (
                <button
                  key={index}
                  className={`w-max border border-gray-300 p-2 text-sm ${
                    index === 2 ? "rounded-br rounded-tr" : ""
                  } ${
                    index === 0
                      ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                      : ""
                  }`}
                  onClick={() => {
                    setLocColYear(data.year);
                  }}
                >
                  {data.name}
                </button>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col xl:flex-row xl:justify-between items-center justify-center">
          <div className="w-full h-[500px]">
            <BarChart
              data={locationCollectionReport?.map((data) => {
                return {
                  location: data.location,
                  plastic: parseInt(data.plastic),
                };
              })}
              outerRadius={180}
              innerRadius={50}
              dataKey={"plastic"}
            />
          </div>
          <div className="basis-[50%] flex items-center justify-center p-2 bg-[#F9FAFB] rounded-md">
            <div className="flex flex-wrap gap-16 basis-[90%]">
              {locationCollectionReport
                ?.map((data, index) => {
                  return {
                    ...data,
                    color: pieColors[index],
                  };
                })
                ?.map((data, index) => {
                  return (
                    <div
                      className="flex gap-2 basis-[112px] h-[54px]"
                      key={index}
                    >
                      <div
                        className={`rounded-full w-[12px] h-[12px] mt-2`}
                        style={{ backgroundColor: `${data.color}` }}
                      ></div>
                      <div className="mt-1">
                        <p className="text-sm mb-1">{data.location}</p>
                        <p className="font-bold">
                          {numberWithCommas(parseInt(data.plastic))}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="h-max border border-gray-300 rounded-md p-2 relative">
        <div className="flex flex-col xl:flex-row xl:justify-between border-b border-gray-300 mb-16 h-max p-4">
          <div className="mb-2">
            <p className="font-bold">Plastic processed</p>
            <p className="text-sm">Track the rate of collection over time.</p>
          </div>
          <div className="flex rounded-md w-max">
            {[
              { name: "Project Overview", year: 1 },
              { name: "2022", year: 2022 },
              { name: "2023", year: 2023 },
              { name: "2024", year: 2024 },
            ]?.map((data, index) => {
              return (
                <button
                  key={index}
                  className={`w-max border border-gray-300 p-2 text-sm ${
                    index === 2 ? "rounded-br rounded-tr" : ""
                  } ${
                    index === 0
                      ? "text-sm rounded-tl rounded-bl bg-[#F9FAFB]"
                      : ""
                  }`}
                  onClick={() => setProcessedYear(data.year)}
                >
                  {data.name}
                </button>
              );
            })}
          </div>
          <div className="flex self-end absolute top-36 xl:top-28 right-5">
            <InputSelect
              css={"w-[185px]"}
              placeholder={"Calendar Type"}
              value={processedReportTimeline}
              options={["Monthly", "Quarterly"]}
              handleChange={(e) => setProcessedReportTimeline(e.target.value)}
            />
          </div>
        </div>
        <div className="h-[500px]">
          <LineCharts
            fill="#F79009"
            datakeyX={"date"}
            dataKeyB={"plastics"}
            lineData={processedReport}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
