import { useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const DashGuard = ({ children }) => {
  const { islogin, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  useEffect(() => {
    if (islogin && accessToken) {
      switch (user?.userType) {
        case "SUPER_ADMIN":
          redirect("/admin/dashboard");
          break;
        default:
          redirect("/dashboard");
      }
    }
  }, [accessToken, islogin, navigate, user]);

  return children;
};

export default DashGuard;
