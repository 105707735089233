import * as yup from "yup";
export const createAggregatorSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  address: yup.string().required("Address is required"),
  yearOfIncorporation: yup
    .string()
    .required("Year of incorporation is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  location: yup.string().required("Location is required"),
  latitude: yup.number("Enter a valid number").required("Location is required"),
  longitude: yup
    .number("Enter a valid number")
    .required("Location is required"),
  state: yup.string().required("State is required"),
  rcNumber: yup.string(),
  noOfFemaleEmployee: yup.number("Enter a valid number"),
  noOfMaleEmployee: yup.number("Enter a valid number"),
  gender: yup.string(),
  ageRange: yup.string(),
  contactPersonFirstName: yup
    .string()
    .required("Contact Person First Name is required"),
  contactPersonLastName: yup
    .string()
    .required("Contact Person Last Name is required"),
  enrollmentDate: yup.string().required("Enrollment date is required"),
  avgMonthlyCollectionCap: yup.string(),
  avgMonthlyProcessionCap: yup.string(),
});
