import React from "react";

const AkwaIbom = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M302.379 463.217L302.682 463.469L303.477 465.628L304.131 466.462L303.95 466.778L303.528 467.9L303.451 468.364L303.661 469.084L304.172 469.366L304.77 469.538L305.247 469.924L305.403 471.095L304.59 471.798L303.558 472.387L303.055 473.198L302.332 474.725L301.816 474.962L301.313 475.049L299.013 475.047L296.003 474.725H291.479L289.278 474.419L288.259 474.449L286.5 475.322L285.476 475.442H283.15L274.124 476.438L273.625 476.156L272.954 476.08L272.327 476.279L271.927 476.814L272.494 476.79L272.985 476.839L273.393 476.962L273.733 477.158L272.594 477.441L270.882 477.525L269.334 477.292L268.65 476.627L267.915 476.442L263.237 476.13L263.413 475.919L263.512 475.756L263.658 475.608L263.954 475.443L263.283 475.247L262.664 475.335L262.226 475.302L262.122 474.726L262.303 474.431L262.664 474.206L263.03 473.912L263.236 473.384L263.082 473.137L262.122 471.982L262.278 471.788L262.514 471.296L261.715 470.427L261.896 469.149L262.846 466.802H262.514L261.921 467.219L261.986 466.016L261.528 464.127L260.6 462.728L259.956 461.457L259.579 459.372L258.416 457.378L258.574 456.552L258.89 455.64L258.186 453.198L259.37 451.387L261.085 449.743L261.402 447.492L261.08 445.169L261.303 442.98L262.024 439.844L261.941 438.865L261.379 437.958L261.483 436.932L263.854 436.318L266.535 436.672L268.074 435.719L268.402 434.782L267.856 433.828L266.556 433.463L266.594 432.769L267.911 430.03L267.589 426.578L268.981 425.633L270.996 425.926L273.606 427.677L275.408 430.309L278.385 431.888L281.132 433.93L279.647 435.51L280.51 437.654L281.116 438.582L282.17 439.357L285.272 438.849L286.637 439.19L288.991 440.578L289.869 441.548L289.482 447.699L290.214 450.485L291.628 453L293.523 455.549L299.727 461.684L301.753 463.102L302.37 463.215L302.379 463.217Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default AkwaIbom;
