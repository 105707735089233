import api from "../api/axios";
import fetcher from "../api/fetacher";
import fileFetch from "../api/fileFetcher";

export const gatAllWaybill = async ({
  page,
  size,
  aggregatorId,
  recycId,
  startDate,
  endDate,
  program,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
      programId: program,
    };
    if (aggregatorId !== "All Aggregators") {
      params.aggregatorId = aggregatorId;
    }

    if (recycId !== "All Collectors") {
      params.recyclerId = recycId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }

    const res = await fetcher(
      "/waybill",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const createWaybill = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fileFetch("/waybill", {
      method: "POST",
      body: data,
      headers: {
        // "Content-Length": data.file.length,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const createWaybillAlt = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fileFetch("/waybill/create", {
      method: "POST",
      body: data,
      headers: {
        // "Content-Length": data.file.length,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export const deleteWaybill = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/waybill/${data}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadWaybill = async ({
  recyclerId,
  aggregatorId,
  startDate,
  endDate,
  program,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    console.log(
      { recyclerId, aggregatorId, startDate, endDate, program },
      "waybill"
    );
    const params = {
      programId: program,
    };
    if (aggregatorId) {
      params.aggregatorId = aggregatorId;
    }
    if (recyclerId) {
      params.recyclerId = recyclerId;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/waybill/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
