import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import TopCard from "../../components/card/TopCard";
import CustomTable from "../../components/table/CustomTable";
import { GoPlus } from "react-icons/go";
import DataCard from "../../components/card/DataCard";
import InputSelect from "../../components/input/InputSelect";
import InputSearch from "../../components/input/InputSearch";
import rafiki from "../../assets/images/rafiki.png";
import useOutsideClick from "../../hooks/useOutsideClick";
import Modal from "../../components/Modal";
import { RecyclerModal } from "../../components/modal/RecyclerModal";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import ViewDetail from "../../components/modal/ViewDetail";
import PaginationPane from "../../components/table/PaginationPane";
import { getLga, getState } from "../../ds/resource";
import useRecycler from "../../hooks/useRecycler";
import useResource from "../../hooks/useResource";
import { useSelector } from "react-redux";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const headers = [
  "Recyclers",
  "Email Address",
  "Address",
  "State",
  "Enrollment Date",
  "Entry Date",
];

const Recycler = () => {
  const wrapperRef = useRef(null);
  const { paReport } = useResource();
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [viewDetail, setViewDetail] = useOutsideClick(wrapperRef);
  const [editDetail, setEditDetail] = useOutsideClick(wrapperRef);

  const [recyclers, setRecyclers] = useState([]);
  const [recyclerId, setRecyclerId] = useState(1);
  const [recyclerDetail, setRecyclerDetail] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [states, setStates] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [lga, setLga] = useState([]);
  const { program, user } = useSelector((state) => state.auth);

  const limit = 10;

  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);

  const { loading, gatAllRecyclers, recyclerDownload } = useRecycler();

  const getRecyclers = useCallback(
    async (newQuery, selState, formatStaDate, formatEdDate) => {
      const queryData = query || newQuery;
      const newState = selectedState?.value || selState?.value;
      const newEdDate = formatEndDate || formatEdDate;
      const newStaDate = formatStartDate || formatStaDate;

      const res = await gatAllRecyclers(
        page,
        limit,
        queryData,
        newState,
        program,
        newStaDate,
        newEdDate
      );
      setTotalPages(res.data?.totalPages);
      setTotalElements(res?.data?.totalElements);
      setRecyclers(res.data?.content);
    },
    [page, program, query]
  );
  useEffect(() => {
    getRecyclers();
  }, [page, program, query, getRecyclers]);

  useEffect(() => {
    const getAllState = async () => {
      const res = await getState(program === 1 ? program : "");
      const list = JSON.parse(res).data?.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });

      setStates([...list]);
    };
    getAllState();
  }, []);

  const reset = () => {
    setSelectedState(null);
    setQuery("");
    setStartDate("");
    setEndDate("");
    getRecyclers();
  };

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    {
      start
        ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
        : setFormatStartDate(null);
    }

    {
      end
        ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
        : setFormatEndDate(null);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[260px] md:w-[170px] lg:w-[280px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date range"}</h3>
      </div>
    </div>
  ));

  const nextPage = () => {
    setPage((prev) => (prev >= totalPages ? 1 : prev + 1));
    getRecyclers(query, selectedState, formatStartDate, formatEndDate);
  };
  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
    getRecyclers(query, selectedState, formatStartDate, formatEndDate);
  };

  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"Recycler"}
          subtitle={"List of recyclers on the platform."}
          buttonTitle={"New Recycler"}
          Icon={GoPlus}
          setShowModal={() => setShowModal(true)}
          exportType="recycler"
          userType={user?.userType}
          downloadData={() =>
            recyclerDownload({
              query: query,
              state: selectedState?.value,
              program: program,
              startDate: formatStartDate,
              endDate: formatEndDate,
            })
          }
        />
      </div>
      <div className="mb-10">
        <DataCard
          image={rafiki}
          css={"border boder-gray-300"}
          title={"Total recycler"}
          subtitle={totalElements || 0}
        />
      </div>
      <div className="mb-10 flex flex-col justify-between md:flex-row lg:flex-row gap-2">
        <div className="flex gap-2">
          <SearchableDropdown
            options={states || []}
            placeholder="Filter by state"
            selectedOption={selectedState}
            defaultValue={null}
            handleChange={(e) => {
              setSelectedState(e);
            }}
          />
        </div>
        <div className="flex lg:flex-row lg:flex-nowrap gap-2">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<ExampleCustomInput />}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            isClearable
          />
          <InputSearch
            placeholder={"search"}
            inputValue={query}
            setInputValue={setQuery}
          />
          <button
            className="flex justify-center items-center h-[44px] w-[101px] border border-gray-300 gap-2 rounded-md"
            onClick={() => {
              getRecyclers(
                query,
                selectedState,
                formatStartDate,
                formatEndDate
              );
            }}
          >
            Apply
          </button>
          <button
            className="flex justify-center items-center h-[44px] w-[60px] border border-gray-300 gap-2 rounded-md"
            onClick={() => reset()}
          >
            refresh
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center">
          <p className="text-center">Loading...</p>
        </div>
      ) : recyclers?.length === 0 ? (
        <div className="flex justify-center">
          <p className="text-center">No data.</p>
        </div>
      ) : (
        <CustomTable
          headers={headers}
          rows={recyclers?.map((data, index) => {
            return {
              recycler: (
                <div className="flex flex-col">
                  <p>{data.company}</p>
                  <p>{data.name}</p>
                </div>
              ),
              email: data.email,
              address: data.address,
              state: data.state,
              enrollmentDate: data.enrollmentDate ? data.enrollmentDate : "N/A",
              date: data.createdAt,
              edit: (
                <MdOutlineRemoveRedEye
                  key={index}
                  onClick={() => {
                    setRecyclerDetail(data);
                    setViewDetail(true);
                  }}
                />
              ),
              open: user?.userType !== "PARTNER" && (
                <FiEdit
                  key={index}
                  onClick={() => {
                    setRecyclerDetail(data);
                    setEditDetail(true);
                  }}
                />
              ),
            };
          })}
        />
      )}
      <PaginationPane
        currentPage={page > 1 ? page : 1}
        totalPages={totalPages || 1}
        nextPage={() => nextPage()}
        prevPage={() => prevPage()}
      />
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <RecyclerModal
            closeModal={() => setShowModal(false)}
            getRecyclers={getRecyclers}
            states={states}
          />
        </Modal>
      )}
      {editDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditDetail(false)}
        >
          <RecyclerModal
            closeModal={() => setEditDetail(false)}
            requestType={"edit"}
            getRecyclers={getRecyclers}
            userData={recyclerDetail}
            states={states}
          />
        </Modal>
      )}
      {viewDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewDetail(false)}
        >
          <ViewDetail
            detail={{
              name: recyclerDetail.name ? recyclerDetail.name : "N/A",
              Address: recyclerDetail.address ? recyclerDetail.address : "N/A",
              "Contact Person FirstName": recyclerDetail.contactPersonFirstName
                ? recyclerDetail.contactPersonFirstName
                : "N/A",
              "Contact Person LastName": recyclerDetail.contactPersonLastName
                ? recyclerDetail.contactPersonLastName
                : "N/A",
              email: recyclerDetail.email ? recyclerDetail.email : "N/A",
              "Phone Number": recyclerDetail.phoneNumber
                ? recyclerDetail.phoneNumber
                : "N/A",
              state: recyclerDetail.state ? recyclerDetail.state : "N/A",
            }}
            closeModal={() => setViewDetail(false)}
            title={"Recycler Details"}
            subtitle={"Recycler details below"}
            loading={loading}
            editbutton={true}
            id={recyclerId}
            createdAt={recyclerDetail.createdAt}
          />
        </Modal>
      )}
    </div>
  );
};

export default Recycler;
