import api from "../api/axios";
import fetcher from "../api/fetacher";

export const gatAllAggregator = async ({
  page,
  size,
  nameOrEmailOrPhoneNumber,
  state,
  location,
  program,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      page,
      size,
      programId: program,
    };
    if (nameOrEmailOrPhoneNumber) {
      params.nameOrEmailOrPhoneNumber = nameOrEmailOrPhoneNumber;
    }
    if (!!state && state !== "Filter by state") {
      params.state = state;
    }
    if (!!location && location !== "Filter by lga") {
      params.location = location;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }

    const res = await fetcher(
      "/aggregator",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAggregatorDetail = async ({ id }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher(`/aggregator/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllAggregatorList = async (program) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    const res = await fetcher(
      "/aggregator/list",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const createAggregator = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/aggregator", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateAggregator = async (data) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const res = await fetcher("/aggregator/update", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const downloadAggregator = async ({
  program,
  nameOrEmailOrPhoneNumber,
  state,
  startDate,
  endDate,
}) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const params = {
      programId: program,
    };
    if (nameOrEmailOrPhoneNumber) {
      params.nameOrEmailOrPhoneNumber = nameOrEmailOrPhoneNumber;
    }
    if (!!state && state !== "All States") {
      params.state = state;
    }
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    const res = await fetcher(
      "/aggregator/download",
      {
        method: "GET",
        // responseType: "blob",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      params,
      true
    );

    return res;
  } catch (error) {
    throw error;
  }
};
