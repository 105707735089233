import React from "react";

const Abia = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M280.112 417.567L280.986 419.862L280.522 422.67L281.728 427.961L284.301 432.597L283.64 434.374L281.133 433.93L278.385 431.888L275.409 430.309L273.607 427.677L270.997 425.926L268.981 425.633L267.589 426.578L267.911 430.03L266.595 432.769L266.557 433.463L267.856 433.828L268.403 434.782L268.075 435.72L266.535 436.672L263.854 436.319L261.484 436.932L261.38 437.958L261.942 438.865L262.025 439.845L261.304 442.98L261.08 445.169L261.402 447.492L261.086 449.743L259.371 451.387L258.186 453.198L258.891 455.641L258.574 456.552L258.416 457.378L259.579 459.373L259.956 461.457L256.423 459.211L253.622 459.136L251.606 458.379L249.553 458.414L245.206 459.258L243.06 458.629L242.361 454.722L247.795 446.782L248.074 444.344L247.02 442.492L250.875 431.895L252.874 429.766L253.508 428.519L254.005 427.191L254.731 426.156L255.13 424.968L255.097 423.585L254.89 422.216V420.837L254.518 419.533L253.918 418.582L254.388 417.869L254.912 415.566L254.23 409.928L252.198 408.21L250.729 407.894L248.076 406.612L246.951 405.691L249.408 402.72L252.952 400.94L253.274 401.176L253.416 401.35L255.928 400.953L258.479 400.862L261.925 403.34L262.012 406.283L261.537 409.516L264.677 411.011L272.356 411.097L275.196 412.356L275.589 414.099L276.446 415.65L278.161 416.891L280.112 417.567Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Abia;
