/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from "react";
import { PiUsers } from "react-icons/pi";
import InputText from "../input/InputText";
import InputSelect from "../input/InputSelect";
import { IoCloseOutline } from "react-icons/io5";
import { Form, Formik } from "formik";
import useUser from "../../hooks/useUser";
import toast from "react-hot-toast";
import { orange } from "@mui/material/colors";
import { createUserSchema } from "../../utils/validationSchema/user";
import { useSelector } from "react-redux";

export const UserModal = ({ closeModal, requestType, getUsers, userData }) => {
  const [loading, setLoading] = useState(false);
  const { createNewUser } = useUser();
  const { program, user } = useSelector((state) => state.auth);

  const initialValues = {
    firstName: requestType === "edit" ? userData?.firstName : "",
    lastName: requestType === "edit" ? userData?.lastName : "",
    organization: requestType === "edit" ? userData?.organization : "",
    userType: requestType === "edit" ? userData?.userType : "",
    email: requestType === "edit" ? userData?.email : "",
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (requestType === "edit") {
        setLoading(true);
        const res = await createNewUser({ ...data, programId: program });
        if (res.code === "00") {
          toast.success("User Created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error updating User");
        }
      }
      if (requestType !== "edit") {
        setLoading(true);
        const res = await createNewUser({ ...data, programId: program });
        if (res.code === "00") {
          toast.success("User Created");
          closeModal();
        }
        if (res.code !== "00") {
          setLoading(false);
          toast.error(res.message || "Error creating User");
        }
      }
    } catch (error) {
      toast.error(error.message || "something went wrong");
    } finally {
      setLoading(false);
      getUsers();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createUserSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, errors, values, handleSubmit }) => {
        return (
          <Form>
            <div>
              <div>
                <div className="flex flex-col gap-y-2 md:w-[640px] lg:w-[640px] bg-white p-4 h-max">
                  <div className="flex justify-between">
                    <PiUsers style={{ width: 26, height: 26 }} />
                    <IoCloseOutline
                      style={{ width: 26, height: 26, cursor: "pointer" }}
                      onClick={() => closeModal()}
                    />
                  </div>
                  <div className="">
                    <h1 className="capitalize font-bold">
                      {requestType === "edit" ? "Edit user" : "Create new user"}
                    </h1>
                    <p className="text-sm">Enter the details below</p>
                  </div>
                  <div className="w-full">
                    <InputSelect
                      label={"User type"}
                      placeholder="Select user type"
                      name="userType"
                      value={values.userType}
                      error={errors.userType}
                      options={
                        user?.userType === "BA_FO"
                          ? ["FIELD_OFFICER"]
                          : ["Admin", "Partner", "BA_FO"]
                      }
                      handleChange={handleChange}
                      css="w-full"
                    />
                  </div>
                  <div className="flex justify-between">
                    <InputText
                      label={"First name"}
                      placeholder={"Enter first name"}
                      name="firstName"
                      value={values.firstName}
                      error={errors.firstName}
                      handleChange={handleChange}
                      css="w-full"
                    />
                    <InputText
                      label={"Last name"}
                      placeholder={"Enter last name"}
                      name="lastName"
                      value={values.lastName}
                      error={errors.lastName}
                      handleChange={handleChange}
                      css="w-full"
                    />
                  </div>

                  <div className="flex justify-between">
                    <InputText
                      label={"Organisation"}
                      name="organization"
                      value={values.organization}
                      error={errors.organization}
                      placeholder={"Enter  organisation"}
                      handleChange={handleChange}
                    />

                    <InputText
                      label={"Email"}
                      placeholder={"Enter email"}
                      name="email"
                      value={values.email}
                      error={errors.email}
                      handleChange={handleChange}
                    />
                  </div>

                  <br />

                  <div className="flex gap-2 justify-center">
                    <button
                      className="mx-auto px-2 w-full h-[40px] font-normal text-xs flex justify-center items-center gap-3 disabled:cursor-not-allowed border border-pfBlack md:w-272"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      disabled={loading ? true : false}
                      className="bg-green-700 text-white flex justify-center items-center h-[40px] w-full gap-2"
                    >
                      {requestType === "edit"
                        ? "Save Changes"
                        : loading
                        ? "Creating..."
                        : "Create User"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
