import React from "react";

const Anambra = ({ fill }) => {
  return (
    <>
      <path
        fill={fill}
        d="M212.9 415.912L213.097 413.766L213.447 412.41L213.971 411.114L214.998 409.393L217.106 406.722L217.303 406.293L217.38 405.822L217.303 404.947L217.352 404.494L218.057 401.931L217.85 399.289L218.079 398.154L219.121 397.354L219.815 397.116L220.355 396.862L220.727 396.461L220.928 395.79L221.098 393.562L220.999 392.859L220.731 392.189L220.038 390.922L219.819 390.229L219.612 388.267L218.673 385.806L218.345 383.034L216.876 378.101L216.647 376.62L216.653 375.022L217.117 374.937L218.297 374.986L219.684 375.261L221.028 375.061L222.765 373.423L223.928 368.493L225.178 366.105L228.766 362.872L229.224 364.284L229.175 365.902L229.864 366.209L232.682 366.103L235.729 367.335L237.417 367.673L239.034 368.335L238.805 371.723L235.861 374.766L234.436 378.55L234.9 379.79L235.681 380.149L237.446 380.468L238.249 380.887L239.33 382.285L241.028 385.942L241.328 386.4L241.454 386.914L241.105 387.304L240.761 387.484L240.783 388.432L240.657 389.306L240.455 389.986L240.892 390.571L241.727 390.412L242.47 389.853L243.344 390.366L243.567 391.584L244.834 392.465L244.54 394.214L245.168 395.687L246.025 397.056L246.593 398.616L247.745 399.876L249.242 400.101L250.64 399.707L251.212 399.784L252.158 400.296L252.425 400.829L252.954 400.938L249.41 402.717L246.953 405.689L244.758 406.114L242.59 406.803L237.817 405.825L235.387 406.091L233 406.643L231.018 407.792L230.308 409.926L228.233 413.954L226.141 414.518L223.869 413.602L219.773 414.676L216.234 417.363L214.491 417.247L212.9 415.912Z"
        stroke="#667085"
        stroke-width="1.50059"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>
  );
};

export default Anambra;
