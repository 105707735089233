import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomTable from "../../components/table/CustomTable";
import TopCard from "../../components/card/TopCard";
import { GoPlus } from "react-icons/go";
import DataCard from "../../components/card/DataCard";
import InputSearch from "../../components/input/InputSearch";
import Character from "../../assets/images/Characters.png";
import Modal from "../../components/Modal";
import { AggregatorModal } from "../../components/modal/AggregatorModal";
import useOutsideClick from "../../hooks/useOutsideClick";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import ViewDetail from "../../components/modal/ViewDetail";
import PaginationPane from "../../components/table/PaginationPane";
import useAggregator from "../../hooks/useAggregator";
import { getLga, getLgaByState, getState } from "../../ds/resource";
import useResource from "../../hooks/useResource";
import { useSelector } from "react-redux";
import SearchableDropdown from "../../components/input/SearchableDropdown";
import { Link, json } from "react-router-dom";
import SearchableDropdown2 from "../../components/input/SearchableDropdown2";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const headers = [
  "Company",
  "Email Address",
  "Phone Number",
  "State",
  "Enrollment Date",
  "Entry Date",
];

const Aggregator = () => {
  const [query, setQuery] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const wrapperRef = useRef(null);
  const [showModal, setShowModal] = useOutsideClick(wrapperRef);
  const [viewDetail, setViewDetail] = useOutsideClick(wrapperRef);
  const [editDetail, setEditDetail] = useOutsideClick(wrapperRef);
  const [aggregators, setAggregators] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [states, setStates] = useState([]);
  const [lga, setLga] = useState([]);
  const { program, user } = useSelector((state) => state.auth);
  const [aggregatorId, setAggregatorId] = useState(0);
  const [aggregatorDetail, setAggregatorDetail] = useState({});
  const [selectedStateId, setSelectedStateId] = useState(null);
  const { loading, gatAllAggregators, aggregatorDownload } = useAggregator();
  const limit = 10;

  //for date range
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [formatStartDate, setFormatStartDate] = useState(null);
  const [formatEndDate, setFormatEndDate] = useState(null);

  const getAggregators = useCallback(
    async (newquery, selState, formatStaDate, formatEdDate) => {
      const queryData = query || newquery;
      const newState = selectedState?.label || selState?.label;
      const newEdDate = formatEndDate || formatEdDate;
      const newStaDate = formatStartDate || formatStaDate;
      const res = await gatAllAggregators(
        page,
        limit,
        queryData,
        newState,
        program,
        newStaDate,
        newEdDate
      );
      setTotalPages(res?.data?.totalPages);
      setTotalElements(res?.data?.totalElements);
      setAggregators(res?.data?.content);
    },
    [page, program, query]
  );
  useEffect(() => {
    getAggregators();
  }, [page, program, query, getAggregators]);

  const resetAllAggregators = async () => {
    setSelectedState(null);
    setQuery("");
    setStartDate("");
    setEndDate("");
    getAggregators();
  };

  useEffect(() => {
    const getAllState = async () => {
      const res = await getState(program);
      const list = JSON.parse(res)?.data?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setStates([...list]);
    };
    getAllState();
  }, []);

  useEffect(() => {
    if (selectedStateId) {
      const getAllLga = async () => {
        const res = await getLgaByState(selectedStateId);
        const list = res.data.map((item) => {
          return {
            label: item.name,
            value: item.name,
          };
        });
        setLga([...list]);
      };
      getAllLga();
    }
  }, [selectedStateId]);
  const nextPage = () => {
    getAggregators(query, selectedState, formatStartDate, formatEndDate);
    setPage((prev) => (prev >= totalPages ? 1 : prev + 1));
  };
  const prevPage = () => {
    setPage((prev) => (prev > 1 ? prev - 1 : prev));
    getAggregators(query, selectedState, formatStartDate, formatEndDate);
  };

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    {
      start
        ? setFormatStartDate(moment(start).format("YYYY-MM-DD"))
        : setFormatStartDate(null);
    }

    {
      end
        ? setFormatEndDate(moment(end).format("YYYY-MM-DD"))
        : setFormatEndDate(null);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="flex flex-col gap-2">
      <div
        className="example-custom-input border border-gray-300 h-[44px] w-[260px] md:w-[170px] lg:w-[280px] p-2 rounded-md"
        onClick={onClick}
        ref={ref}
      >
        <h3>{value ? value : "Select date range"}</h3>
      </div>
    </div>
  ));

  return (
    <div className="p-4">
      <div className="mb-10">
        <TopCard
          title={"Aggregator"}
          subtitle={"List of aggregators on the platform."}
          buttonTitle={"New Aggregator"}
          Icon={GoPlus}
          setShowModal={() => setShowModal(true)}
          exportType="aggregator"
          userType={user?.userType}
          downloadData={() =>
            aggregatorDownload({
              program,
              query,
              state: selectedState?.label,
              startDate: formatStartDate,
              endDate: formatEndDate,
            })
          }
        />
      </div>
      <div className="mb-10">
        <DataCard
          title={"Total aggregators"}
          subtitle={totalElements || 0}
          image={Character}
          css={"bg-[#B9E6FE]"}
        />
      </div>
      <div className="mb-10 flex flex-col justify-between md:flex-row lg:flex-row gap-2 ">
        <div className="flex gap-2">
          <SearchableDropdown
            options={states || []}
            placeholder="Filter by state"
            selectedOption={selectedState}
            defaultValue={null}
            handleChange={(e) => {
              setSelectedStateId(e.value);
              setSelectedState(e);
            }}
          />
        </div>
        <div className="flex lg:flex-row lg:flex-nowrap gap-2">
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            customInput={<ExampleCustomInput />}
            showYearDropdown
            yearDropdownItemNumber={100}
            scrollableYearDropdown
            isClearable
          />
          <InputSearch
            placeholder={"search"}
            inputValue={query}
            setInputValue={setQuery}
          />

          <button
            className="flex justify-center items-center h-[44px] w-[80px] lg:w-[101px] border border-gray-300 gap-2 rounded-md"
            onClick={() => {
              getAggregators(
                query,
                selectedState,
                formatStartDate,
                formatEndDate
              );
            }}
          >
            Apply
          </button>
          <button
            className="flex justify-center items-center h-[44px] w-[60px] border border-gray-300 gap-2 rounded-md"
            onClick={() => resetAllAggregators()}
          >
            refresh
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center">
          <p className="text-center">Loading...</p>
        </div>
      ) : aggregators?.length === 0 ? (
        <div className="flex justify-center">
          <p className="text-center">No data.</p>
        </div>
      ) : (
        <CustomTable
          headers={headers}
          rows={aggregators?.map((data, index) => {
            return {
              company: (
                <div className="flex flex-col" key={index}>
                  <p>{data.name}</p>
                  <p>{data.address}</p>
                </div>
              ),
              email: data.email,
              phone_number: data.phoneNumber,
              State: data.state,
              enrollmentDate: data.enrollmentDate,
              data: data.createdAt,
              open: (
                <MdOutlineRemoveRedEye
                  key={index}
                  onClick={() => {
                    setViewDetail(true);
                    setAggregatorDetail(data);
                  }}
                />
              ),
              edit: user?.userType !== "PARTNER" && (
                <FiEdit
                  key={index}
                  onClick={() => {
                    setAggregatorDetail(data);
                    setEditDetail(true);
                  }}
                />
              ),
            };
          })}
        />
      )}
      <PaginationPane
        currentPage={page > 1 ? page : 1}
        totalPages={totalPages || 1}
        nextPage={() => nextPage(totalPages, query, selectedState)}
        prevPage={() => prevPage(query, selectedState)}
      />
      {showModal && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setShowModal(false)}
        >
          <AggregatorModal
            closeModal={() => setShowModal(false)}
            getAggregators={getAggregators}
            states={states}
            lgas={lga}
            useerData={{}}
            setSelectedStateId={setSelectedStateId}
          />
        </Modal>
      )}
      {editDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setEditDetail(false)}
        >
          <AggregatorModal
            closeModal={() => setEditDetail(false)}
            requestType={"edit"}
            getAggregators={getAggregators}
            useerData={aggregatorDetail}
            states={states}
            lgas={lga}
          />
        </Modal>
      )}
      {viewDetail && (
        <Modal
          variant="default"
          refProp={wrapperRef}
          closeModal={() => setViewDetail(false)}
        >
          <ViewDetail
            detail={{
              Name: aggregatorDetail?.name ? aggregatorDetail?.name : "N/A",
              "Phone Number": aggregatorDetail?.phoneNumber
                ? aggregatorDetail?.phoneNumber
                : "N/A",
              "owner gender": aggregatorDetail?.gender
                ? aggregatorDetail?.gender
                : "N/A",
              "owner age range": aggregatorDetail?.ageRange
                ? aggregatorDetail?.ageRange
                : "N/A",
              Address: aggregatorDetail?.address
                ? aggregatorDetail?.address
                : "N/A",
              State: aggregatorDetail?.state ? aggregatorDetail?.state : "N/A",
              Location: aggregatorDetail?.location
                ? aggregatorDetail?.location
                : "N/A",
              "Year of Incorporation": aggregatorDetail?.yearOfIncorporation
                ? aggregatorDetail?.yearOfIncorporation
                : "N/A",
              "No of Female Employee": aggregatorDetail?.noOfFemaleEmployee
                ? aggregatorDetail?.noOfFemaleEmployee
                : Number(0).toString(),
              "No of Male Employee": aggregatorDetail?.noOfMaleEmployee
                ? aggregatorDetail?.noOfMaleEmployee
                : Number(0).toString(),
              cooperative: aggregatorDetail?.cooperative
                ? aggregatorDetail?.cooperative
                : "N/A",
              "Rc Number": aggregatorDetail?.rcNumber
                ? aggregatorDetail?.rcNumber
                : "N/A",
              "Avg. Monthly Collections Capacity":
                aggregatorDetail?.avgMonthlyCollectionCap
                  ? aggregatorDetail?.avgMonthlyCollectionCap
                  : Number(0).toString(),
              "Avg. Monthly Processing Capacity":
                aggregatorDetail?.avgMonthlyProcessionCap
                  ? aggregatorDetail?.avgMonthlyProcessionCap
                  : Number(0).toString(),
              Latitude: aggregatorDetail?.latitude
                ? aggregatorDetail?.latitude
                : "N/A",
              Longitude: aggregatorDetail?.longitude
                ? aggregatorDetail?.longitude
                : "N/A",
              "Map Link": (
                <Link
                  to={`https://www.google.com/maps/place/${aggregatorDetail.latitude},${aggregatorDetail.longitude}`}
                  className="border border-gray-300 p-2 h-10"
                  target="_blank"
                >
                  see map
                </Link>
              ),
            }}
            closeModal={() => setViewDetail(false)}
            title={"Aggregator Details"}
            subtitle={"Aggregator details below"}
            loading={loading}
            editbutton={true}
            id={aggregatorId}
            createdAt={aggregatorDetail?.createdAt}
          />
        </Modal>
      )}
    </div>
  );
};

export default Aggregator;
